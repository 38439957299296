import { Trans, useTranslation } from 'react-i18next';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useResponsive from '~/hooks/useResponsive';

import readyToUpdate from '../assets/ready-to-update.svg';
import { domainSetupCardSx } from '../utils/stepData';

type FinalizeDomainPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  isPending?: boolean;
};

/*
  A component that takes a Site object as defined by HUAPI and
  displays domain info as well as a button to perform an action
*/
const UpdateURLPrompt = ({
  site,
  isPending = false,
}: FinalizeDomainPropOptions) => {
  /*
    The domain has passed all validation steps, and the site pending url is
    ready to be promoted to site url. We will show a button that will perform
    this change. (NOTE: Functionality does not yet exist for this manual action
    to occur)

    - This will likely be a useSiteUpdate HUAPI hook call.
    - We want to display the pending_url here, since it has not yet been promoted to site url.

    This component will need to be displayed in two locations:
    1. Within the stepper on the Hosting Overview, replacing the Temp Domain Info box
    2. On Site Overview, between the tab list and tab content
  */
  const { t } = useTranslation('domains', {
    keyPrefix: 'setupProgress.updateURLPrompt',
  });
  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();

  const pendingUrl = site?.pending_url;
  const hasPendingURLActiveWPSession: boolean =
    !!site?.pending_url_data?.wp_session && !!pendingUrl;
  const descriptionKey =
    'domains:setupProgress.updateURLPrompt.' +
    (hasPendingURLActiveWPSession
      ? 'updateDelayedDescription'
      : 'updateReadyDescription');

  return (
    <Card
      data-testid="update-url-prompt"
      sx={{ ...domainSetupCardSx, backgroundColor: 'primary.light' }}
    >
      {/* Currently this will only display when there is an active WP session, since the manual url update action isn't available from the HAL site yet */}
      <CardContent>
        <Stack spacing={2}>
          <Stack
            direction={'row'}
            spacing={5}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {!isTablet && (
              <CardMedia
                image={readyToUpdate}
                component="img"
                alt={t('imageAltText')}
                sx={{ width: 225, height: 178 }}
              />
            )}
            <Stack
              spacing={3}
              alignItems={'center'}
              textAlign={'center'}
              maxWidth={600}
            >
              {isPending ? (
                <Skeleton height={25} width={isTablet ? 200 : 400} />
              ) : (
                <Typography variant={'h2'} sx={{ wordBreak: 'break-word' }}>
                  <Trans
                    i18nKey={'domains:setupProgress.updateURLPrompt.header'}
                    values={{ domain: pendingUrl }}
                  />
                </Typography>
              )}
              <Typography variant={'body1'} fontWeight={400}>
                <Trans i18nKey={descriptionKey} components={{ br: <br /> }} />
              </Typography>
              {/* TODO: add manual url update call when available */}
              {!hasPendingURLActiveWPSession && (
                <Button
                  variant={'contained'}
                  disabled={isPending}
                  data-testid={'update-url-button'}
                >
                  {t('button')}
                </Button>
              )}
            </Stack>
          </Stack>
          {!hasPendingURLActiveWPSession && (
            <>
              <Divider
                orientation={'horizontal'}
                variant="middle"
                flexItem
                sx={{ borderBottomWidth: 2 }}
              />
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                <LightbulbOutlinedIcon />
                <Typography variant="body2">
                  <Trans
                    i18nKey="domains:setupProgress.updateURLPrompt.sessionNote"
                    components={{ b: <b></b> }}
                  />
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UpdateURLPrompt;

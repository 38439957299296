import { useEffect, useState } from 'react';

import {
  AccountEventListQueryResult,
  useAccountEventList,
} from '@newfold/huapi-js';
import { AccountEventList200EventsItem } from '@newfold/huapi-js/src/index.schemas';

import {
  accountEventsEntryQueryKey,
  accountEventsListQueryKey,
  accountEventsQueryKey,
} from '~/components/MFEProvider/lib/HttpClient/customQueryConfig';
import {
  invalidateMultipleQueries,
  queryClient,
} from '~/components/MFEProvider/lib/HttpClient/queryClient';
import { stepEvents } from '~/scenes/Site/components/DomainSetup/utils/stepData';
import { promotionEvents } from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/stagingConstants';

/*
  Overview:
  ---------

  Default state:
    not polling

  When a component wants to subscribe to watch for an event:
    1. The sub must invoke the subscribeToEvent() callback (provided through the Tenant provider) in order
      to enable accountEvents polling, by giving it a valid event object
    2. useAccountEvents will:
      a. validate that the event contains the correct information
      b. check whether the event/resourceId combo already has en existing query cache entry
        i. no-op if so
      c. add the entry query key to the watch list
      d. set/update enabled to be true and begin polling at some interval
        i. we could already be polling at some interval from a different event entry
    3. When the specified event shows up in a response:
      a. invalidate the associated query cache entries and the overall event list query
      b. remove the event from the watch list
      c. check the watch list
        i. if empty, stop polling
*/

// lists of IDs to build query keys for invalidation
export interface ResourceIdTypes {
  addon?: string[];
  domain?: string[];
  hosting?: string[];
  site?: string[];
}

// Event args data to be provided by a subscriber component
export interface eventWatchRequestPropOptions {
  events?: string[];
  resourceIds?: ResourceIdTypes;
  queryKeys?: any[][];
}

type EventEntryQueryKey = string[];

export type EventEntryQueryData = {
  queries: any[][];
};

type EventEntryQueryKeyList = EventEntryQueryKey[] | undefined;

type eventSubscriptionConfig = {
  events: string[];
  resources: string[];
  formatter: Function;
  retryTime: number;
};

const eventSubscriptions: eventSubscriptionConfig[] = [
  {
    // staging promotion-related events
    events: [...promotionEvents],
    resources: ['hosting', 'site'],
    formatter: () => {},
    retryTime: 10000, // 10 seconds
  },
  {
    // domain stepper-related events
    events: [...stepEvents],
    resources: ['domain', 'hosting'],
    formatter: (event: string) => {
      // some stepper events are of the form "pending_url http://example.com added" - this prunes the url portion
      if (!!event.split(' ')[0] && !!event.split(' ')[2])
        return `${event.split(' ')[0]} ${event.split(' ')[2]}`;
      return '';
    },
    retryTime: 30000, // 30 seconds
  },
  // * EXAMPLE *:
  // {
  //   events: ['connect domain'], // a group of events to associate with some group of query invalidations
  //   resources: ['hosting', 'site'] // which resource identifiers to watch for in the event
  //   formatter: () => {} // some custom function that can augment events formatted in some specific way
  //   retryTime: 30000, // a custom time for this event group
  // },
];

const findEventMapping = (
  events: string[],
): eventSubscriptionConfig | undefined => {
  return eventSubscriptions.find((mapping) => {
    // TODO: validate whether the supplied resource ids match the mapping for the events that were passed
    return (
      // is the supplied list of events a subset of this config mapping list?
      events.every((event) => mapping.events.includes(event))
    );
  });
};

export const generateEventEntryQueryKey = (
  event: string,
  resourceIds?: ResourceIdTypes,
): any[] => {
  const entryQueryKey: EventEntryQueryKey = [...accountEventsEntryQueryKey];

  // Sorting the ids in each list guarantees a consistent order for query key generation
  resourceIds?.addon?.sort();
  resourceIds?.addon?.forEach((id) => {
    entryQueryKey.push(id);
  });
  resourceIds?.domain?.sort();
  resourceIds?.domain?.forEach((id) => {
    entryQueryKey.push(id);
  });
  resourceIds?.hosting?.sort();
  resourceIds?.hosting?.forEach((id) => {
    entryQueryKey.push(id);
  });
  resourceIds?.site?.sort();
  resourceIds?.site?.forEach((id) => {
    entryQueryKey.push(id);
  });
  // The last element in the query key will be the event string
  entryQueryKey.push(event);
  // example: ['/custom/account/events/invalidate/entry', 'WN.HP.123', '123', '234, 'promote done'],

  return entryQueryKey;
};

const useAccountEvents = () => {
  const watchlistQueryKey = [...accountEventsListQueryKey];
  const [queryEnabled, setQueryEnabled] = useState(false);

  const defaultAccountEventsArgs: eventWatchRequestPropOptions = {};
  const [eventArgs, setEventArgs] = useState<eventWatchRequestPropOptions>(
    defaultAccountEventsArgs,
  );

  function handleSetEventArgs(eventArgs: eventWatchRequestPropOptions) {
    setEventArgs(eventArgs);
  }

  const callbacks = {
    subscribeToEvent: handleSetEventArgs,
  };

  /*
    Entry structure:
      - query key:
        - derived
        - type EventEntryQueryKey
        - a list of strings (base key, resource IDs, event)
      - query data
        - provided
        - type EventEntryQueryData
        - a keyed object containing a list of query keys

      example:
      ['/custom/account/events/invalidate/entry', 'WN.HP.234', '345', 'promote done']:
        { queries: [ ['v1/sites/345'], ['v2/hosting/WN.HP.234/sites'] ] }


    List structure:
      - query key
        - imported
        - value static
      - query data
        - array of type EventEntryQueryKey
        - a list of entry query keys
        - each one meant to be invalidated upon seeing a specific event

      example:
      {
        ['/custom/account/events/invalidate/list']: [
          ['/custom/account/events/invalidate/entry', 'WN.HP.234', '123', '456', 'promote done'],
          ['/custom/account/events/invalidate/entry', 'WN.HP.234', '123', '456', 'demote done'],
          ['/custom/account/events/invalidate/entry', 'WN.HP.345', '123', 'dns updated']
        ]
      }
  */

  const eventsQueryData: AccountEventListQueryResult | undefined =
    queryClient.getQueryData(accountEventsQueryKey);

  // Needs to be initialized to current time so we aren't starting with the full list of events from the past 7 days every time
  const currentTime = new Date().toISOString().replace('T', ' ');
  const eventDateLast =
    eventsQueryData?.data?.utc ??
    currentTime.substring(0, currentTime.indexOf('.'));

  const validEventMapping = !!eventArgs.events
    ? findEventMapping(eventArgs.events)
    : undefined;

  const queryKeysToInvalidate: any[][] = eventArgs.queryKeys ?? [];

  if (
    !!validEventMapping &&
    !!eventArgs &&
    Object.keys(eventArgs).length >= 0 &&
    !!eventArgs.events &&
    eventArgs.events?.length >= 0
  ) {
    const eventList: string[] = [...eventArgs.events!];
    const eventEntryQueryListData: EventEntryQueryKeyList =
      queryClient.getQueryData(watchlistQueryKey);

    const newWatchlistData =
      eventEntryQueryListData ?? ([] as EventEntryQueryKeyList);

    // Create a watchlist entry for *each event* we want to watch for
    eventArgs.events.forEach((event) => {
      const entryQueryKey = generateEventEntryQueryKey(
        event,
        eventArgs?.resourceIds,
      );

      // Check whether query data for this key already exists - if so, do not re-add it
      const entryQueryData: EventEntryQueryData | undefined =
        queryClient.getQueryData(entryQueryKey);

      if (!entryQueryData || entryQueryData.queries.length < 1) {
        const invalidationListEntryData: EventEntryQueryData = {
          queries: queryKeysToInvalidate,
        };

        // Set the newly-created data at the entry query key
        queryClient.setQueryData(entryQueryKey, invalidationListEntryData);

        // Add the entry query key to the overall watch list of query keys
        newWatchlistData?.push(entryQueryKey);
      }

      // Add the event invalidation to the list
      queryClient.setQueryData(watchlistQueryKey, newWatchlistData);

      // Remove each item from our tracking list as we process it
      eventList.splice(0, 1);
      if (eventList.length === 0) handleSetEventArgs(defaultAccountEventsArgs);
    });
  }

  useEffect(() => {
    const watchlistData: EventEntryQueryKeyList =
      queryClient.getQueryData(watchlistQueryKey);

    // enable if we have any subscribed events to watch for
    if (!!watchlistData && watchlistData.length > 0) {
      setQueryEnabled(true);
    }
    /*
      This is only watching for changes in eventArgs because we only want to set or
        update query:enabled if a new request has come in from a consumer component.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventArgs]);

  const eventsQueryResult = useAccountEventList(
    {
      date_last: eventDateLast,
    },
    {
      query: {
        queryKey: accountEventsQueryKey,
        enabled: queryEnabled,
        // We could use a variable refetch interval, maybe based on individual config values or just a faster/slower concept
        refetchInterval: 10000, // Using a static 10 sec timer to test for now
      },
    },
  );

  /*
    It would be nice to sort/format the resource ID lists in HUAPI
      and return them alongside the events, so that we don't need to worry about
      reconstructing them here to match existing query keys
  */
  const matchResources = (
    event: AccountEventList200EventsItem,
    config: eventSubscriptionConfig,
    queryKey: any[],
  ): boolean => {
    const queryKeyResourceIds: string[] = queryKey.slice(1, -1); // remove the base key and the event name

    // for the resources specified in this event's config
    return config.resources.every((resource) => {
      // do the IDs in the query key match the IDs in the event?
      if (resource === 'addon') {
        return (
          !!event.addon_id &&
          queryKeyResourceIds.includes(String(event.addon_id))
        );
      } else if (resource === 'domain') {
        return (
          !!event.domain_id &&
          queryKeyResourceIds.includes(String(event.domain_id))
        );
      } else if (resource === 'hosting') {
        return (
          !!event.hosting_id &&
          queryKeyResourceIds.includes(String(event.hosting_id))
        );
      } else if (resource === 'site') {
        return (
          !!event.site_id && queryKeyResourceIds.includes(String(event.site_id))
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (
      !eventsQueryResult?.isPending &&
      !eventsQueryResult?.isFetching &&
      eventsQueryResult?.isSuccess &&
      !!eventsQueryResult?.data?.data?.events &&
      eventsQueryResult?.data?.data?.events.length > 0
    ) {
      const currentWatchlistData: EventEntryQueryKeyList =
        queryClient.getQueryData(watchlistQueryKey);

      const eventsData = eventsQueryResult?.data?.data;

      if (
        !!eventsData?.events &&
        eventsData?.events?.length > 0 &&
        !!currentWatchlistData &&
        currentWatchlistData?.length > 0
      ) {
        /*
          At this point, we know that we have at least one invalidation event subscribed
            to and pending invalidation, and that we found new events in the latest poll.

          We will get a list of events in this format:
          [
            {
              addon_id: number | null,
              count: number,
              date_first: string,
              date_last: string,
              domain_id: number | null,
              event: string,
              hosting_id: number,
              site_id: number | null,
              tenant_id: number
            },
            ...
          ]
        */
        const newWatchlistData: EventEntryQueryKeyList = [];
        const watchlistEntriesToRemove: any[][] = [];
        const watchedQueriesToInvalidate: any[][] = [];

        currentWatchlistData?.forEach((queryKey) => {
          let queryEventMatched = false;

          const eventConfig = findEventMapping(queryKey.slice(-1));

          if (!!eventConfig) {
            eventsData.events?.forEach((event) => {
              if (
                !!event.event &&
                (queryKey.includes(event.event) ||
                  // Check against the config-specific formatted version of the event as well
                  queryKey.includes(eventConfig.formatter(event.event))) &&
                (eventConfig.events.includes(event.event) ||
                  eventConfig.events.includes(
                    eventConfig.formatter(event.event),
                  )) &&
                // Validate event id values against what we expect based on the config
                matchResources(event, eventConfig, queryKey)
              ) {
                const eventToInvalidate: EventEntryQueryData | undefined =
                  queryClient.getQueryData(queryKey);

                queryEventMatched = true;

                if (
                  !!eventToInvalidate &&
                  eventToInvalidate.queries.length > 0
                ) {
                  // Queue these queries to be invalidated
                  watchedQueriesToInvalidate.push(...eventToInvalidate.queries);

                  // Queue this entry key to be removed
                  watchlistEntriesToRemove.push(queryKey);
                }

                // break out of this events loop if a match is found
                return;
              }
            }); // end of event loop

            if (!queryEventMatched) {
              // If we did not find a matching event, add this query key back to the watchlist
              newWatchlistData.push(queryKey);
            }
          }
        }); // end of query key loop

        // Update the watchlist with any queries we did not invalidate
        queryClient.setQueryData(watchlistQueryKey, newWatchlistData);

        // If we no longer have events to watch for, stop polling the events list
        if (newWatchlistData.length <= 0) setQueryEnabled(false);

        // remove the actual accounts event list query to reset its response state
        watchlistEntriesToRemove.push(accountEventsQueryKey);

        // Remove watchlist entries whose queries we are invalidating
        watchlistEntriesToRemove.forEach((queryKey) =>
          queryClient.removeQueries({ queryKey }),
        );

        // Invalidate the collected queries
        invalidateMultipleQueries(queryClient, watchedQueriesToInvalidate);
      }
    }
    /*
      The only thing this useEffect should be looking at is the result of the events list api
        call, since the retry frequency is the most often it should be triggered.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsQueryResult]);

  return {
    callbacks,
    data: eventsQueryResult.data,
  };
};

export default useAccountEvents;

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

interface SearchBarPropOptions {
  searchQuery?: string;
  placeholder?: string;
  handleSearch: (inputValue: string) => void;
  inputProps?: OutlinedInputProps;
}

export default function SearchBar({
  searchQuery = '',
  placeholder = 'Search',
  handleSearch,
  inputProps = {},
}: SearchBarPropOptions) {
  return (
    <FormControl variant="outlined">
      <OutlinedInput
        size="small"
        fullWidth
        value={searchQuery}
        onChange={(event) => handleSearch(event.target.value)}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            {searchQuery ? (
              <IconButton
                onClick={() => handleSearch('')}
                type="submit"
                aria-label="search"
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        {...inputProps}
      />
    </FormControl>
  );
}

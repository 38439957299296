import { useMFEContext } from '~/components/MFEProvider';

interface BrandInfo {
  phoneNumberFormatted: string;
  phoneNumberHref: string;
  isDNSManagedInCpanel: boolean;
  domainName: string;
  path: string;
  contactLink: string;
  optimizeLink: string;
  supportLink: string;
  wpSolutionLearnMoreUrl: string;
  loggingInUsingExtClient: string;
  basicSSHCommands: string;
  commonSSHErrors: string;
  generateSSHPublicKeyUrl: string;
  indexesLearnMoreUrl: string;
  directoryPrivacyLearnMoreUrl: string;
  redirectsLearnMoreUrl: string;
  diskUsageLearnMoreUrl: string;
  phpInfoLearnMoreUrl: string;
  dbInfoLearnMoreUrl: string;
  cronJobLearnMoreUrl: string;
  remoteMySQLLearnMoreUrl: string;
  ipblockerLearnMoreUrl: string;
  cloudStagingKb: string;
  cloudStagingPromoteKb: string;
  cloudStagingDeleteKb: string;
  visitorInfoLearnMoreUrl: string;
  ftpLearnMoreUrl: string;
  cloudFlareKB: string;
  checkDatabaseLearnMoreUrl: string;
  repairDatabaseLearnMoreUrl: string;
  sslLearnMoreUrl: string;
  pageSpeedInsightsLearnMoreKb: string;
  emailAccountForwardersLearnMoreUrl: string;
  domainForwardersLearnMoreUrl: string;
  advancedDNSManagementUrl: string;
}

// TODO: ideally this is passed from host application
// or managed in a service or endpoint of its own
const brands = {
  default: {
    phoneNumberFormatted: '',
    phoneNumberHref: '',
    isDNSManagedInCpanel: false,
    domainName: '',
    path: '',
    contactLink: '',
    optimizeLink: '',
    supportLink: '',
    wpSolutionLearnMoreUrl: '',
    loggingInUsingExtClient: '',
    basicSSHCommands: '',
    commonSSHErrors: '',
    generateSSHPublicKeyUrl: '',
    indexesLearnMoreUrl: '',
    directoryPrivacyLearnMoreUrl: '',
    redirectsLearnMoreUrl: '',
    diskUsageLearnMoreUrl: '',
    phpInfoLearnMoreUrl: '',
    dbInfoLearnMoreUrl: '',
    cronJobLearnMoreUrl: '',
    remoteMySQLLearnMoreUrl: '',
    ipblockerLearnMoreUrl: '',
    cloudStagingKb: '',
    cloudStagingPromoteKb: '',
    cloudStagingDeleteKb: '',
    visitorInfoLearnMoreUrl: '',
    ftpLearnMoreUrl: '',
    cloudFlareKB: '',
    checkDatabaseLearnMoreUrl: '',
    repairDatabaseLearnMoreUrl: '',
    sslLearnMoreUrl: '',
    pageSpeedInsightsLearnMoreKb: '',
    emailAccountForwardersLearnMoreUrl: '',
    domainForwardersLearnMoreUrl: '',
    advancedDNSManagementUrl: '',
  } as BrandInfo,
  bluehost: {
    phoneNumberFormatted: '1-888-401-4678',
    phoneNumberHref: 'tel:+18884014678',
    isDNSManagedInCpanel: true,
    domainName: 'www.bluehost.com',
    path: 'Bluehost%20Cloud',
    contactLink: 'https://www.bluehost.com/contact',
    optimizeLink:
      'https://www.bluehost.com/help/article/optimize-hosting-reduce-disk-space',
    supportLink: 'https://www.bluehost.com/contact',
    wpSolutionLearnMoreUrl: 'https://www.bluehost.com',
    loggingInUsingExtClient:
      'https://www.bluehost.com/help/article/ssh-connections',
    basicSSHCommands: 'https://www.bluehost.com/help/article/ssh-commands',
    commonSSHErrors: 'https://www.bluehost.com/help/article/common-ssh-errors',
    generateSSHPublicKeyUrl:
      'https://www.bluehost.com/help/article/generate-ssh-keys#windows-with-putty',
    indexesLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-index-manager',
    directoryPrivacyLearnMoreUrl:
      'https://www.bluehost.com/help/article/password-protect-a-folder-on-your-website',
    redirectsLearnMoreUrl:
      'https://www.bluehost.com/help/article/redirect-a-domain',
    diskUsageLearnMoreUrl:
      'https://www.bluehost.com/help/article/disk-space-bandwidth-usage',
    phpInfoLearnMoreUrl: 'https://www.bluehost.com/help/article/am-php-info',
    dbInfoLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-database-manager#create-mysql-db',
    cronJobLearnMoreUrl:
      'https://www.bluehost.com/help/article/cron-job-basics',
    remoteMySQLLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-remote-mysql',
    ipblockerLearnMoreUrl:
      'https://www.bluehost.com/help/article/cp-ip-blocker',
    cloudStagingKb: 'https://www.bluehost.com/help/article/bh-cloud-staging',
    cloudStagingPromoteKb:
      'https://www.bluehost.com/help/article/bh-cloud-promoting-a-staging-site',
    cloudStagingDeleteKb:
      'https://www.bluehost.com/help/article/bh-cloud-remove-or-delete-a-staging-site',
    ftpLearnMoreUrl:
      'https://www.bluehost.com/help/article/create-and-manage-ftp-accounts-for-your-website',
    cloudFlareKB: 'https://www.bluehost.com/help/article/cloudflare-enable-cdn',
    checkDatabaseLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-check-repair-and-optimize-database-in-account-manager',
    repairDatabaseLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-check-repair-and-optimize-database-in-account-manager',
    sslLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-how-to-manually-install-ssl-in-account-manager',
    visitorInfoLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-viewing-visitor-stats',
    pageSpeedInsightsLearnMoreKb:
      'https://www.bluehost.com/help/article/am-pagespeed-insights',
    emailAccountForwardersLearnMoreUrl:
      'https://www.bluehost.com/help/article/create-account-and-domain-forwarders',
    advancedDNSManagementUrl: '',
  } as BrandInfo,
  hostgator: {
    phoneNumberFormatted: '1-866-964-2867',
    phoneNumberHref: 'tel:+18669642867',
    isDNSManagedInCpanel: true,
    supportLink: 'https://www.hostgator.com/contact',
    //domainName and path not implemented, since Hostgator does not yet support Atomic
    contactLink: 'https://www.hostgator.com/contact',
    optimizeLink:
      'https://www.hostgator.com/help/article/optimize-and-free-up-hosting-disk-space',
    // TODO: Need to confirm hostgator link
    loggingInUsingExtClient:
      'https://www.hostgator.com/help/article/ssh-keying-through-putty-on-windows-or-linux',
    // TODO: Need to confirm hostgator link
    basicSSHCommands: 'https://www.hostgator.com/help/article/shell-commands',
    // TODO: Need to confirm hostgator link
    commonSSHErrors: 'https://www.hostgator.com/help/article/ssh-errors',
    generateSSHPublicKeyUrl:
      'https://www.hostgator.com/help/article/ssh-keying-through-putty-on-windows-or-linux',
    indexesLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-index-manager',
    directoryPrivacyLearnMoreUrl:
      'https://www.hostgator.com/help/article/how-to-edit-your-htaccess-file',
    // TODO: Need to confirm hostgator link
    redirectsLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-redirects',
    diskUsageLearnMoreUrl:
      'https://www.hostgator.com/help/article/how-can-i-view-disk-space-and-bandwidth-usage-for-my-shared-account',
    dbInfoLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-database-manager',
    cronJobLearnMoreUrl: 'https://www.hostgator.com/help/article/cp-cron-job',
    remoteMySQLLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-remote-mysql',
    ipblockerLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-ip-blocker',
    ftpLearnMoreUrl: 'https://hostgator.com/help/article/cp-ftp-management',
    checkDatabaseLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-check-repair-and-optimize-database-in-account-manager',
    repairDatabaseLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-check-repair-and-optimize-database-in-account-manager',
    sslLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-how-to-manually-install-ssl-in-customer-portal',
    visitorInfoLearnMoreUrl:
      'https://www.hostgator.com/help/article/cp-viewing-visitor-stats',
    phpInfoLearnMoreUrl: 'https://www.hostgator.com/help/article/cp-php-info',
    pageSpeedInsightsLearnMoreKb:
      'https://www.hostgator.com/help/article/cp-pagespeed-insights',
    emailAccountForwardersLearnMoreUrl:
      'https://www.hostgator.com/help/article/how-to-createdelete-an-email-forwarder-in-cpanel',
    advancedDNSManagementUrl: '',
  } as BrandInfo,
  vodien: {
    // TODO: update values from brand
    phoneNumberFormatted: '+65 6018 3544',
    phoneNumberHref: 'tel:+65%206018%203544',
    isDNSManagedInCpanel: false,
    supportLink: 'https://help.vodien.com',
    contactLink: 'https://www.vodien.com/contact',
  } as BrandInfo,
};

export const useBrandInfo = () => {
  // @ts-expect-error
  const { brand, brandFromJWT } = useMFEContext();
  const brandLower = brand.toLowerCase() as keyof typeof brands;
  const brandExists = brandLower && brandLower in brands;
  const isDreamscape = ['vodien', 'crazydomains'].includes(brandLower);

  return {
    brandFromJWT,
    hostApplication: isDreamscape ? 'dreamscape' : 'accountmanager',
    isDreamscape,
    ...(brandExists
      ? // Use defaults first, but overwrite with actual brand values if defined
        { ...brands.default, ...brands[brandLower] }
      : brands.default),
  };
};

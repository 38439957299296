import { useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Tooltip from '~/utils/CommonTooltip';

type AdvancedHostingServerSetupProps = {
  hostname?: string;
  isDomainServiceConfigured: boolean;
};

const AdvancedHostingServerSetup = ({
  hostname = '',
  isDomainServiceConfigured,
}: AdvancedHostingServerSetupProps) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.advHostingServerInfo',
  });

  const tooltipContent = (
    <div>
      <Typography>{t('serverSetup.helperText2')}</Typography>
      <Typography display="block">{t('serverSetup.setServer')}</Typography>
      <Typography display="block">
        {t('serverSetup.createCpanelAccount')}
      </Typography>
      <Typography display="block">{t('serverSetup.createARecords')}</Typography>
      <Typography display="block">
        {t('serverSetup.createPTRRecords')}
      </Typography>
    </div>
  );

  return (
    <>
      {hostname && (
        <Typography
          variant="body2"
          textAlign={'left'}
          sx={{ wordWrap: 'break-word' }}
        >
          {hostname}
        </Typography>
      )}
      {!isDomainServiceConfigured && (
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningIcon color="warning" />
          <Link href="/setup-server">
            <Typography variant="body2">{t('runServerSetup')}</Typography>
          </Link>
          <Tooltip desc={tooltipContent} />
        </Stack>
      )}
    </>
  );
};

export default AdvancedHostingServerSetup;

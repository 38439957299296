// Remove custom style var and sx prop
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { StagingSitePromote409DetailsLimitsItem } from '@newfold/huapi-js/src/index.schemas';

import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import { useBrandInfo } from '~/hooks/useBrandInfo';

type ResourceLimitErrorModalPropOptions = {
  open: boolean;
  handleClose: Function;
  siteId: string | number | null;
  parentSiteId: string | number | null;
  limits: StagingSitePromote409DetailsLimitsItem[] | undefined;
};

const ResourceLimitErrorModal = ({
  handleClose,
  siteId,
  parentSiteId,
  limits,
}: ResourceLimitErrorModalPropOptions) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'staging.promoteSite.resourceLimitErrorModal',
  });

  const { cloudStagingPromoteKb } = useBrandInfo();

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = () => {
    window.open(cloudStagingPromoteKb, '_blank');
  };

  const stagingSiteLimits = limits?.filter(
    (limit) => Number(limit.site_id) === siteId,
  );

  const productionSiteLimits = limits?.filter(
    (limit) => Number(limit.site_id) === parentSiteId,
  );

  return (
    <>
      <DialogTitle>
        {t('title')}
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} py={1}>
          <WarningIcon color="warning" />
          <Typography component="div" variant="body1" pb={1}>
            <Trans
              t={t}
              i18nKey="desc"
              components={{
                Link: <Link href={cloudStagingPromoteKb} target="_blank" />,
              }}
            />
          </Typography>
        </Stack>
        <Grid container pt={1} spacing={2}>
          {!isEmpty(stagingSiteLimits) && (
            <Grid item xs>
              <Card sx={{ minWidth: 265, minHeight: 120, borderRadius: 0 }}>
                <CardHeader
                  title={
                    <Typography variant="h3" align="left" paddingBottom={2}>
                      {t('stagingSite')}
                    </Typography>
                  }
                  sx={{ bgcolor: 'warning.main', height: '20px' }}
                />
                <Divider />
                <CardContent>
                  {stagingSiteLimits &&
                    stagingSiteLimits.map((limit, index) => (
                      <div key={limit?.limit}>
                        {limit?.limit === 'workers' && (
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h3">
                              {t('phpWorkers')}:{' '}
                            </Typography>
                            <Typography variant="h3" color="error">
                              {limit?.used} {t('of')} {limit?.max}
                            </Typography>
                          </Stack>
                        )}

                        {limit?.limit === 'disk_limit_gib' && (
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h3">
                              {t('diskAllocation')}:{' '}
                            </Typography>
                            <Typography variant="h3" color="error">
                              {limit?.used} {t('GB')} {t('of')} {limit?.max}{' '}
                              {t('GB')}
                            </Typography>
                          </Stack>
                        )}
                      </div>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          )}
          {!isEmpty(productionSiteLimits) && (
            <Grid item xs>
              <Card sx={{ minWidth: 265, minHeight: 120, borderRadius: 0 }}>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      align="left"
                      paddingBottom={2}
                      color="common.white"
                    >
                      {t('productionSite')}
                    </Typography>
                  }
                  sx={{
                    bgcolor: 'secondary.main',
                    fontSize: '2px',
                    alignContent: 'center',
                    height: '20px',
                  }}
                />
                <Divider />
                <CardContent>
                  {productionSiteLimits &&
                    productionSiteLimits.map((limit, index) => (
                      <div key={limit.limit}>
                        {limit?.limit === 'workers' && (
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h3">
                              {t('phpWorkers')}:{' '}
                            </Typography>
                            <Typography variant="h3" color="error">
                              {limit?.used} {t('of')} {limit?.max}
                            </Typography>
                          </Stack>
                        )}

                        {limit?.limit === 'disk_limit_gib' && (
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h3">
                              {t('diskAllocation')}:{' '}
                            </Typography>
                            <Typography variant="h3" color="error">
                              {limit?.used} {t('GB')} {t('of')} {limit?.max}{' '}
                              {t('GB')}
                            </Typography>
                          </Stack>
                        )}
                      </div>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="text" onClick={handleCancel} size="large">
            {t('backBtn')}
          </Button>
          <LoadingButtonWrapper
            data-testid="howDoIFixThisBtn"
            variant="contained"
            onClick={handleSubmit}
          >
            {t('howDoIfixThisBtn')}
          </LoadingButtonWrapper>
        </Stack>
      </DialogActions>
    </>
  );
};
export default ResourceLimitErrorModal;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneIcon from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { isTestMode } from '~/components/DevMode';
import { useMFEContext } from '~/components/MFEProvider';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFullStory from '~/hooks/useFullStory';

type PhoneNumberPropOptions = {
  text: string;
};
export function PhoneNumber({ text }: PhoneNumberPropOptions) {
  const { phoneNumberHref, phoneNumberFormatted } = useBrandInfo();
  return (
    <Button
      startIcon={<PhoneIcon />}
      variant="contained"
      href={phoneNumberHref}
    >
      {`${text} ${phoneNumberFormatted}`}
    </Button>
  );
}

type ActionButtonPropOptions = {
  statusCode: number;
  actionText: string;
};

export const ActionButton = ({
  statusCode,
  actionText,
}: ActionButtonPropOptions) => {
  return (
    <>
      {statusCode === 401 ? (
        <Button
          variant="contained"
          href="/login"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/login';
          }}
        >
          {actionText}
        </Button>
      ) : (
        <PhoneNumber text={actionText} />
      )}
    </>
  );
};

type HostingLoadingPropOptions = {
  statusCode?: number;
  loading?: boolean;
  pending?: boolean;
  deleted?: boolean;
  isError?: boolean;
};

export default function HostingLoading({
  statusCode = -1,
  loading = undefined,
  pending = undefined,
  deleted = undefined,
  isError = undefined,
}: HostingLoadingPropOptions) {
  const { t } = useTranslation('hosting');
  const { event } = useFullStory();
  const {
    // @ts-expect-error
    hostingId,
    // @ts-expect-error
    data: { hostingData, addHostingAccountUrl },
  } = useMFEContext();

  const { isDreamscape } = useBrandInfo();

  const productName = hostingData?.productName;

  // log non 401-errors to fullstory
  useEffect(() => {
    if (statusCode >= 400 && statusCode !== 401 && !pending && !isTestMode) {
      event('Hosting MFE - Error - Hosting Not Available', {
        hostingId_str: String(hostingId),
        errorStatusCode_str: String(statusCode),
      });
    }
  }, [event, hostingId, statusCode, pending]);

  type StatusCopyTypes = {
    title: string;
    body?: string;
    actionText?: string;
  };
  const statusCopy = (() => {
    if (pending && statusCode !== 403) return t('pending');
    if (pending && statusCode === 403) return t('provisioning');
    if (deleted) return t('deleted');
    if (statusCode === 401) return t('error.401');
    return t('error.failed');
  })() as StatusCopyTypes;

  return (
    <Stack spacing={3} data-testid="general-hosting-error">
      {/* Hosting plan name */}
      {!isDreamscape && <Typography variant="h1">{productName}</Typography>}

      {/* Hosting message when hosting is deleted */}
      {deleted && (
        <Card>
          <CardHeader
            sx={{ pb: statusCopy?.body ? 0 : 2 }}
            title={t('deleted.title')}
            action={
              <>
                <Button variant="contained" href={addHostingAccountUrl}>
                  {statusCopy?.actionText}
                </Button>
              </>
            }
          />
        </Card>
      )}

      {/* Hosting message */}
      {(pending || isError) && (
        <Card data-testid="hosting-loader-card">
          {pending && (
            <Box
              sx={{ width: '100%' }}
              data-testid="hosting-linear-progress-bar"
            >
              <LinearProgress sx={{ height: 6 }} />
            </Box>
          )}
          <CardHeader
            sx={{ pb: statusCopy?.body ? 0 : 2 }}
            title={statusCopy?.title}
            action={
              statusCopy?.actionText && (
                <ActionButton
                  statusCode={statusCode}
                  actionText={statusCopy?.actionText}
                />
              )
            }
          />
          {statusCopy?.body && <CardContent>{statusCopy?.body}</CardContent>}
        </Card>
      )}

      {(loading || pending) && !isError && (
        <>
          {/* Hosting / Renewal info */}
          <Skeleton variant="rounded" width={'100%'} height={150} />

          {/* Sites list */}
          <Skeleton variant="rounded" width={'100%'} height={500} />

          {/* Hosting detail / Quick links */}
          <Box data-testid="hosting-skeleton-loader">
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Skeleton variant="rounded" width={'100%'} height={300} />
              </Grid>
              <Grid item md={4}>
                <Skeleton variant="rounded" width={'100%'} height={300} />
              </Grid>
            </Grid>
          </Box>

          {/* Etc. */}
          <Skeleton variant="rounded" width={'100%'} height={140} />
        </>
      )}
    </Stack>
  );
}

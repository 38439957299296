import { PropsWithChildren } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiPagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

type PaginationPropOptions = {
  page?: number;
  pageCount?: number;
  pageLimit?: number;
  pageSize?: number;
  pageSizes?: number[];
  pageSelectLabel?: string;
  totalRows?: number;
  onPageChange?: any;
  onPageSizeChange?: any;
};

const Pagination = ({
  page = 1,
  pageCount = 1,
  pageLimit = undefined,
  pageSize = 5,
  pageSizes = [5, 10, 25, 50],
  pageSelectLabel = 'per page',
  totalRows = undefined,
  onPageChange = undefined,
  onPageSizeChange = undefined,
}: PropsWithChildren<PaginationPropOptions>) => {
  // If there is only one page and not (page limit and page limit is greater than or equal to the first page size), don't show pagination
  if (pageCount <= 1 && !(pageLimit && pageLimit >= pageSizes[0])) {
    return null;
  }

  // If there is only one page and the total rows is less than the first page size, don't show pagination
  if (
    pageCount === 1 &&
    typeof totalRows !== 'undefined' &&
    totalRows <= pageSizes[0]
  ) {
    return null;
  }

  return (
    <Stack spacing={2} direction="row">
      <FormControl size="small" sx={{ minWidth: 80 }}>
        <InputLabel id="sitesPerPageLbl">{pageSelectLabel}</InputLabel>
        <Select
          id="paginationLimit"
          labelId="paginationPerPageLbl"
          label={pageSelectLabel}
          value={pageSize}
          onChange={onPageSizeChange}
        >
          {pageSizes.map((size, index) => (
            <MenuItem value={size} key={index}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {pageCount > 1 && (
        <MuiPagination
          shape="rounded"
          count={pageCount}
          page={page}
          onChange={onPageChange}
        />
      )}
    </Stack>
  );
};

export { Pagination, PaginationPropOptions };

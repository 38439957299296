import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';

const gcTime = 1000 * 60 * 60 * 24; // 24 hours;
const staleTime = 1000 * 60 * 3; // 3 mins;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime,
      staleTime,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const invalidateMultipleQueries = async (
  queryClient: QueryClient,
  queries: (readonly any[])[],
) =>
  await Promise.all(
    queries.map(async (query) =>
      queryClient.invalidateQueries({ queryKey: query }),
    ),
  );

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// we want the allow list to be in a path format that is familiar with us and what we use in
// huapi-js as well as our react router paths.
// this function converts that path and :params style to regex with wildcard values.
function convertPathToRegex(path: string) {
  return new RegExp(`^${path.replace(/:[^/]+/g, '[^/]+')}$`);
}

// The following values are persisted in local storage
const PERSIST_QUERIES_ALLOW_LIST = [
  // add paths that should be persisted with localStorage here:
  // NOTE: a param such as `:hostingId` will be converted to a
  // regex wildcard that matches any value
  '/ctb/hasPurchased/:hostingId',
  // '/v1/account/hosting', // TODO: temporarily removing from cache until huapi can return multi-tenant hosting accounts
  '/v1/hosting/:hostingId',
  '/v1/hosting/:hostingId/addons',
  '/v1/hosting/:hostingId/capabilities',
  '/v1/hosting/:hostingId/db/usage',
  '/v1/hosting/:hostingId/ecommerce',
  '/v1/hosting/:hostingId/info',
  '/v1/hosting/:hostingId/info/diskusage',
  '/v2/hosting/:hostingId/nameservers',
  '/v1/hosting/:hostingId/onboarding',
  '/v1/hosting/:hostingId/ssh',
  '/v1/hosting/:hostingId/sites',
  '/v2/hosting/:hostingId/sites',
  // '/v1/sites', // TODO: temporarily removing from cache until huapi can return all multi-tenant sites
  // '/v2/sites', // TODO: temporarily removing from cache until huapi can return all multi-tenant sites
];

// convert paths to regex[]
const persistQueriesAsRegex =
  PERSIST_QUERIES_ALLOW_LIST.map(convertPathToRegex);

export const dehydrateOptions = {
  shouldDehydrateQuery: ({ queryKey }: { queryKey: any }) =>
    !!persistQueriesAsRegex.find((regex) => regex.test(queryKey[0])),
};

export const PROMOTION_FAILED = 'failed';
export const PROMOTION_IN_PROGRESS = 'in_progress';
export const PROMOTION_NOT_PROMOTING = 'not_promoting';

export const promotionStatuses = [
  PROMOTION_FAILED,
  PROMOTION_IN_PROGRESS,
  PROMOTION_NOT_PROMOTING,
];

export const EVENT_PROMOTE_START = 'promote start';
export const EVENT_DEMOTE_START = 'demote start';
export const EVENT_PROMOTE_DONE = 'promote done';
export const EVENT_DEMOTE_DONE = 'demote done';

export const promotionEvents = [
  EVENT_DEMOTE_DONE,
  EVENT_DEMOTE_START,
  EVENT_PROMOTE_DONE,
  EVENT_PROMOTE_START,
];

import { useRef } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useMFEContext } from '~/components/MFEProvider';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useQueryFeature from '~/hooks/useQueryFeature';
import DomainSetup from '~/scenes/Site/components/DomainSetup';

import ProServicesCard from '../../../../components/ProServicesCard';
import AdvancedHostingOverView from '../../components/AdvancedHosting';
import BuilderSiteLogin from '../../components/BuilderSiteLogin';
import DiskUsageChart from '../../components/DiskUsageChart';
import OverviewCard from '../../components/OverviewCard';
import QuickLinks from '../../components/QuickLinks';
import ServerInfo from '../../components/ServerInfo';
import Sites from '../../components/Sites';
import AtomicHostingOverview from './Cloud';

const propTypes = {};

const Overview = () => {
  const {
    data: { proServices: proServicesData },
  } = useMFEContext();

  const [isEnabled] = useFeature('sites');
  const [isWindows] = useFeature('windows');
  const [isAdvancedHosting] = useFeature('advanced_hosting');
  const [isAtomic] = useFeature('atomic');
  const [isPlesk] = useFeature('plesk');
  const [isReseller] = useFeature('reseller');
  const container = useRef(null);
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );
  const [canShowDomainSetup] = useQueryFeature('domainsetup');
  if (isAtomic) return <AtomicHostingOverview />;

  // Advanced-hosting => vps,dedi,vps_cpanel,dedi_cpanel,dedi_windows,dedi_plesk.
  if (isAdvancedHosting) {
    return (
      <Stack spacing={3}>
        <AdvancedHostingOverView
          container={container}
          hasPurchasedRecently={hasPurchasedRecently}
        />
      </Stack>
    );
  }
  return (
    <div>
      <Grid container spacing={3} columns={12}>
        {canShowDomainSetup && (
          <Grid item xs={12} className="xyz-nested">
            <Box className="domain-setup-progress-container">
              <DomainSetup />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} className="xyz-nested">
          <Box className="hosting-overview-container">
            <Box className="hosting-overview-alerts" ref={container} />
            <OverviewCard />
          </Box>
        </Grid>
        {isEnabled && (
          <Grid item xs={12} className="xyz-nested">
            <Sites />
          </Grid>
        )}
        <Grid item xs={12} className="xyz-nested">
          <BuilderSiteLogin />
        </Grid>
        <Grid item xs sm className="xyz-nested">
          <ServerInfo
            container={container}
            hasPurchasedRecently={hasPurchasedRecently}
          />
        </Grid>
        {!isWindows && (
          <Grid item xs={12} md={4} className="xyz-nested">
            <QuickLinks />
          </Grid>
        )}
        {!isPlesk && !isReseller && (
          <Grid item xs={12} className="xyz-nested">
            <DiskUsageChart />
          </Grid>
        )}
        {proServicesData?.link && (
          <Grid item xs={12} className="xyz-nested">
            <ProServicesCard proServicesData={proServicesData} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

Overview.propTypes = propTypes;
export default Overview;

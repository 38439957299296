import { useTranslation } from 'react-i18next';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import WarningIcon from '@mui/icons-material/Warning';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CTB from '~/components/CTB';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';
import useHostingPlanDetails from '~/hooks/useHostingPlanDetails';
import useScrollToLocation from '~/hooks/useScrollToLocation';

import { UsageTypes } from '.';
import Tooltip from '../../../../../../utils/CommonTooltip';

type UsagePropOptions = {
  cardContentBody: string;
  isFetching: boolean;
  cardSubTitle: string;
  fileSubTitle: string;
  usage: UsageTypes;
  disklimit?: string;
  diskused?: string;
  disklastupdated?: string | null;
  fileslimit?: string;
  filesused?: string;
  refetchDiskData: VoidFunction;
};

const Usage = ({
  cardContentBody,
  isFetching,
  cardSubTitle,
  fileSubTitle,
  usage,
  disklimit = undefined,
  diskused = undefined,
  disklastupdated = undefined,
  fileslimit = undefined,
  filesused = undefined,
  refetchDiskData,
}: UsagePropOptions) => {
  useScrollToLocation();
  const { t } = useTranslation('settings', {
    keyPrefix: 'usageStatsCard',
    useSuspense: false,
  });
  const { id: accountId } = useAccount();
  const [canUpgrade] = useFeature('hosting_upgrade');
  const { contactLink, optimizeLink } = useBrandInfo();
  const { isBluehostOnlineStorePlan } = useHostingPlanDetails(accountId);

  return (
    <>
      <CardContent data-testid="stats-info" id="usage-stats">
        <Stack spacing={2}>
          <Typography variant="body1">{cardContentBody}</Typography>
          {isFetching ? (
            <Stack
              direction="row"
              spacing={1}
              mb={2}
              mt={2}
              data-testid="stats-loader"
            >
              <Skeleton variant="circular" height={24} width={24}>
                <Avatar />
              </Skeleton>
              <Skeleton width="30%" />
            </Stack>
          ) : (
            <div>
              <Grid
                container
                columns={12}
                spacing={{ xs: 4, md: 12 }}
                justifyContent="flex-start"
              >
                {/* @ts-expect-error */}
                <Grid
                  xyz="fade left stagger-2 delay-4"
                  item
                  xs={9}
                  md={6}
                  sm={12}
                >
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyItems={'flex-start'}
                  >
                    <Typography mt={2} variant="body2">
                      {cardSubTitle}
                    </Typography>
                    <Tooltip
                      desc={
                        !usage?.hasIcon
                          ? t('diskUsageTooltip')
                          : t('warningTooltip')
                      }
                    />
                  </Stack>
                  <LinearProgress
                    variant="determinate"
                    value={usage?.percentUsed || 0}
                    sx={{ mt: 1, mb: 1 }}
                    color={usage?.variant}
                  />
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyItems={'flex-start'}
                  >
                    {usage?.hasIcon ? (
                      <WarningIcon color="error" data-testid="warning-icon" />
                    ) : (
                      ''
                    )}
                    <Typography variant="body2" data-testid="usage-desc">
                      {diskused && disklimit
                        ? `${t('statTxt', {
                            used: diskused,
                            available: disklimit,
                          })}`
                        : t('unableToLoadMsg')}
                    </Typography>
                  </Stack>
                  {fileslimit && filesused && (
                    <Stack>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        justifyItems={'flex-start'}
                        mt={1}
                      >
                        <Typography mt={4} variant="body2">
                          {fileSubTitle}
                        </Typography>
                        <Tooltip
                          testId={'file-tooltip-btn'}
                          desc={
                            !usage?.hasFileIcon
                              ? t('fileUsageTooltip')
                              : t('fileWarningTooltip')
                          }
                        />
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        value={usage?.filePercentUsed || 0}
                        color={usage?.fileVariant}
                      />
                      <Stack
                        direction={'row'}
                        spacing={1}
                        justifyItems={'flex-start'}
                        mt={1}
                      >
                        {usage?.hasFileIcon && (
                          <WarningIcon
                            color="error"
                            data-testid="file-warning-icon"
                          />
                        )}
                        <Typography
                          variant="body2"
                          data-testid="file-usage-desc"
                        >
                          {filesused && fileslimit
                            ? `${t('filesStatTxt', {
                                fused: filesused,
                                favailable: fileslimit,
                              })}`
                            : t('unableToLoadFileMsg')}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontStyle: 'italic',
                          }}
                        >
                          {`${t('lastUpdatedTxt', {
                            lastupdated: disklastupdated,
                          })}`}
                        </Typography>
                        <IconButton onClick={refetchDiskData}>
                          <CachedRoundedIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  )}
                </Grid>
                {/* @ts-expect-error */}
                <Grid xyz="fade left delay-4" item xs={12} md={6} sm={12}>
                  <Paper elevation={3}>
                    <Stack
                      direction={'row'}
                      justifyContent={'flex-start'}
                      spacing={1}
                    >
                      <Stack
                        marginLeft={2}
                        spacing={1}
                        marginTop={2}
                        direction={'row'}
                      >
                        <WbIncandescentOutlinedIcon
                          sx={{
                            transform: 'rotate(180deg)',
                          }}
                        />
                        <Typography>{t('runningOutOfSpaceTxt')}</Typography>
                      </Stack>
                    </Stack>
                    <Card variant="outlined">
                      {/* <CardActionArea> */}
                      <CardContent>
                        <Stack spacing={2}>
                          {optimizeLink ? (
                            <Stack
                              p={2}
                              borderRadius={2}
                              direction={'row'}
                              justifyContent="space-between"
                              spacing={2}
                              alignItems={'center'}
                              sx={{
                                backgroundColor: 'secondary.light',
                              }}
                            >
                              <Typography variant="h4">
                                {t('optimizeOption')}
                              </Typography>
                              <Link href={optimizeLink} target="_blank">
                                <ArrowForwardIosIcon
                                  sx={{ cursor: 'pointer' }}
                                  fontSize="small"
                                  color="action"
                                />
                              </Link>
                            </Stack>
                          ) : null}
                          {canUpgrade && !isBluehostOnlineStorePlan && (
                            <Stack
                              p={2}
                              borderRadius={2}
                              direction={'row'}
                              justifyContent="space-between"
                              spacing={2}
                              alignItems={'center'}
                              sx={{
                                backgroundColor: 'secondary.light',
                              }}
                            >
                              <Typography variant="h4">
                                {t('upgradeHostingOption')}
                              </Typography>
                              <CTB product="HOSTING_UPGRADE">
                                {/* @ts-expect-error */}
                                <ArrowForwardIosIcon
                                  sx={{ cursor: 'pointer' }}
                                  fontSize="small"
                                  color="action"
                                />
                              </CTB>
                            </Stack>
                          )}
                          {contactLink ? (
                            <Stack
                              p={2}
                              borderRadius={2}
                              direction={'row'}
                              justifyContent="space-between"
                              spacing={2}
                              alignItems={'center'}
                              sx={{
                                backgroundColor: 'secondary.light',
                              }}
                            >
                              <Typography variant="h4">
                                {t('contactSupportOption')}
                              </Typography>
                              <Link href={contactLink} target="_blank">
                                <ArrowForwardIosIcon
                                  sx={{ cursor: 'pointer' }}
                                  fontSize="small"
                                  color="action"
                                />
                              </Link>
                            </Stack>
                          ) : null}
                        </Stack>
                      </CardContent>
                      {/* </CardActionArea> */}
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
        </Stack>
      </CardContent>
    </>
  );
};
export default Usage;

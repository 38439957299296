// Remove SX prop
import { bool, shape, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { removeCTB } from './includeCTB';

/**
 * Embed is broken out of CTB as a separate component to avoid MUI Dialog ref fowarding issues
 */
const propTypes = {
  callbacks: shape({}).isRequired,
  data: shape({}).isRequired,
  isPending: bool.isRequired,
  product: string.isRequired,
};

const Embed = ({ callbacks, data, isPending, product }) => {
  const ref = useRef(null);

  const [initialized, setInitialized] = useState(false);

  const isCustomCtbWidth = [
    'WP_SOLUTION_FAMILY',
    'WP_SOLUTION_UPGRADE',
    'HOSTING_UPGRADE',
    'BH_ECOMM_ADDON_FAMILY',
  ].includes(product);

  useEffect(() => {
    let intervalId;
    const initializeClickToBuy = () => {
      if (!initialized && ref?.current?.id && window.ClickToBuy) {
        window.ClickToBuy.init({
          selector: ref.current.id,
          callbacks,
          data,
        });
        setInitialized(true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(initializeClickToBuy, 100);

    return () => {
      clearInterval(intervalId);
      removeCTB();
    };
  }, [callbacks, data, initialized]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: isCustomCtbWidth ? '1392px' : '1070px',
      }}
      data-testid="click-to-buy-embed"
    >
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={!initialized}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        id="ctb-container-uuid"
        // CTB requires its parent div to have a width of 100%
        style={{ width: '100%' }}
        ref={ref}
      />
    </Stack>
  );
};

Embed.propTypes = propTypes;
export default Embed;

import { PropsWithChildren, ReactNode } from 'react';

import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import useResponsive from '~/hooks/useResponsive';

const drawerBleeding = 22;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Drawer = ({
  children = undefined,
  isDrawerOpen,
  toggleDrawer,
}: PropsWithChildren<{
  children?: ReactNode;
  isDrawerOpen: boolean;
  toggleDrawer: (
    open: boolean,
    callback?: (to?: string) => void,
  ) => (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  ) => void;
}>) => {
  const { isIos } = useResponsive();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableBackdropTransition={!isIos()}
      disableDiscovery={isIos()}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
          minHeight: `${drawerBleeding}px`,
          borderBottom: `1px solid ${grey[300]}`,
        }}
      >
        <Puller />
        &nbsp;
      </StyledBox>
      <Box
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default Drawer;

import { styled } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts';

interface CustomPieChartCenterLabelProps {
  outOfRange?: boolean;
  label: string;
  secondaryLabel?: string;
}
interface StyledTextProps {
  textType: 'primary' | 'secondary';
  outOfRange?: boolean;
}

const StyledText = styled('text')<StyledTextProps>(
  ({ theme, textType, outOfRange }) => ({
    textAnchor: 'middle',
    dominantBaseline: 'middle',
    fontSize: textType === 'primary' ? 26 : 14,
    fill: outOfRange ? theme.palette.error.main : 'text',
  }),
);

export default function PieChartCenterLabel({
  label,
  outOfRange = false,
  secondaryLabel = '',
}: CustomPieChartCenterLabelProps) {
  const { width, height, left, top } = useDrawingArea();
  const centerXAxis = left + width / 2;
  const centerYAxis = top + height / 2;
  return (
    <>
      <StyledText
        x={centerXAxis}
        y={centerYAxis}
        textType="primary"
        outOfRange={outOfRange}
      >
        {label}
      </StyledText>
      {secondaryLabel && (
        <StyledText
          x={centerXAxis}
          y={centerYAxis + 30}
          textType="secondary"
          outOfRange={outOfRange}
        >
          {secondaryLabel}
        </StyledText>
      )}
    </>
  );
}

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CardLoader = ({
  title,
  diskChartTitle,
  fileUsageChartTitle,
}: {
  title: string;
  fileUsageChartTitle: string;
  diskChartTitle: string;
}) => {
  return (
    <Card>
      <CardContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          mb={2}
        >
          <Typography variant="h2">{title}</Typography>
        </Stack>
        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
          <Box flex={1}>
            <Paper elevation={1}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography variant="h3">{diskChartTitle}</Typography>
                  </Box>
                  <Skeleton width={200} height={200} animation="wave" />
                </CardContent>
              </Card>
            </Paper>
          </Box>
          <Box flex={1}>
            <Paper elevation={1}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography variant="h3">{fileUsageChartTitle}</Typography>
                  </Box>
                  <Skeleton width={200} height={200} animation="wave" />
                </CardContent>
              </Card>
            </Paper>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardLoader;

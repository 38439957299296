import { useTenant } from '../..';
import TenantEmpty from '../TenantEmpty';
import TenantProvisioning from '../TenantProvisioning';
import TimeoutError from './components/TimeoutError';
import UnexpectedError from './components/UnexpectedError';

export default function TenantError() {
  const { isEmpty, isProvisioning, isUnexpectedError, isTimeoutError } =
    useTenant();

  // could we fetch tenant list?
  // No?

  // are we provisioning?
  if (isProvisioning) return <TenantProvisioning />;

  if (isTimeoutError) return <TimeoutError />;

  // !! UNEXPECTED HAL ERROR
  // Options:
  // - contact support
  // - create a new hosting account
  // - visit the renewal center
  if (isUnexpectedError) return <UnexpectedError />;

  // could we fetch tenant list?
  // Yes?
  // how many tenants do we have?
  if (isEmpty) return <TenantEmpty />;

  // Default error? Or null?
  return null;
}

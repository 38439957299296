import axios from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { getBrandFromSub } from '~/utils/extractJWTInfo';

import { isTestMode } from '../components/DevMode';

const DEFAULT_INTERVAL = 1000 * 60 * 2; // 2 minutes
const TEST_INTERVAL = 1000; // 1 second
const JWT_REFRESH_INTERVAL = isTestMode ? TEST_INTERVAL : DEFAULT_INTERVAL;

interface JwtPayloadTypes extends JwtPayload {
  act?: {
    sub?: string;
    originatorUserId?: string;
  };
}
interface DecodedTokenTypes extends JwtPayloadTypes {
  appTokenUserId?: string;
  brand?: string;
  env?: string;
  iatMs?: number;
  expMs: number;
  sessionLengthMs: number;
  isValid: boolean;
}

export const decodeToken = (token: string) => {
  let decodedToken: JwtPayloadTypes | undefined = undefined;
  try {
    if (!!token) {
      decodedToken = jwt_decode(token);
    }
  } catch (error) {
    console.error(
      'useJwtAutoRefresh decodeToken - failed to decode provided jwt: ',
      error,
    );
  } finally {
    if (decodedToken === undefined) {
      return {
        expMs: 0,
        sessionLengthMs: 0,
        isValid: false,
      } as DecodedTokenTypes;
    }
  }

  const brand = getBrandFromSub(decodedToken?.sub);
  const env = decodedToken?.aud;
  const iatMs = Number(decodedToken?.iat) * 1000;
  const expMs = Number(decodedToken?.exp) * 1000;

  const appTokenUserId = decodedToken?.act?.sub?.split(':').pop();
  const originatorUserId = decodedToken?.act?.originatorUserId;

  return {
    ...decodedToken,
    appTokenUserId,
    originatorUserId,
    brand,
    env,
    iatMs,
    expMs,
    // difference between the tokens exp and iss time in milliseconds
    sessionLengthMs: expMs - iatMs,
    isValid: !!token && !!decodedToken,
  } as DecodedTokenTypes;
};

export const useJwtAutoRefresh = (
  setAccessToken: (arg: string | void) => void,
  refreshAccessToken: () => Promise<string>,
) => {
  // Keep track of jwt expiration
  const [localIssuedTime, setLocalIssuedTime] = useState(Date.now());

  const refreshToken = async () => {
    // generate new token
    const newAccessToken = await refreshAccessToken().catch((e) => {
      console.error('useJwtAutoRefresh - token refresh callback failure:', e);
      // Refresh page in the event of an error, causing AMM to provide a fresh jwt
      // or cause the user to login again if their session has expired
      window.location.reload();
    });

    // when accessToken changes, update the locally tracked issued time
    setLocalIssuedTime(Date.now());

    // set state
    setAccessToken(newAccessToken);

    // set global header
    axios.defaults.headers = {
      // @ts-expect-error
      Authorization: `Bearer ${newAccessToken}`,
    };

    // reset timer
    clearTimeout(refreshTimer);
    setRefreshTimer(setTimeout(refreshToken, JWT_REFRESH_INTERVAL));
    return newAccessToken;
  };

  const [refreshTimer, setRefreshTimer] = useState(() =>
    setTimeout(refreshToken, JWT_REFRESH_INTERVAL),
  );

  /**
   * isTokenExpired - perform a comparison of our local record of the jwt
   * creation time against its own lifespan calculation, as well as a direct
   * check on the token's expiration. NOTE: the first check depends on
   * having a valid token that was generated with an iat at the same time as the
   * refresh token methods response.
   *
   * @returns {boolean} - true if the token is expired, false if it is still good
   */
  const isTokenExpired = (accessToken: string) => {
    const { expMs, isValid, sessionLengthMs } = decodeToken(accessToken);

    // validation
    if (!isValid) return false;

    // amount of ms buffer before considering token expired
    const bufferMs = 30 * 1000; // 30 seconds

    // compare the local time against the jwt lifespan
    const currentTime = Date.now();
    const localSessionLength = currentTime - localIssuedTime;
    const jwtLengthWithBuffer = sessionLengthMs - bufferMs;

    const tokenIsGood =
      localSessionLength < jwtLengthWithBuffer && // is local session still good?
      currentTime < expMs - bufferMs; // is token itself still good?

    return !tokenIsGood;
  };

  // unmount cleanup
  useEffect(() => {
    return () => {
      // clear the refresh timer to prevent it from executing
      // after the react app is removed from the host app
      clearTimeout(refreshTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isTokenExpired,
    refreshToken,
  };
};

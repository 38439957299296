import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';

const FtpAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ftp } = t('scenes.advanced', { returnObjects: true }) as {
    ftp: {
      title: string;
      tooltipText: string;
      manageBtn: string;
    };
  };

  const [hasSites] = useFeature('sites');
  const [isDestiny] = useFeature('destiny');
  const [isCPanel] = useFeature('cpanel');

  const { brandFromJWT } = useBrandInfo();
  const isBlueHost = brandFromJWT.toLowerCase() === 'bluehost';

  if (!hasSites) {
    return null;
  }

  return (
    <InfoListItem
      // @ts-expect-error
      title={ftp?.title}
      // @ts-expect-error
      tooltipText={ftp?.tooltip}
      {...{
        inlineCTA:
          (isDestiny || isCPanel) && isBlueHost ? (
            <Button variant="text" href={'/ftp-access'} color="primary">
              {ftp?.manageBtn}
            </Button>
          ) : (
            <SsoLink
              variant="text"
              type={'hosting'}
              queryParams={{ app: 'ftp' }}
            >
              {ftp?.manageBtn}
            </SsoLink>
          ),
      }}
    />
  );
};

export default FtpAccessInfo;

import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import { PROMOTION_IN_PROGRESS } from './stagingConstants';

/*
Promotion flow
--------------

Initial state:
Site 1
  { id: 123, config.site_type: 'staging', meta_parent_id: 456, promotion_status: 'not_promoting' }
Site 2
  { id: 456, config.site_type: 'billable' }

*Promote Site 1*

Immediate update:
Site 1
  { id: 123, config.site_type: 'staging', meta_promoting_from: 123, promotion_status: 'in_progress' }
Site 2
  { id: 456, config.site_type: 'staging', meta_parent_id: 456, promotion_status: 'not_promoting' }

Promotion finished:
Site 1
  { id: 123, config.site_type: 'billable', meta_promoted_from: 123 }
Site 2
  { id: 456, config.site_type: 'staging', meta_parent_id: 456, promotion_status: 'not_promoting' }
*/

export const checkIsStagingSite = (
  site?: SitesInfo200 | SiteInfoResponseModelV2 | HostingSitesV2200ItemsItem,
  // Any time there is a parent_site_id, consider it a staging site
) => !!site && !!site?.staging?.parent_site_id;

export const checkIsParentSite = (
  site?: SitesInfo200 | SiteInfoResponseModelV2 | HostingSitesV2200ItemsItem,
) =>
  !!site &&
  !site?.staging?.parent_site_id &&
  // Is this site currently promoting from a staging site to a parent site?
  // This covers the transitional period between starting promotion and the final domain moving over
  ((!!site?.staging?.promoting_from &&
    site?.staging?.promotion_status === PROMOTION_IN_PROGRESS) ||
    // Was this a staging site previously?
    !!site?.staging?.promoted_from ||
    // Does this site currently have staging sites?
    (!!site?.staging?.sites && site?.staging?.sites.length > 0));

import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import Copy from '~/components/Copy';
import useHostingInfo from '~/hooks/useHostingInfo';

import AdvancedHostingViewIPModal from './ViewIPModal';

interface Props {
  serverData: any;
  isPending: boolean;
}

const AdvancedHostingIpInfo = ({
  serverData,
  isPending,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });

  const { isDisabled } = useHostingInfo();

  const [openIPModal, setOpenIPModal] = useState(false);
  const showIPModal = () => setOpenIPModal(true);

  return (
    <>
      {isPending ? (
        <Skeleton width="90%" />
      ) : (
        <>
          {serverData?.data?.ips?.other_ips ? (
            <Typography variant="body2" textAlign={'left'}>
              {serverData?.data?.ips?.primary_ip ?? ''}
              <Link
                component="button"
                onClick={showIPModal}
                disabled={isDisabled}
                sx={{ pl: 0.5 }}
              >
                {t('advHostingServerInfo.viewIpBtn')}
              </Link>
            </Typography>
          ) : (
            <Copy copyValue={serverData?.data?.ips?.primary_ip ?? ''}>
              <Typography variant="body2" textAlign={'left'}>
                {serverData?.data?.ips?.primary_ip ?? ''}
              </Typography>
            </Copy>
          )}
        </>
      )}
      <AdvancedHostingViewIPModal
        openIPModal={openIPModal}
        setOpenIPModal={setOpenIPModal}
        ips={serverData?.data?.ips?.other_ips}
      />
    </>
  );
};

export default AdvancedHostingIpInfo;

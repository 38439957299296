import { ReactNode, SyntheticEvent } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useMoreActionsMenu } from '..';

// TODO: This was pulled as-is from DomainRow/DomainRowActionItem and can probably be made much more flexible
const MoreActionsItem = ({
  label,
  tooltipTxt = undefined,
  disabled,
  onClick = () => {},
  dataTestId,
  color = undefined,
}: {
  label: string;
  tooltipTxt?: string | ReactNode;
  disabled: boolean;
  onClick?: (e: SyntheticEvent) => void;
  dataTestId: string;
  color?: string;
}) => {
  const { handleClose } = useMoreActionsMenu();

  return (
    <Tooltip title={tooltipTxt} arrow placement="left">
      {/* This div allows the Tooltip to trigger while allowing the MenuItem to be disabled */}
      <div>
        <MenuItem
          disabled={disabled}
          onClick={(e) => {
            // close the menu
            handleClose();
            // execute the action
            return onClick(e);
          }}
          data-testid={dataTestId}
        >
          <Typography color={color}>{label}</Typography>
        </MenuItem>
      </div>
    </Tooltip>
  );
};

export default MoreActionsItem;

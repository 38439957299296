import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export default function TenantProvisioning() {
  const { t } = useTranslation('tenant');

  return (
    <Card>
      <Box sx={{ width: '100%' }} data-testid="tenant-linear-progress-bar">
        <LinearProgress sx={{ height: 6 }} />
      </Box>
      <CardHeader title={t('provisioning.title')} />
      <CardContent>
        <Typography>{t('provisioning.description')}</Typography>
        <Typography>{t('provisioning.subDescription')}</Typography>
      </CardContent>
    </Card>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingSitesV2QueryKey,
  getSitesDomainsV2QueryKey,
  getSitesInfoQueryKey,
  useSitesInfo,
  useStagingSitePromote,
} from '@newfold/huapi-js';

import { setAlertMessage } from '~/apps/Databases/scenes/RemoteMySQLList/utils/alert';
import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import { useTenant } from '~/components/TenantProvider';
import useAccount from '~/hooks/useAccount';
import { eventWatchRequestPropOptions } from '~/hooks/useAccountEvents';
import { useBrandInfo } from '~/hooks/useBrandInfo';

import {
  EVENT_PROMOTE_DONE,
  PROMOTION_FAILED,
  PROMOTION_IN_PROGRESS,
} from '../../../utils/stagingConstants';
import PromoteInProgressModal from './PromoteInProgressModal';

export type PromoteModalPropsOptions = {
  open: boolean;
  handleClose: Function;
  siteId: number | string;
  domain: string;
  parentDomain: string;
  siteName?: string | null;
  parentSiteId: number;
};

const PromoteModal = ({
  open,
  handleClose,
  siteId,
  domain,
  siteName = undefined,
  parentDomain,
  parentSiteId,
}: PromoteModalPropsOptions) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'staging.promoteSite.promoteModal',
  });

  const generateAlert = useAlert();

  const navigate = useNavigate();
  const location = useLocation();

  const { cloudStagingPromoteKb, phoneNumberFormatted } = useBrandInfo();

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [pollingActive, setPollingActive] = useState(false);
  const [modalTimeoutId, setModalTimeoutId] = useState<
    NodeJS.Timeout | undefined | null
  >(undefined);

  const { id: hostingId } = useAccount();
  const { callbacks } = useTenant();

  const queryClient = useQueryClient();
  const newQueryKeys: any[] = [
    getHostingSitesV2QueryKey(hostingId, { parent_site_id: Number(siteId) }),
    getSitesInfoQueryKey(Number(siteId)),
    getHostingSitesV2QueryKey(hostingId, { parent_site_id: parentSiteId }),
    getSitesInfoQueryKey(parentSiteId),
    getSitesDomainsV2QueryKey(String(siteId)),
    getSitesDomainsV2QueryKey(String(parentSiteId)),
  ];

  const {
    mutate: promoteSite,
    isPending,
    isSuccess: isSuccessSitePromote,
  } = useStagingSitePromote({
    mutation: {
      onError: () => {
        generateAlert({
          severity: 'error',
          description: (
            <Trans
              i18nKey={t('alertMessage.error')}
              values={{ phoneNumber: phoneNumberFormatted }}
            />
          ),
          showCloseBtn: true,
          persist: true,
        });
        removeQueryKeysAndCloseModals();
      },
    },
  });

  const {
    data: siteInfo,
    refetch: refetchSiteInfo,
    isSuccess: isSuccessSiteInfo,
  } = useSitesInfo(Number(siteId), {
    query: {
      enabled:
        !!siteId && showProgressModal && pollingActive && isSuccessSitePromote,
      refetchInterval: 5000,
    },
  });

  useEffect(() => {
    if (isSuccessSitePromote) {
      //enable polling as soon as receiving job id
      setPollingActive(true);

      //enable 30 sec timer as soon as receiving job id
      const timeoutId = setTimeout(() => {
        setPollingActive(false);
        setModalTimeoutId(null);
      }, 60000);

      if (!!timeoutId) setModalTimeoutId(timeoutId);
    }
  }, [isSuccessSitePromote, refetchSiteInfo]);

  const redirectAfterPromotion = () => {
    // If we started in a parent site's staging list, go to the new parent site's staging list
    if (location.pathname.includes('/staging'))
      navigate(`/sites/${siteId}/staging`);
  };

  useEffect(() => {
    // We only want this to run if each api call (not the promotion process itself) was successful
    if (isSuccessSiteInfo && isSuccessSitePromote) {
      const isPromotionComplete =
        siteInfo?.data?.staging?.promotion_status === null &&
        siteInfo?.data?.staging?.promoted_from !== null;

      const isPromotionStable =
        // Is promomtion complete?
        isPromotionComplete ||
        // or is promotion in progress?
        (!!siteInfo?.data?.staging?.promoting_from &&
          siteInfo?.data?.staging?.promotion_status === PROMOTION_IN_PROGRESS);

      const isPromotionFailed =
        siteInfo?.data?.staging?.promotion_status === PROMOTION_FAILED;

      // if the backend query is completed first irrespective of polling complete
      if (isPromotionStable && !isPromotionFailed) {
        // If we're all finished here
        if (isPromotionComplete) {
          generateAlert({
            severity: 'info',
            description: t('alertMessage.success', {
              siteName: siteName,
            }),
            showCloseBtn: true,
          });

          // Clean up the timer if we finish before it runs out
          if (!!modalTimeoutId) {
            clearTimeout(modalTimeoutId);
            setModalTimeoutId(null);
          }

          removeQueryKeysAndCloseModals();

          redirectAfterPromotion();
        }
        // if polling is complete but backend update have not been completed
        else if (!pollingActive) {
          callbacks.subscribeToEvent({
            events: [EVENT_PROMOTE_DONE],
            resourceIds: {
              hosting: [String(siteInfo?.data?.account_id)],
              site: [String(siteId), String(parentSiteId)],
            },
            queryKeys: newQueryKeys,
          } as eventWatchRequestPropOptions);

          // TODO: this should be ephemeral
          generateAlert({
            severity: 'info',
            description: t('alertMessage.polling', {
              siteName: siteName,
            }),
            showCloseBtn: true,
            persist: true,
          });

          removeQueryKeysAndCloseModals();

          redirectAfterPromotion();
        }
      }

      if (isPromotionFailed) {
        setAlertMessage('error', t('alertMessage.error'));
        removeQueryKeysAndCloseModals();
      }
    }

    // Do we need both siteInfo and isSuccessSiteInfo in the dep array? Are they causing too many useEffect triggers?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteInfo,
    showProgressModal,
    isSuccessSiteInfo,
    isSuccessSitePromote,
    pollingActive,
  ]);

  // Close promotion modals and reset state values
  const closeModals = () => {
    setPollingActive(false);
    setShowProgressModal(false);
    handleClose();
  };

  const removeQueryKeysAndCloseModals = () => {
    //remove query keys to allow parent components to reload on mount. Helps with navigating to the same page.
    newQueryKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key });
    });

    closeModals();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = () => {
    setShowProgressModal(true);
    promoteSite({ siteId: siteId as number, data: {} });
  };

  if (showProgressModal) {
    return <PromoteInProgressModal open={open} />;
  }

  return (
    <>
      <DialogTitle>
        {t('title')}
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} py={2}>
              <WarningIcon color="warning" />
              <Stack direction="column">
                <Typography component="div" variant="body1">
                  <Trans
                    t={t}
                    i18nKey="desc.para1"
                    values={{ domain, parentDomain }}
                    components={{
                      b: <b />,
                    }}
                  />
                </Typography>
                <Typography component="div" variant="body1" pb={4} pt={3}>
                  <Trans
                    t={t}
                    i18nKey="desc.para2"
                    components={{
                      Link: (
                        <Link href={cloudStagingPromoteKb} target="_blank" />
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} alignItems={'center'}>
              <Box
                sx={{
                  width: 330,
                  height: 41,
                  borderRadius: 2,
                  bgcolor: 'secondary.main',
                }}
              >
                <Typography
                  variant="h3"
                  noWrap
                  color="common.white"
                  align="center"
                  pt={1}
                >
                  {parentDomain}
                </Typography>
              </Box>

              <Stack direction="row" spacing={1}>
                <ArrowUpwardIcon color="action" fontSize="large" />
                <ArrowDownwardIcon color="action" fontSize="large" />
              </Stack>
              <Box
                sx={{
                  width: 330,
                  height: 41,
                  borderRadius: 2,
                  bgcolor: 'warning.A200',
                }}
              >
                <Typography variant="h3" noWrap align="center" pt={1}>
                  {domain}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="body1" pt={5}>
              {t('desc.para3')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel} disabled={isPending}>
          {t('cancelBtn')}
        </Button>
        <LoadingButtonWrapper
          data-testid="goLiveBtn"
          variant="contained"
          loading={isPending}
          onClick={handleSubmit}
        >
          {t('goLiveBtn')}
        </LoadingButtonWrapper>
      </DialogActions>
    </>
  );
};
export default PromoteModal;

import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  getHostingAdvancedServerQueryKey,
  useHostingAdvancedPassword,
} from '@newfold/huapi-js';
import { HostingAdvancedPasswordBody } from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import { deepClone } from '~/utils/deepClone';
import {
  isNumeric,
  isValidCase,
  isValidCharacters,
  isValidLength,
} from '~/utils/passwordValidations';

interface ResetPasswordModalPropOptions {
  openPasswordResetModal: boolean;
  setOpenPasswordResetModal: React.Dispatch<React.SetStateAction<boolean>>;
  showResetPasswordSuccessModal: () => void;
}

const AdvancedHostingResetPasswordModal: React.FC<
  ResetPasswordModalPropOptions
> = ({
  openPasswordResetModal,
  setOpenPasswordResetModal,
  showResetPasswordSuccessModal,
}) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'resetPasswordCard.resetPassword.modal',
  });

  const generateAlert = useAlert();
  const { id: accountId } = useAccount();

  const [validPassword, setValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [upperCaseValid, setUpperCaseValid] = useState(false);
  const [specialCaseValid, setSpecialCaseValid] = useState(false);
  const [numberCaseValid, setNumberCaseValid] = useState(false);
  const [lengthCaseValid, setLengthCaseValid] = useState(false);

  const queryClient = useQueryClient();
  const queryKey = getHostingAdvancedServerQueryKey(accountId);
  const { mutate: resetPassword, isPending } = useHostingAdvancedPassword({
    mutation: {
      onSuccess: (data) => {
        showResetPasswordSuccessModal();
      },
      onError: (error) => {
        generateAlert({
          severity: 'error',
          description: t('failureMsg'),
          showCloseBtn: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    },
  });

  const handleCancel = () => {
    setValue('password', '');
    setValidPassword(false);
    setUpperCaseValid(false);
    setSpecialCaseValid(false);
    setLengthCaseValid(false);
    setNumberCaseValid(false);
    setOpenPasswordResetModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<HostingAdvancedPasswordBody>({
    defaultValues: {},
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target.value) {
      setValue('password', '');
      setUpperCaseValid(false);
      return setValidPassword(false);
    }

    const { isUpperCase } = isValidCase(event.target.value);
    const { isSplCharacters } = isValidCharacters(event.target.value);
    const { isNumber } = isNumeric(event.target.value);
    const { isMinLength } = isValidLength(event.target.value);

    setUpperCaseValid(isUpperCase);
    setSpecialCaseValid(isSplCharacters);
    setNumberCaseValid(isNumber);
    setLengthCaseValid(isMinLength);
  };

  const onSubmit: SubmitHandler<HostingAdvancedPasswordBody> = (data) => {
    const { ...newData } = data;
    const formData: HostingAdvancedPasswordBody = deepClone(newData);

    resetPassword({
      hostingId: accountId,
      data: {
        password: formData.password,
      },
    });
    setValue('password', '');

    setValidPassword(false);
    setUpperCaseValid(false);
    setSpecialCaseValid(false);
    setLengthCaseValid(false);
    setNumberCaseValid(false);
    setOpenPasswordResetModal(false);
  };

  const isValidPasswordSet =
    upperCaseValid && specialCaseValid && numberCaseValid && lengthCaseValid;

  return (
    <Dialog open={openPasswordResetModal} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pt: 3.5, pb: 0.5 }}>{t('title')}</DialogTitle>
      <Divider sx={{ mr: 3, ml: 3 }} />

      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={1.5}>
            <WarningIcon color="error" sx={{ pt: 0.5 }} />
            <Typography>
              <Trans i18nKey="settings:resetPasswordCard.resetPassword.modal.modalDesc" />
            </Typography>
          </Stack>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1}>
              <TextField
                fullWidth
                size="small"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                required
                error={errors.password ? !validPassword : false}
                autoComplete="new-password"
                sx={{ maxWidth: 'sm' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('iconText')}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        sx={{ p: 0 }}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password', { onChange: handleChange })}
              />

              <List dense sx={{ pb: 2.5 }}>
                <Typography variant="h4" sx={{ pb: 0.5 }}>
                  {t('subHead')}
                </Typography>
                <ListItem sx={{ p: 0 }}>
                  <CircleIcon
                    color={lengthCaseValid ? 'success' : 'disabled'}
                    sx={{ fontSize: 8 }}
                  />
                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={t('passwordValidationTxt.item1')}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <CircleIcon
                    color={upperCaseValid ? 'success' : 'disabled'}
                    sx={{ fontSize: 8 }}
                  />
                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={t('passwordValidationTxt.item2')}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <CircleIcon
                    color={specialCaseValid ? 'success' : 'disabled'}
                    sx={{ fontSize: 8 }}
                  />
                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={t('passwordValidationTxt.item3')}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <CircleIcon
                    color={numberCaseValid ? 'success' : 'disabled'}
                    sx={{ fontSize: 8 }}
                  />

                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={t('passwordValidationTxt.item4')}
                  />
                </ListItem>
              </List>
            </Stack>

            <DialogActions sx={{ p: 0 }}>
              <Button variant="text" onClick={handleCancel}>
                {t('cancelBtn')}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isPending}
                disabled={!validPassword && !isValidPasswordSet}
              >
                {t('resetPasswordBtn')}
              </LoadingButton>
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AdvancedHostingResetPasswordModal;

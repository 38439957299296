import { string } from 'prop-types';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const propTypes = {
  message: string,
};

const LoadingScene = ({ message = null }) => (
  <Box
    sx={{
      // center the spinner
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      // add spacing around loader
      minHeight: '50vh',
      p: 6,
    }}
  >
    <CircularProgress />
  </Box>
);

LoadingScene.propTypes = propTypes;
export default LoadingScene;

import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useSite } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import MoreActionsMenu from '~/components/MoreActionsMenu';
import MoreActionsItem from '~/components/MoreActionsMenu/components/MoreActionsItem';
import SsoLink from '~/components/SsoLink';
import useHostingInfo from '~/hooks/useHostingInfo';
import usePromoteSiteValidateOnly from '~/hooks/usePromoteSiteValidateOnly';
import { useSiteContext } from '~/scenes/Site';
import DeleteSiteModal from '~/scenes/Site/scenes/Cloud/components/DeleteSiteFork/DeleteSiteModal';
import PromoteSite from '~/scenes/Site/scenes/Cloud/scenes/Staging/components/PromoteSite';
import { checkIsStagingSite } from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/checkIsStagingOrParentSite';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import { getSiteDisabled } from '~/utils/getSiteDisabled';

const installingStatuses = ['pending_create', 'create'];
const failedInstallingStatuses = ['failed_create'];
const configuringStatuses = ['pending_configure', 'configure'];
const failedConfigureStatus = ['failed_configure'];
const deletingStatuses = ['pending_delete', 'delete'];

export default function SiteCardActions({
  site,
}: PropsWithChildren<{
  site: HostingSitesV2200ItemsItem | SiteInfoResponseModelV2;
}>) {
  const { t } = useTranslation(['sites', 'cloud']);

  const { isDisabled: isHostingDisabled } = useHostingInfo();
  const generateAlert = useAlert();

  const { promotionInProgress } = useSiteContext();

  const isStagingSite = checkIsStagingSite(site);

  const [openDeleteSiteModal, setOpenDeleteSiteModal] = useState(false);
  const showDeleteSiteModal = () => setOpenDeleteSiteModal(true);

  const [isDeleting, setIsDeleting] = useState(false);

  const siteId = site?.id;
  const hasUrlOrPendingUrl: boolean = !!site?.url || !!site?.pending_url;
  const siteStatus = site?.status!;
  const isMigration = !!site?.migration_id;
  const isSiteDisabled = getSiteDisabled(siteStatus);
  const disableSSO = isHostingDisabled || !siteId || isSiteDisabled;

  // Can be improved later when handling more than one site.
  const iconButtonRef = useRef<HTMLButtonElement | null>(null);
  const actionDelete = localStorage.getItem('action');
  useEffect(() => {
    if (!!actionDelete && actionDelete === 'showDeleteSite') {
      iconButtonRef?.current?.click();
      localStorage.removeItem('action');
    }
  }, [actionDelete]);

  const { mutate: deleteSite, isPending: isPendingSiteDeletion } = useSite({
    mutation: {
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('cloud.deleteSite.alertMessage.error'),
          showCloseBtn: true,
        });
      },
    },
  });

  const handleSubmit = () => {
    deleteSite({ siteId: Number(site?.id!), data: {} });
    setIsDeleting(true);
  };

  const {
    openPromoteSiteModal,
    setOpenPromoteSiteModal,
    handleSitePromote,
    sitePromoteResp,
  } = usePromoteSiteValidateOnly();

  const { isPending: isPendingSitePromoteValidation } = sitePromoteResp;

  if (!siteStatus) return null;

  // Migration Site Failed
  if (isMigration && failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion || isDeleting}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Migrating Site
  if (isMigration && installingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Configure Site Failed
  if (failedConfigureStatus.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="warning"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.configureFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedConfigureToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
      </Stack>
    );

  // Configuring Site
  if (configuringStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.configuring')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Deleting Site
  if (deletingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="error"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} color="error" />}
        >
          <Typography variant="body2" color={'secondary'}>
            {t('disableSite.deleting')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Delete Site Failed
  if (siteStatus.includes('failed_delete'))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="error"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.deleteFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedDeleteToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion || isDeleting}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Create Site Failed
  if (failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.installingWordPress')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedCreateToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion || isDeleting}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Creating Site
  if (siteStatus?.includes('create'))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.installingWordPress')}
          </Typography>
        </Alert>
      </Stack>
    );

  if (siteStatus?.includes('active')) {
    // Active Site - staging
    if (isStagingSite) {
      return (
        <Stack direction={'row'} spacing={1}>
          {openPromoteSiteModal && (
            <PromoteSite
              open={openPromoteSiteModal}
              handleClose={() => setOpenPromoteSiteModal(false)}
              stagingSite={site}
              sitePromoteResp={sitePromoteResp}
            />
          )}
          {/* 'Promote' button that opens the promote flow modal */}
          <Button
            aria-label={'promote staging site'}
            data-testid={'staging-promote-button'}
            sx={{ flex: { xs: 1, sm: 'auto' } }}
            variant="outlined"
            onClick={() => handleSitePromote(Number(siteId))}
            disabled={
              isHostingDisabled ||
              promotionInProgress ||
              isPendingSitePromoteValidation
            }
          >
            {promotionInProgress
              ? t('cloud:staging.promoteSite.promoteBtnActive')
              : t('cloud:staging.promoteSite.promoteBtn')}
          </Button>
          {/* 'Manage' button that takes you to that site's Overview */}
          <Button
            aria-label={'manage staging site'}
            data-testid={'staging-manage-button'}
            sx={{ flex: { xs: 1, sm: 'auto' } }}
            variant="contained"
            href={siteId ? `/sites/${siteId}` : undefined}
            disabled={!siteId || isHostingDisabled}
          >
            {t('cloud.staging.manageBtnText')}
          </Button>
          {openDeleteSiteModal && (
            <Dialog
              open={openDeleteSiteModal}
              aria-labelledby="delete-site-modal-title"
              aria-describedby="delete-site-modal-description"
              maxWidth={'sm'}
              fullWidth
            >
              <DeleteSiteModal
                open={openDeleteSiteModal}
                setOpen={setOpenDeleteSiteModal}
                siteUrl={
                  (hasUrlOrPendingUrl && (site?.pending_url || site?.url)) ||
                  t('deleteSite.deleteSiteBtn').toUpperCase()
                }
                siteId={site?.id as number}
                siteName={site?.name!}
                parentSiteId={site?.staging?.parent_site_id!}
              />
            </Dialog>
          )}
          {/* Hamburger button for additional actions */}
          {/* This menu should only include options for Delete Site and WPAdmin SSO for now */}
          <MoreActionsMenu buttonRef={iconButtonRef}>
            <MoreActionsItem
              aria-label={'delete staging site'}
              label={t('cloud.staging.deleteBtnText')}
              dataTestId={'staging-delete-button'}
              disabled={isHostingDisabled}
              onClick={showDeleteSiteModal}
            ></MoreActionsItem>
            <SsoLink
              aria-label={'sso to staging site wordpress admin'}
              dataTestId={'staging-sso-button'}
              type="site"
              variant="menuItem"
              disabled={disableSSO}
              id={siteId}
              // @ts-expect-error
              sx={{ flex: { xs: 1, sm: 'auto' } }}
            >
              {t('cloud.staging.wpAdminBtnText')}
            </SsoLink>
          </MoreActionsMenu>
        </Stack>
      );
    }

    // Active Site - production
    return (
      <Stack direction={'row'} spacing={1}>
        <Button
          aria-label={'manage site'}
          data-testid={'prod-manage-button'}
          sx={{ flex: { xs: 1, sm: 'auto' } }}
          variant="outlined"
          href={siteId ? `sites/${siteId}` : undefined}
          disabled={isHostingDisabled}
        >
          {t('cloud.manageBtnText')}
        </Button>

        {/* wordpress sso button */}
        <SsoLink
          aria-label={'sso to site wordpress admin'}
          data-testid={'prod-sso-button'}
          type="site"
          variant="contained"
          disabled={disableSSO}
          id={siteId}
          // @ts-expect-error
          sx={{ flex: { xs: 1, sm: 'auto' } }}
          startIcon={<WordPressIcon color="#fff" />}
        >
          {t('cloud.wpAdminBtnText')}
        </SsoLink>
      </Stack>
    );
  }
}

import { Trans, useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Copy from '~/components/Copy';

type ViewIPModalPropOptions = {
  openIPModal: boolean;
  setOpenIPModal: (state: boolean) => void;
  ip?: string;
};

const ViewIPModal = ({
  openIPModal,
  setOpenIPModal,
  ip = undefined,
}: ViewIPModalPropOptions) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.ipAddress.modal',
  });

  const handleClose = () => {
    setOpenIPModal(false);
  };

  //TODO: Removed the learn more link to advanced/ssh. as the nameserver info available in hosting overview page. If necessary, with the product.
  return (
    <Dialog
      open={openIPModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="row" spacing={2} pb={2}>
          <WarningIcon color="error" />
          <Typography>
            <Trans i18nKey="settings:nameServerAndIPCard.ipAddress.modal.desc" />
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{t('subTitle')}</Typography>
          <Typography>
            <b>{ip}</b>
          </Typography>
          <Copy copyValue={ip} disable={!ip} />
        </Stack>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2} pt={0}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          {t('closeBtn')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ViewIPModal;

// import StorageIcon from '@mui/icons-material/Storage';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import { getSitesInfoQueryKey } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
} from '@newfold/huapi-js/src/index.schemas';

import { invalidateMultipleQueries } from '~/components/MFEProvider/lib/HttpClient/queryClient';
import { Pagination } from '~/components/Pagination';
import SearchBar from '~/components/VisionUI/SearchBar';
import useAccount from '~/hooks/useAccount';
import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';
import useRefetchInterval from '~/hooks/useRefetchInterval';
import AddSiteButton from '~/scenes/Hosting/components/AddSiteButton';
import { SITE_LIST_PAGE_SIZE } from '~/scenes/Sites/constants';

import SiteCard from './components/SiteCard';
import SitesEmpty from './components/SitesEmpty';
import SitesLoading from './components/SitesLoading';

const Sites = () => {
  const { t } = useTranslation('sites');
  const addSiteBtnTranslation = t('cloud.addSiteBtn', {
    wp: `${t('platform.wordpress')} `,
  });
  const queryClient = useQueryClient();

  const { id: hostingId } = useAccount();
  const { refetchTime } = useRefetchInterval();

  const [pendingSiteQueries, setPendingSiteQueries] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(SITE_LIST_PAGE_SIZE);
  const [page, setPage] = useState(1);

  const siteListParams = { limit: pageSize, page: page, search: '' };

  if (searchQuery) {
    siteListParams['search'] = searchQuery;
  }

  const {
    data: hostingSites,
    isPending,
    isSuccess,
  } = useFilteredHostingSites(hostingId, siteListParams, {
    query: {
      enabled: !!hostingId,
      refetchInterval: (query) => {
        const sites = query?.state?.data?.data?.items;
        const keepPolling: any = sites?.some((i: any) => i.status !== 'active');
        return keepPolling && refetchTime;
      },
      refetchIntervalInBackground: true,
    },
  });

  // onSuccess - useFilteredHostingSites
  useEffect(() => {
    if (isSuccess && hostingSites) {
      (async () => {
        const sites = hostingSites?.data?.items;
        const pendingSites = sites?.filter((i) => i.status !== 'active') ?? [];
        const hasPendingSites = pendingSites?.length > 0;
        const hasPendingSiteQueries = pendingSiteQueries?.length > 0;
        const donePolling = !hasPendingSites && hasPendingSiteQueries;

        // Track sites that are pending, once they become active,
        // invalidate the site info call(s) to keep cache in sync
        if (hasPendingSites) {
          pendingSites?.forEach((site: HostingSitesV2200ItemsItem) => {
            const siteQueryKey = getSitesInfoQueryKey(Number(site?.id));
            const queryKeys = hasPendingSiteQueries
              ? [pendingSiteQueries, siteQueryKey]
              : [siteQueryKey];
            setPendingSiteQueries(queryKeys);
          });
        }
        if (donePolling) {
          setPendingSiteQueries([]);
          await invalidateMultipleQueries(queryClient, pendingSiteQueries);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostingSites, isSuccess]);

  const sites = hostingSites?.data?.items;
  const pageCount = hostingSites?.data?.pages ?? 1;
  const pageLimit = hostingSites?.data?.limit ?? 5;

  const handleSearch = (value: string) => {
    setPage(1);
    setSearchQuery(value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<number>,
    value: number,
  ) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<typeof pageSize>) => {
    const value = event.target.value;
    setPageSize(Number(value));
    setPage(1);
  };

  return (
    <Card>
      <CardHeader
        title={t('cloud.header')}
        action={
          <AddSiteButton isPending={isPending}>
            {addSiteBtnTranslation}
          </AddSiteButton>
        }
        direction={{ xs: 'column', sm: 'row' }}
      />
      <CardContent>
        <Stack spacing={2}>
          <SearchBar
            searchQuery={searchQuery}
            placeholder={t('searchSites')}
            handleSearch={handleSearch}
            inputProps={{ sx: { width: { xs: '100%', sm: 400 } } }}
          />

          {isPending && <SitesLoading />}

          {/* 0 sites */}
          {!isPending && !sites && <SitesEmpty />}

          {/* 1+ sites */}
          {!isPending &&
            sites &&
            sites.map(
              (site: HostingSitesV2200ItemsItem | SiteInfoResponseModelV2) => (
                <SiteCard site={site} key={site.id} />
              ),
            )}
        </Stack>
      </CardContent>
      <CardContent>
        <Pagination
          page={page}
          pageCount={pageCount}
          pageSize={pageSize}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalRows={hostingSites?.data?.total}
        />
      </CardContent>
    </Card>
  );
};

export default Sites;

export type chartData = {
  label: string;
  legendLabel: string;
  value: string | number;
};

/* convertDiskStorage function takes in value as GB (Ex:1000GB) and convert it to its appropriate (Ex: 1TB) TB/PB/EB/ZB*/

function convertDiskStorage(value: number) {
  try {
    if (typeof value !== 'number') {
      return 0;
    }
    const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];
    let updatedValue = value;
    let unitIndex = 0;
    if (value < 1) {
      const mbValue = value * 1000;
      updatedValue = mbValue;
    } else {
      unitIndex = 1;
      while (updatedValue >= 1000 && unitIndex < sizes.length - 1) {
        updatedValue /= 1000;
        unitIndex++;
      }
    }
    return updatedValue.toFixed(2) + ' ' + sizes[unitIndex];
  } catch (error) {
    console.error('Failed to calculate storage', error);
  }

  return null;
}

export function convertStorageValuesToNumbers(value: string) {
  const storageValue = value.slice(0, -2);
  return Number(storageValue);
}

function getTotalValue(totalValue: string, type: string) {
  let computedTotalValue = totalValue === 'Unlimited' ? 0 : totalValue;
  if (computedTotalValue !== 0) {
    computedTotalValue =
      type === 'storage'
        ? convertStorageValuesToNumbers(computedTotalValue as string)
        : (computedTotalValue as number);
  }
  return computedTotalValue;
}

export function getValues(data: chartData[], type: string) {
  const usedValue = data.filter((item) => item.label === 'Used')[0].value;
  const totalValue = data.filter((item) => item.label === 'Total')[0].value;
  return {
    usedValue:
      type === 'storage'
        ? convertStorageValuesToNumbers(usedValue as string)
        : (usedValue as number),
    totalValue: getTotalValue(totalValue as string, type),
    customLegendUsedValue: getUsedAndTotalLegendValues(
      usedValue as number,
      type,
    ),
    customLegendTotalValue:
      totalValue === 'Unlimited'
        ? totalValue
        : getUsedAndTotalLegendValues(totalValue as number, type),
  };
}

const getUsedAndTotalLegendValues = (value: string | number, type: string) => {
  return type === 'storage'
    ? convertDiskStorage(convertStorageValuesToNumbers(value as string))
    : value;
};

export function getChartData(data: chartData[], type: string) {
  const { usedValue, totalValue } = getValues(data, type);
  const computedUsedSpace = totalValue === 0 ? totalValue : usedValue;
  // Assigning  0.1 to Total to show empty pie for Unlimited case
  const computedAvailableSpace =
    totalValue === 0 ? 0.1 : (totalValue as number) - (usedValue as number);

  return data.map((item) => ({
    id: item.label,
    label: item.legendLabel,
    value: item.label === 'Used' ? computedUsedSpace : computedAvailableSpace,
  }));
}

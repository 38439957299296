import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getHostingSitesV2QueryKey, useSite } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';

export type DeleteSiteModalProps = {
  open: boolean;
  setOpen: Function;
  siteUrl: string;
  siteId: number;
  siteName: string | undefined | null;
  parentSiteId?: number;
  isStagingSite?: boolean;
};

const DeleteSiteModal = ({
  setOpen,
  siteUrl,
  siteId,
  parentSiteId = undefined,
  siteName,
  isStagingSite = false,
}: DeleteSiteModalProps) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'settings.deleteSite.deleteModal',
  });
  const navigate = useNavigate();
  const generateAlert = useAlert();
  const { id: hostingId } = useAccount();

  const { cloudStagingDeleteKb } = useBrandInfo();

  const [isDeleting, setIsDeleting] = useState(false);

  const queryClient = useQueryClient();
  const hostingSitesQueryKey = getHostingSitesV2QueryKey(
    hostingId,
    isStagingSite
      ? {
          parent_site_id: parentSiteId ? Number(parentSiteId) : undefined,
        }
      : undefined,
  );

  const { mutate: deleteSite, isPending } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'info',
          description: t('alertMessage.success', {
            siteName: siteName,
          }),
          showCloseBtn: true,
        });

        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: hostingSitesQueryKey });
          //navigate to hosting list if not a staging site.
          !!parentSiteId
            ? navigate(`/sites/${parentSiteId}/staging`)
            : navigate('/');

          setIsDeleting(false);
          setOpen(false);
        }, 10000);
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('alertMessage.error'),
          showCloseBtn: true,
        });
        setOpen(false);
      },
    },
  });

  const [isDeleteVerifiedByUserInput, setIsDeleteVerifiedByUserInput] =
    useState(false);

  const handleChange = (event: any) => {
    const deleteVal = t('deleteBtn');
    setIsDeleteVerifiedByUserInput(
      deleteVal.toUpperCase() === siteUrl.toUpperCase()
        ? event.target.value === siteUrl
        : siteUrl.toLowerCase() === event.target.value.toLowerCase(),
    );
  };

  const handleCancel = (event: any) => {
    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    deleteSite({ siteId, data: {} });
    setIsDeleting(true);
  };

  return (
    <>
      <DialogTitle>
        {!!parentSiteId ? t('staging.title') : t('title')}
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Stack direction="row" spacing={2} py={3}>
          <WarningIcon color="error" />
          <Typography component="div" fontWeight={'bold'}>
            {!!parentSiteId ? t('staging.desc') : t('desc.0')}
          </Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            {!!parentSiteId ? (
              <>
                <Typography component="div" pb={1}>
                  {t('staging.para1')}
                </Typography>
                <Typography component="div" variant="body1" pb={3}>
                  <Trans
                    t={t}
                    i18nKey="staging.para2"
                    components={{
                      Link: (
                        <Link href={cloudStagingDeleteKb} target="_blank" />
                      ),
                    }}
                  />
                </Typography>
              </>
            ) : (
              <>
                <Typography component="div" pb={1}>
                  {t('desc.1')}
                </Typography>
                <Typography component="div" pb={3}>
                  {t('desc.2')}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Grid container my={3}>
          <Grid item xs={12}>
            <Typography component="div" variant="body1" pb={4}>
              <Trans
                t={t}
                i18nKey="confirmation"
                values={{ siteUrl }}
                components={{
                  SiteUrl: <Typography color="error" />,
                }}
              />
            </Typography>
            <TextField
              data-testid="confirmURL"
              id="outlined-basic"
              label={t('enterSiteUrl')}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel} disabled={isPending}>
          {t('cancelBtn')}
        </Button>
        <LoadingButtonWrapper
          data-testid="confirmDeleteBtn"
          variant="contained"
          color="error"
          disabled={!isDeleteVerifiedByUserInput}
          loading={isPending || isDeleting}
          onClick={handleSubmit}
        >
          {t('deleteBtn')}
        </LoadingButtonWrapper>
      </DialogActions>
    </>
  );
};

export default DeleteSiteModal;

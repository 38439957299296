import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingAddons } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';

import BuilderSSO from './BuilderSSO';

const BuilderSiteLogin = () => {
  const { t } = useTranslation('hosting', { keyPrefix: 'builder' });
  const { id: hostingId } = useAccount();

  const { data, isPending, isError } = useHostingAddons(hostingId);
  const hostingAddons = data?.data?.rows;

  const weebly = useMemo(
    () =>
      hostingAddons?.filter(
        (addon) => addon?.type === 'weebly' && addon?.status === 'active',
      ),
    [hostingAddons],
  );

  const webzai = useMemo(
    () =>
      hostingAddons?.filter(
        (addon) => addon?.type === 'webzai' && addon?.status === 'active',
      ),
    [hostingAddons],
  );

  if (isPending || isError || (isEmpty(weebly) && isEmpty(webzai))) return null;

  return (
    <Card data-testid="builder-sso-card">
      <CardHeader title={t('cardTitle')} />
      <CardContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ sm: 'center' }}
          spacing={2}
        >
          <Typography>{t('cardDesc')}</Typography>
          <Stack direction="row" spacing={2}>
            {weebly?.length! > 0 && (
              <BuilderSSO type="weebly" list={weebly!} id={weebly?.[0]?.id!} />
            )}
            {webzai?.length! > 0 && (
              <BuilderSSO type="webzai" list={webzai!} id={webzai?.[0]?.id!} />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BuilderSiteLogin;

import { Trans, useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useBrandInfo } from '~/hooks/useBrandInfo';

export default function UnexpectedError() {
  const { t } = useTranslation('tenant');
  const { phoneNumberFormatted, phoneNumberHref } = useBrandInfo();

  return (
    <Card>
      <CardHeader title={t('error.generic.title')} />
      <CardContent>
        <Stack spacing={2}>
          <Typography>{t('error.generic.description')}</Typography>
          <Typography>
            <Trans
              i18nKey="tenant:error.generic.supportTxt"
              values={{ phoneNumber: phoneNumberFormatted }}
              components={{ Link: <Link href={phoneNumberHref}></Link> }}
            />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import SsoLink from '~/components/SsoLink';
import useResponsive from '~/hooks/useResponsive';
import { useSiteContext } from '~/scenes/Site';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';

import { domainSetupCardSx } from '../utils/stepData';

type EditSiteSSOBannerPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
};

const EditSiteSSOBanner = ({ site }: EditSiteSSOBannerPropOptions) => {
  const { t } = useTranslation('domains', {
    keyPrefix: 'setupProgress.siteSiteSSOBanner',
  });
  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();

  const {
    // @ts-ignore
    isDisabled: isSiteDisabled,
    // @ts-ignore
    isHostingDisabled,
  } = useSiteContext();
  const isDisabled: boolean = isSiteDisabled || isHostingDisabled || false;

  return (
    <Box
      data-testid={'edit-site-sso-banner'}
      padding={2}
      sx={{
        ...domainSetupCardSx,
        backgroundColor: 'primary.light',
      }}
    >
      <Stack
        spacing={3}
        direction={isTablet ? 'column' : 'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        textAlign={'center'}
      >
        <Typography variant={'h3'} color={'primary.dark'}>
          {t('description')}
        </Typography>
        <SsoLink
          variant={'contained'}
          disabled={isDisabled}
          fluid={isTablet ? true : false}
          id={site?.id}
        >
          <WordPressIcon color={'inherit'} sx={{ mr: 1 }} />
          {t('ssoButton')}
        </SsoLink>
      </Stack>
    </Box>
  );
};

export default EditSiteSSOBanner;

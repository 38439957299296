import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '~/components/MFEProvider';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';

import AdvancedHostingServerStatusModal from '../Server/ServerStatusModal';
import AdvancedHostingResetPasswordModal from './ResetPasswordModal';

interface Props {
  serverData: any;
}

const AdvancedHostingResetPassword: React.FC<Props> = ({ serverData }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'resetPasswordCard',
  });

  /* @ts-ignore */
  const { brand } = useMFEContext();

  const [showResetPassword, account] = useFeature('reset_password');

  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);
  const [openServerStatusSuccessModal, setOpenServerStatusSuccessModal] =
    useState(false);
  const [showResetPasswordSuccessTxt, setShowResetPasswordSuccessTxt] =
    useState(false);

  const serverStatus = serverData?.data?.status;

  //ctb upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const getArticle = (accountType: string) => {
    return t('resetPassword.article.' + accountType);
  };

  const showPasswordResetModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenPasswordResetModal(true);
  };

  const showResetPasswordSuccessModal = () => {
    setShowResetPasswordSuccessTxt(true);
    setOpenServerStatusSuccessModal(true);
  };

  const handleSuccessfullModalClose = () => {
    setOpenServerStatusSuccessModal(false);
    setShowResetPasswordSuccessTxt(false);
  };

  // Shared - Sites - true, server-controls - false, reset-password - true if reseller.
  // VPS/Dedi - Sites - false, server-controls - true, reset-password - true
  if (!showResetPassword) {
    return null;
  }

  const ACCOUNT_TYPES = [
    'dedicated_windows',
    'dedicated_plesk',
    'shared_plesk',
    'vps_plesk',
    'vps_windows',
  ];

  if (
    ACCOUNT_TYPES.includes(account.accountType) &&
    brand?.toLowerCase() === 'hostgator'
  ) {
    return (
      <Link
        href={getArticle(account.accountType)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="body2">{t('resetPassword.learnMore')}</Typography>
      </Link>
    );
  }

  return (
    <>
      <Typography variant="body2" textAlign={'left'}>
        ***********
        <Link
          component="button"
          onClick={showPasswordResetModal}
          disabled={hasPurchasedRecently || serverStatus !== 'active'}
        >
          {t('advHostingReset')}
        </Link>
      </Typography>

      <AdvancedHostingResetPasswordModal
        openPasswordResetModal={openPasswordResetModal}
        setOpenPasswordResetModal={setOpenPasswordResetModal}
        showResetPasswordSuccessModal={showResetPasswordSuccessModal}
      />

      <AdvancedHostingServerStatusModal
        openServerStatusSuccessModal={openServerStatusSuccessModal}
        handleSuccessfullModalClose={handleSuccessfullModalClose}
        showResetPasswordSuccessTxt={showResetPasswordSuccessTxt}
      />
    </>
  );
};

export default AdvancedHostingResetPassword;

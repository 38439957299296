import { ReactElement, useState } from 'react';

import InfoRounded from '@mui/icons-material/InfoRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type CommonTooltipPropOptions = {
  desc?: string | ReactElement;
  testId?: string;
};

const CommonTooltip = ({
  desc = '',
  testId = 'tooltip-btn',
}: CommonTooltipPropOptions) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          open={open}
          title={desc}
          placement="top-start"
          disableHoverListener
          onClose={handleTooltipClose}
          PopperProps={{
            disablePortal: true,
          }}
          data-testid={testId}
        >
          <IconButton onClick={handleTooltipOpen} sx={{ p: 0 }}>
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CommonTooltip;

import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import Dialog from '@mui/material/Dialog';

import { useSitesInfo } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
  SitesInfo200,
  StagingSitePromote200,
  StagingSitePromote409,
  StagingSitePromoteBody,
} from '@newfold/huapi-js/src/index.schemas';

import PromoteModal from './components/PromoteModal';
import PromoteModalLoading from './components/PromoteModalLoading';
import ResourceLimitErrorModal from './components/ResourceLimitErrorModal';

export type PromoteSitePropsOptions = {
  open: boolean;
  handleClose: Function;
  stagingSite:
    | HostingSitesV2200ItemsItem
    | SiteInfoResponseModelV2
    | SitesInfo200;

  sitePromoteResp: UseMutationResult<
    AxiosResponse<StagingSitePromote200, any>,
    AxiosError<StagingSitePromote409, any>,
    {
      siteId: number;
      data: StagingSitePromoteBody;
    },
    unknown
  >;
};

const PromoteSite = ({
  open,
  handleClose,
  stagingSite,
  sitePromoteResp,
}: PromoteSitePropsOptions) => {
  const { data } = useSitesInfo(Number(stagingSite?.staging?.parent_site_id));
  const siteInfo = data?.data;

  const stagingSiteId = stagingSite?.id;

  const { isPending, error } = sitePromoteResp;

  const hasError = error?.response?.data?.error === 'resourceUsageExceeded';

  const limits = error?.response?.data?.details?.limits;

  if (hasError && limits) {
    return (
      <Dialog
        open={open}
        aria-labelledby="resource-limit-modal-title"
        aria-describedby="promote-limit-modal-description"
        maxWidth={'md'}
        fullWidth
      >
        <ResourceLimitErrorModal
          open={open}
          handleClose={handleClose}
          siteId={stagingSiteId!}
          parentSiteId={stagingSite?.staging?.parent_site_id!}
          limits={limits}
        />
      </Dialog>
    );
  }
  return (
    <>
      {isPending ? (
        <Dialog
          open={open}
          aria-labelledby="promote-loading-modal-title"
          aria-describedby="promote-loading-modal-description"
          maxWidth={'sm'}
          fullWidth
        >
          <PromoteModalLoading />
        </Dialog>
      ) : (
        <Dialog
          open={open}
          aria-labelledby="promote-modal-title"
          aria-describedby="promote-modal-description"
          maxWidth={'sm'}
          fullWidth
        >
          <PromoteModal
            open={open}
            handleClose={handleClose}
            siteId={stagingSite?.id as number}
            domain={stagingSite?.domain!}
            parentDomain={siteInfo?.domain!}
            siteName={stagingSite?.name}
            parentSiteId={stagingSite?.staging?.parent_site_id!}
          />
        </Dialog>
      )}
    </>
  );
};
export default PromoteSite;

import { NavigateFunction } from 'react-router-dom';

export enum HostingStatus {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  EXPIRED = 'Expired',
  PROVISIONING = 'Provisioning',
}

declare global {
  interface Window {
    Hosting: {
      init: any;
      /**
       * Example: window.Hosting.router.navigate(window.Hosting.routes.hosting)
       *
       * routes:
       **  hosting = '/'
       **  sites = '/sites'
       **  email = '/email'
       */
      router: {
        navigate: NavigateFunction;
      };
      /**
       * Example: window.Hosting.routes.hosting
       */
      routes: {
        hosting: '/';
        sites: '/sites';
        email: '/email';
      };
    };
  }
}

import { useMemo } from 'react';

import { LoadingButtonProps } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { LinkProps } from '@mui/material/Link';
import createPalette, {
  PaletteOptions,
} from '@mui/material/styles/createPalette';
import createTheme from '@mui/material/styles/createTheme';
import { deepmerge } from '@mui/utils';

import { Brand, getTokensByBrand } from '@newfold/tokens';

import { useBrandInfo } from '~/hooks/useBrandInfo';

import getAdditionalStylesByBrand from './getAdditionalStylesByBrand';
import LinkBehavior from './LinkBehavior';

const getBackgroundColor = () =>
  window
    .getComputedStyle(document.body, null)
    .getPropertyValue('background-color') === 'rgba(0, 0, 0, 0)'
    ? '#fff'
    : window
        .getComputedStyle(document.body, null)
        .getPropertyValue('background-color');

export type PaletteMode = 'light' | 'dark';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    selected: true;
    filters: true;
  }
}

// generate the pallet object for the theme using the css variables, and the override mappings
const generatePalette = (mode: PaletteMode, brand: Brand) => {
  const { color } = getTokensByBrand(brand);

  const palette: PaletteOptions = {
    ...(mode === 'light'
      ? {
          text: {
            disabled: color.spectrum.neutral[400],
            secondary: color.spectrum.neutral[500], // TODO: should this be secondary 600ish?
          },
          action: {
            hoverOpacity: 0,
            disabled: color.spectrum.neutral[400],
          },
          common: {
            white: color.semantic.neutral[0],
            black: color.semantic.neutral[1000],
          },

          primary: {
            ...color.semantic.primary,
            A100: color.semantic.primary[200],
            A200: color.semantic.primary[400],
            A400: color.semantic.primary[600],
            A700: color.semantic.primary[900],

            main: color.semantic.primary.main,
            light: color.semantic.primary.light,
            dark: color.semantic.primary.dark,
          },
          secondary: {
            ...color.semantic.secondary,
            A100: color.semantic.secondary[200],
            A200: color.semantic.secondary[400],
            A400: color.semantic.secondary[600],
            A700: color.semantic.secondary[900],

            main: color.semantic.secondary.main,
            light: color.semantic.secondary.light,
            dark: color.semantic.secondary.dark,
          },
          info: {
            ...color.semantic.info,
            A100: color.semantic.info[200],
            A200: color.semantic.info[400],
            A400: color.semantic.info[600],
            A700: color.semantic.info[900],

            main: color.semantic.info.main,
            light: color.semantic.info.light,
            dark: color.semantic.info.dark,
          },
          success: {
            ...color.semantic.success,
            A100: color.semantic.success[200],
            A200: color.semantic.success[400],
            A400: color.semantic.success[600],
            A700: color.semantic.success[900],

            main: color.semantic.success.main,
            light: color.semantic.success.light,
            dark: color.semantic.success.dark,
          },
          error: {
            ...color.semantic.error,
            A100: color.semantic.error[200],
            A200: color.semantic.error[400],
            A400: color.semantic.error[600],
            A700: color.semantic.error[900],

            main: color.semantic.error.main,
            light: color.semantic.error.light,
            dark: color.semantic.error.dark,
          },
          warning: {
            ...color.semantic.warning,
            A100: color.semantic.warning[200],
            A200: color.semantic.warning[400],
            A400: color.semantic.warning[600],
            A700: color.semantic.warning[900],

            main: color.semantic.warning.main,
            light: color.semantic.warning.light,
            dark: color.semantic.warning.dark,
          },
          grey: {
            ...color.spectrum.neutral,
            A100: color.spectrum.neutral[200],
            A200: color.spectrum.neutral[400],
            A400: color.spectrum.neutral[600],
            A700: color.spectrum.neutral[900],
          },
        }
      : // DARK MODE
        {
          ...createPalette({ mode }),
          background: {
            default: color.semantic.secondary[900],
            paper: color.semantic.secondary[900],
          },
          divider: color.semantic.secondary[300],
          text: {
            primary: color.semantic.neutral[0],
            secondary: color.semantic.secondary[100],
          },
          // action: {
          //   hoverOpacity: 0,
          //   disabled: grey[400],
          // },
          common: {
            white: color.semantic.neutral[0],
            black: color.semantic.neutral[1000],
          },
          primary: {
            ...color.semantic.primary,
            // light: color.semantic.primary[100],
            // main: color.semantic.primary[300],
            // dark: color.semantic.primary[500],
          },
          secondary: {
            ...color.semantic.secondary,
            // light: color.semantic.secondary[100],
            // main: color.semantic.secondary[300],
            // dark: color.semantic.secondary[500],
          },
          info: {
            ...color.semantic.info,
          },
          success: {
            ...color.semantic.success,
          },
          error: {
            ...color.semantic.error,
          },
          warning: {
            ...color.semantic.warning,
          },
          grey: {
            ...color.spectrum.neutral,
            A100: color.spectrum.neutral[200],
            A200: color.spectrum.neutral[400],
            A400: color.spectrum.neutral[600],
            A700: color.spectrum.neutral[900],
          },
        }),
  };

  return palette;
};

const ThemeByMode = (mode: PaletteMode = 'light', brand: Brand) => {
  const { color } = getTokensByBrand(brand);

  const additionalStyles = useMemo(() => {
    return getAdditionalStylesByBrand(brand, color);
  }, [brand, color]);

  return createTheme(
    deepmerge(
      {
        palette: generatePalette(mode, brand),
        typography: {
          fontFamily: ['"Open Sans"', 'Helvetica', 'sans-serif'].join(','),
          h1: {
            fontSize: 28,
            fontWeight: 400,
            lineHeight: '36px',
            letterSpacing: -0.8,
          },
          h2: {
            fontSize: 20,
            lineHeight: '27px',
            fontWeight: 600,
            letterSpacing: 0.15,
          },
          h3: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 600,
          },
          h4: {
            fontSize: 14,
            fontWeight: 600,
          },
          h5: {
            fontSize: 12,
          },
          h6: {
            fontSize: 10,
            fontWeight: 600,
          },
          body1: {
            fontSize: 16,
          },
          body2: {
            fontSize: 14,
          },
          button: {
            fontSize: 14,
            lineHeight: '15px',
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: 0.75,
          },
          caption: {
            fontSize: 11,
            fontWeight: 600,
            lineHeight: '15px',
            letterSpacing: '0.12em',
          },
        },
        components: {
          MuiSkeleton: {
            defaultProps: {
              variant: 'rounded',
              animation: 'wave',
            },
          },
          MuiContainer: {
            defaultProps: {
              maxWidth: false,
            },
          },
          MuiCircularProgress: {
            styleOverrides: {
              colorPrimary: ({ theme }) => ({
                color: theme.palette.primary.dark,
              }),
            },
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
            styleOverrides: {
              root: {
                minHeight: 0,
              },
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                minWidth: 36,
              },
            },
          },
          MuiListItemAvatar: {
            styleOverrides: {
              root: {
                minWidth: 0,
              },
            },
          },
          MuiAvatar: {
            styleOverrides: {
              root: {
                width: '1em',
                height: '1em',
                overflow: 'visible',
                backgroundColor: 'transparent',
                marginRight: 10,
              },
            },
          },
          MuiLoadingButton: {
            defaultProps: {
              loadingIndicator: <CircularProgress color="inherit" size={20} />,
            } as LoadingButtonProps,
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: 0,
              },
            },
            variants: [
              {
                props: {
                  color: 'primary',
                },
                style: ({ theme }) => ({
                  color: theme.palette.primary.dark,
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  styletype: 'counter',
                },
                style: ({ theme }) => ({
                  fontSize: '0.875rem',
                  boxSizing: 'border-box',
                  lineHeight: 1.5,
                  border: '1px solid',
                  borderRadius: '999px',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transitionProperty: 'all',
                  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                  transitionDuration: '120ms',
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    color: theme.palette.primary.dark,
                  },
                }),
              },
            ],
          },
          MuiButton: {
            styleOverrides: {
              root: ({ theme }) => ({
                padding: theme.spacing(1.5, 2),
              }),
            },
            variants: [
              {
                props: { variant: 'text' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  padding: 0,
                  color: theme.palette.primary.dark,
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                }),
              },
              {
                props: { variant: 'text', color: 'error' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  padding: 0,
                  color: theme.palette.error.main,
                  '&:hover': {
                    color: theme.palette.error.dark,
                  },
                }),
              },
              {
                props: { variant: 'contained', color: 'primary' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  background: theme.palette.primary.dark,
                  '&:hover': {
                    background: theme.palette.primary.main,
                  },
                }),
              },
              {
                props: { variant: 'contained', color: 'error' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  background: theme.palette.error.main,
                  '&:hover': {
                    background: theme.palette.error.dark,
                  },
                }),
              },
              {
                props: { variant: 'outlined', color: 'primary' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  borderColor: theme.palette.primary.dark,
                  color: theme.palette.primary.dark,
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                  },
                }),
              },
              {
                props: { variant: 'outlined', color: 'error' },
                style: ({ theme }) => ({
                  fontWeight: 700,
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main,
                  '&:hover': {
                    borderColor: theme.palette.error.dark,
                    color: theme.palette.error.dark,
                  },
                }),
              },
            ],
          },
          MuiSwitch: {
            styleOverrides: {
              colorPrimary: ({ theme }) => ({
                '&.Mui-checked': {
                  // Controls checked color for the thumb
                  color: theme.palette.primary.dark,
                },
              }),
              track: ({ theme }) => ({
                '.Mui-checked.Mui-checked + &': {
                  // Controls checked color for the track
                  backgroundColor: theme.palette.primary.dark,
                },
              }),
            },
          },
          MuiTabs: {
            styleOverrides: {
              indicator: ({ theme }) => ({
                backgroundColor: theme.palette.primary.dark,
              }),
            },
          },
          MuiTab: {
            styleOverrides: {
              root: ({ theme }) => ({
                '&.Mui-selected': {
                  color: theme.palette.primary.dark,
                },
                ':hover': {
                  color: theme.palette.primary.main,
                },
              }),
            },
          },
          MuiDrawer: {
            styleOverrides: {
              root: {
                '> .MuiPaper-root': {
                  height: `auto`,
                  overflow: 'visible',
                },
              },
            },
          },
          MuiRadio: {
            styleOverrides: {
              colorPrimary: ({ theme }) => ({
                '&.Mui-checked': {
                  color: theme.palette.primary.dark,
                },
              }),
            },
          },

          MuiOutlinedInput: {
            styleOverrides: {
              // @ts-expect-error
              colorPrimary: ({ theme }) => ({
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.dark,
                },
              }),
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: ({ theme }) => ({
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              }),
            },
          },
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
            styleOverrides: {
              root: ({ theme }) =>
                mode !== 'dark'
                  ? {
                      // mode light
                      fontFamily: 'inherit',
                      textDecoration: 'underline',
                      fontSize: 'inherit',
                      color: theme.palette.primary.dark,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: 'none',
                        color: theme.palette.primary.main,
                      },
                      '&[aria-disabled="true"]': {
                        color: theme.palette.text.disabled,
                        pointerEvents: 'none',
                      },
                      '&[disabled]': {
                        color: theme.palette.text.disabled,
                        pointerEvents: 'none',
                      },
                    }
                  : {
                      // mode dark
                      color: theme.palette.common.white,
                      '&:hover': {
                        boxShadow: 'none',
                        color: theme.palette.primary.light,
                      },
                      '&[aria-disabled="true"]': {
                        color: theme.palette.text.disabled,
                        pointerEvents: 'none',
                      },
                      '&[disabled]': {
                        color: theme.palette.text.disabled,
                        pointerEvents: 'none',
                      },
                    },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                'input[type=number]': {
                  MozAppearance: 'textfield',
                },
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                // TODO: set h2 defaults to variable and use the variable here, so we aren't duplicating code
                fontSize: 20,
                lineHeight: '27px',
                fontWeight: 600,
                letterSpacing: 0.15,
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: ({ theme }) => ({
                padding: theme.spacing(3),
              }),
            },
          },
          MuiPickersToolbar: {
            styleOverrides: {
              root: {
                '> .MuiTypography-overline': {
                  fontWeight: '400',
                  letterSpacing: '0.1em',
                },
              },
              content: {
                gridGap: '12px',
              },
            },
          },
          MuiPickersLayout: {
            styleOverrides: {
              contentWrapper: {
                gridColumn: 1,
                gridRow: 2,
              },
            },
          },
          MuiPickersToolbarText: {
            styleOverrides: {
              root: {
                fontWeight: 400,
                fontSize: '2rem',
                lineHeight: 1.235,
                '&.MuiTypography-subtitle1': {
                  fontSize: '1.25rem',
                  lineHeight: 1.75,
                },
                '&.MuiTypography-h3': {
                  fontSize: '2.5rem',
                  lineHeight: 1.167,
                },
              },
            },
          },
          MuiDateTimePickerToolbar: {
            styleOverrides: {
              separator: {
                fontSize: '2.5rem',
                lineHeight: 1.167,
                margin: '0 4px 0 2px',
              },
              timeContainer: {
                alignItems: 'center',
              },
            },
          },
          MuiMultiSectionDigitalClockSection: {
            styleOverrides: {
              root: {
                '&.MuiMultiSectionDigitalClockSection-root::after': {
                  height: 'auto',
                },
              },
            },
          },
          MuiAccordion: {
            defaultProps: {
              disableGutters: true,
            },
          },
          MuiAccordionSummary: {
            styleOverrides: {
              content: {
                margin: 0,
              },
            },
          },
          MuiCard: {
            defaultProps: {
              raised: false,
            },
            styleOverrides: {
              root: {
                padding: 0,
                height: '100%',
              },
            },
            variants: [
              {
                props: {
                  variant: 'selected',
                },
                style: ({ theme }) => ({
                  // @ts-ignore
                  border: `1px solid ${theme.palette.info.main}`,
                  // @ts-ignore
                  backgroundColor: theme.palette.info.light,
                }),
              },
            ],
          },
          MuiCardHeader: {
            defaultProps: {
              titleTypographyProps: {
                variant: 'h2',
              },
            },
            styleOverrides: {
              root: {
                paddingBottom: 0,
              },
            },
            variants: [
              {
                props: {
                  // @ts-expect-error
                  variant: 'cardlistheader',
                },
                style: ({ theme }) => ({
                  backgroundColor: theme.palette.secondary.light,
                  padding: theme.spacing(2),
                }),
              },
            ],
          },
          MuiCardContent: {
            styleOverrides: {
              root: ({ theme }) => ({
                padding: theme.spacing(3, 2),
              }),
            },
          },
          MuiAlert: {
            defaultProps: {
              icon: false,
              variant: 'outlined',
            },
            styleOverrides: {
              root: ({ ownerState, theme }) => {
                const severityMain = ownerState.severity
                  ? theme.palette[ownerState.severity].main
                  : theme.palette.primary.main;
                return {
                  alignItems: 'center',
                  '& button': {
                    color: 'inherit',
                  },
                  '& button:hover': {
                    // @ts-ignore
                    color: severityMain,
                    // @ts-ignore
                    borderColor: severityMain,
                  },
                };
              },
            },
            variants: [
              {
                props: {
                  severity: 'info',
                  variant: 'outlined',
                },
                style: ({ theme }) => ({
                  // if prop
                  border: `1px solid ${theme.palette.info.main}`,
                  backgroundColor: theme.palette.info.light,
                  color: theme.palette.info.dark,
                  // padding: theme.spacing(2, 3),
                  '& button': {
                    border: `1px solid ${theme.palette.info.dark}`,
                    padding: theme.spacing(2, 3),
                  },
                  '& .MuiAlert-icon': {
                    color: theme.palette.info.dark,
                  },

                  '& .MuiIconButton-root': {
                    border: 'none',
                  },
                }),
              },
              {
                props: { severity: 'success', variant: 'outlined' },
                style: ({ theme }) => ({
                  border: `1px solid ${theme.palette.success.main}`,
                  backgroundColor: theme.palette.success.light,
                  color: theme.palette.success.dark,
                  // padding: theme.spacing(2, 3),
                  '& button': {
                    border: `1px solid ${theme.palette.success.dark}`,
                    padding: theme.spacing(2, 3),
                  },
                  '& .MuiAlert-icon': {
                    color: theme.palette.success.dark,
                  },
                  '& .MuiIconButton-root': {
                    border: 'none',
                  },
                }),
              },
              {
                props: { severity: 'error', variant: 'outlined' },
                style: ({ theme }) => ({
                  border: `1px solid ${theme.palette.error.main}`,
                  backgroundColor: theme.palette.error.light,
                  color: theme.palette.error.dark,
                  // padding: theme.spacing(2, 3),
                  '& button': {
                    border: `1px solid ${theme.palette.error.dark}`,
                    padding: theme.spacing(2, 3),
                  },
                  '& .MuiAlert-icon': {
                    color: theme.palette.error.dark,
                  },
                  '& .MuiIconButton-root': {
                    border: 'none',
                  },
                }),
              },
              {
                props: { severity: 'warning', variant: 'outlined' },
                style: ({ theme }) => ({
                  border: `1px solid ${theme.palette.warning.main}`,
                  backgroundColor: theme.palette.warning.light,
                  // @ts-expect-error
                  color: theme.palette.grey.main,
                  padding: theme.spacing(2, 3),
                  '& button': {
                    border: `1px solid ${theme.palette.warning.dark}`,
                    padding: theme.spacing(2, 3),
                  },
                  '& .MuiAlert-icon': {
                    color: theme.palette.warning.dark,
                  },
                  '& .MuiIconButton-root': {
                    border: 'none',
                  },
                  '& .MuiButton-root:hover': {
                    color: theme.palette.warning.dark,
                  },
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  border: 'none',
                },
                style: () => ({
                  border: 0,
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  type: 'simple',
                },
                style: () => ({
                  border: 0,
                  padding: '0px 16px',
                  '& .MuiTypography-root': {
                    color: 'text.primary',
                    fontWeight: 600,
                  },
                }),
              },
            ],
          },
          MuiTypography: {
            variants: [
              {
                props: {
                  // @ts-expect-error
                  wordbreak: 'break-word',
                },
                style: () => ({
                  wordBreak: 'break-word',
                }),
              },
            ],
          },
          MuiAlertTitle: {
            styleOverrides: {
              root: {
                fontSize: 16,
                fontWeight: 600,
              },
            },
            variants: [
              {
                props: {
                  // @ts-expect-error
                  severity: 'info',
                },
                style: ({ theme }) => ({
                  color: theme.palette.info.dark,
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  severity: 'success',
                },
                style: ({ theme }) => ({
                  color: theme.palette.success.dark,
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  severity: 'error',
                },
                style: ({ theme }) => ({
                  color: theme.palette.error.dark,
                }),
              },
              {
                props: {
                  // @ts-expect-error
                  severity: 'warning',
                },
                style: ({ theme }) => ({
                  color: theme.palette.warning.dark,
                }),
              },
            ],
          },
          MuiTooltip: {
            defaultProps: {
              arrow: true,
            },
            styleOverrides: {
              tooltip: ({ theme }) =>
                theme.palette.mode === 'dark'
                  ? {
                      // no customization for dark mode
                    }
                  : {
                      // light mode:
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.common.white,
                      padding: theme.spacing(1, 1.2),
                      fontSize: 14,
                    },
              arrow: ({ theme }) =>
                theme.palette.mode === 'dark'
                  ? {
                      // no customization for dark mode
                    }
                  : {
                      // light mode
                      color: theme.palette.primary.dark,
                    },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: ({ theme }) => ({
                backgroundColor:
                  mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.grey[400],
              }),
            },
          },
          MuiAppBar: {
            defaultProps: {
              color: 'inherit',
            },
            styleOverrides: {
              root: () => ({
                backgroundColor: getBackgroundColor(),
              }),
            },
          },
          MuiPaper: {
            variants: [
              {
                props: { role: 'dialog' },
                style: {
                  borderRadius: 8,
                },
              },
              {
                props: { variant: 'filters' },
                style: ({ theme }) => ({
                  width: '100%',
                  border: `1px solid ${color.semantic.info[700]}`,
                  backgroundColor: color.semantic.info[50],
                  padding: theme.spacing(2, 3),
                }),
              },
            ],
          },
          MuiTableContainer: {
            styleOverrides: {
              root: () => ({
                borderRadius: '4px 4px 0 0',
              }),
            },
          },
          MuiTable: {
            styleOverrides: {
              root: ({ theme }) => ({
                borderWidth: 1,
                borderStyle: 'solid',
                // border: '1px solid',
                borderColor: theme.palette.divider,
              }),
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: ({ theme }) => ({
                backgroundColor: theme.palette.secondary.light,
                fontWeight: 'bold',
              }),
            },
          },
          MuiFab: {
            styleOverrides: {
              root: ({ theme }) => ({
                width: '3em',
                height: '2em',
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.primary.dark}`,
                color: theme.palette.primary.dark,
                borderRadius: 4,
              }),
            },
          },
        },
      },
      additionalStyles,
    ),
  );
};

export const getTheme = (mode: PaletteMode, brand: Brand) => {
  return ThemeByMode(mode, brand);
};

/**
 * custom hook to return mui theme object based on mode and brand
 */
export const useNewfoldBrandTheme = (mode: PaletteMode = 'light') => {
  const { brandFromJWT: brand } = useBrandInfo();
  return getTheme(mode, brand);
};

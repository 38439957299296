import { PropsWithChildren } from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled, Theme } from '@mui/material/styles';

import useResponsive from '~/hooks/useResponsive';

import LoadingButtonWrapper from '../LoadingButtonWrapper';

const buttonStyles = {
  flexBasis: '7.5rem',
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
};

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { flexGrow: 1 },
  ...buttonStyles,
}));

export const StyledLoadingButton = styled(LoadingButtonWrapper)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: { flexGrow: 1 },
    ...buttonStyles,
  }),
);

/**
 * Dialog Modal,
 * Bottom aligned for mobile views
 * Centered aligned for Desktop view
 */
const ResponsiveDialog = ({
  children = undefined,
  ...props
}: PropsWithChildren<DialogProps>) => {
  const { PaperProps, ...otherProps } = props;

  const { isDesktop } = useResponsive();

  return (
    <Dialog
      fullWidth={isDesktop()}
      PaperProps={{
        ...PaperProps,
        sx: (theme: Theme) => ({
          [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            ml: 2,
            mr: 2,
            mb: 1,
          },
        }),
      }}
      {...otherProps}
    >
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;

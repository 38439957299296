import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  useHostingAdvancedDomainServicesCheck,
  useHostingAdvancedServer,
} from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import useFeature from '~/hooks/useFeature';

import AdvancedHostingIpInfo from '../IPInfo';
import AdvancedHostingResetPassword from '../ResetPassword/ResetPassword';
import AdvancedHostingServerSetup from './ServerSetup';

const AdvancedHostingServerInfo = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });
  const { id: hostingId } = useAccount();

  const [isPlesk, plesk] = useFeature('plesk');
  const [isWindows, windows] = useFeature('windows');

  const [isPending, setIsPending] = useState<boolean>(false);

  const { data: serverData } = useHostingAdvancedServer(hostingId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!hostingId,
    },
  });

  const { data: domainServiceData } = useHostingAdvancedDomainServicesCheck(
    hostingId,
    {
      query: {
        enabled: !!hostingId,
      },
    },
  );
  const isDomainServiceConfigured =
    !domainServiceData?.data?.configure_domain_services;

  const serverStatus = serverData?.data?.status;
  useEffect(() => {
    const statuses = ['active', 'stopped', 'failed_start', 'decom'];
    if (serverStatus && statuses.includes(serverStatus)) {
      setIsPending(false);
    } else {
      setIsPending(true);
    }
  }, [serverStatus]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {t('advHostingServerInfo.serverInfoTitle')}
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.operatingSystem')}
                </Typography>
                {isPending ? (
                  <Skeleton width="90%" />
                ) : (
                  <Typography variant="body2" textAlign={'left'}>
                    {/* @ts-ignore*/}
                    {serverData?.data?.os_name ?? ''}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.ipAddress')}
                </Typography>
                <AdvancedHostingIpInfo
                  serverData={serverData}
                  isPending={isPending}
                />
              </Stack>
            </Grid>
            {(serverData?.data?.hostname || !isDomainServiceConfigured) && (
              <Grid item xs={12} sm={6} md={3}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" color="grey.500">
                    {t('advHostingServerInfo.hostName')}
                  </Typography>
                  {isPending ? (
                    <Skeleton width="90%" />
                  ) : (
                    <AdvancedHostingServerSetup
                      hostname={serverData?.data?.hostname}
                      isDomainServiceConfigured={isDomainServiceConfigured}
                    />
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.rootPassword')}
                </Typography>
                <AdvancedHostingResetPassword serverData={serverData} />
              </Stack>
            </Grid>
            {(isPlesk || isWindows) && (
              <Grid item xs={12} sm={6} md={3}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" color="grey.500">
                    {t('advHostingServerInfo.userName')}
                  </Typography>
                  <Typography variant="body2" textAlign={'left'}>
                    {plesk?.username || windows?.username}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default AdvancedHostingServerInfo;

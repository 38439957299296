import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {
  getDomainInfoQueryKey,
  getHostingSitesV2QueryKey,
  getSitesInfoQueryKey,
  useDomainInfo,
  useSitesDomainsV2,
  useSiteUpdate,
} from '@newfold/huapi-js';
import {
  DomainInfo200SetupProgress,
  HostingSitesV2200ItemsItem,
  SitesDomainsV2Params,
  SitesInfo200,
  SiteUpdateBody,
} from '@newfold/huapi-js/src/index.schemas';

import {
  invalidateMultipleQueries,
  queryClient,
} from '~/components/MFEProvider/lib/HttpClient/queryClient';
import { useTenant } from '~/components/TenantProvider';
import useAccount from '~/hooks/useAccount';
import { eventWatchRequestPropOptions } from '~/hooks/useAccountEvents';
import useOnboarding from '~/hooks/useOnboarding';
import { checkCreatingStatus } from '~/utils/siteMeta';

import { useSiteContext } from '../..';
import DomainSetupProgress from './components/DomainSetupProgress';
import EditSiteSSOBanner from './components/EditSiteSSOBanner';
import StepContent from './components/StepContent';
import UpdateURLComplete from './components/UpdateURLComplete';
import UpdateURLPrompt from './components/UpdateURLPrompt';
import {
  A_RECORD_FAIL_ERROR,
  DOMAIN_UNREGISTERED_ERROR,
  DomainSetupErrorTypes,
  DomainSetupStep,
  JARVIS_DOMAIN_PRODUCT_PREFIX,
  PENDING_URL_JOB_TIMEOUT,
  stepKeys,
  stepStatuses,
  WAITING_FOR_CDN_DNS,
  WAITING_FOR_CDN_VALIDATION,
} from './utils/stepData';
import {
  getIsActionableError,
  getIsStillInProgressError,
  translateStatus,
} from './utils/stepDataHelpers';

export type DomainSetupPropOptions = {
  site?: SitesInfo200 | HostingSitesV2200ItemsItem;
};

/*
 * Wrapper component that orchestrates which part(s) of the domain setup process to show
 */
const DomainSetup = ({ site = undefined }: DomainSetupPropOptions) => {
  const { t } = useTranslation('domains', { keyPrefix: 'setupProgress' });

  const [openChangeURLModal, setOpenChangeURLModal] = useState(false);

  const siteContext = useSiteContext();
  const { id: hostingId } = useAccount();
  const { callbacks } = useTenant();

  const isHostingContext: boolean =
    !!siteContext &&
    Object.keys(siteContext).length === 0 &&
    siteContext.constructor === Object;

  const { firstSite, numberOfSites } = useOnboarding(
    true, // When we eventually show the stepper in Site context, pass !site here
    // If hosting context is detected and a site wasn't passed in, have the onboarding hook fetch it
    isHostingContext && !site,
  );

  const siteInfo: SitesInfo200 | HostingSitesV2200ItemsItem | undefined =
    site ?? firstSite;
  const siteId: number = siteInfo?.id!;

  const hostingSitesQueryKey = getHostingSitesV2QueryKey(hostingId);
  const siteInfoQueryKey = getSitesInfoQueryKey(siteId);
  const queryKeys: any[] = [hostingSitesQueryKey, siteInfoQueryKey];
  const handleInvalidation = async () => {
    await invalidateMultipleQueries(queryClient, queryKeys);
  };

  const siteOnboardingStatus = siteInfo?.onboarding;
  const siteOnboardingCompleted = siteOnboardingStatus === 1;

  /*
    How ever we get the site, we need to check whether they have any domains besides the autogen:
    - If so, proceed with everything else.
    - If not, then they didn't purchase a domain at regflow and so we need to disable onboarding
        so that when they add a domain later, we don't show the stepper for external domains (yet).
  */
  const queryParams: SitesDomainsV2Params = {};
  const { data: sitesDomains, isSuccess: sitesDomainsSuccess } =
    useSitesDomainsV2(String(siteId), queryParams, {
      query: { enabled: !!siteId },
    });
  const hasDomains = !!sitesDomains && sitesDomains?.data?.total! > 1;

  const { mutate: updateSite } = useSiteUpdate({
    mutation: {
      // Don't show any error if this fails, should happen quietly
      onSuccess: async () => {
        handleInvalidation();
      },
    },
  });

  // If they only have a single domain (the autogen) or fewer, disable their site onboarding ability
  useEffect(() => {
    if (!siteOnboardingCompleted && !hasDomains && sitesDomainsSuccess) {
      updateSite({
        siteId,
        data: {
          onboarding: 1,
        } as SiteUpdateBody,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDomains]);

  const siteStatus: string | undefined = siteInfo?.status;
  const domainId: number | null | undefined = siteInfo?.pending_url_data
    ?.domain_id
    ? Number(siteInfo?.pending_url_data?.domain_id)
    : undefined;

  const { data: domainInfo, isLoading: domainLoading } = useDomainInfo(
    domainId!,
    {
      query: {
        enabled:
          !!siteInfo?.pending_url && !!domainId && !siteOnboardingCompleted,
        staleTime: 0,
        gcTime: 0,
      },
    },
  );
  const domain = domainInfo?.data;

  const domainIsInternal =
    !!domain &&
    domain?.is_internal === true &&
    domain?.product_id?.includes(JARVIS_DOMAIN_PRODUCT_PREFIX);

  const setupProgress: DomainInfo200SetupProgress | undefined =
    domain?.setup_progress;

  useEffect(() => {
    // if we only have one site, which hasn't completed onboarding, and there is at least one non-autogen domain that is internal
    if (
      numberOfSites === 1 &&
      !siteOnboardingCompleted &&
      !!domain?.id &&
      hasDomains &&
      domainIsInternal &&
      !domainLoading &&
      !!setupProgress
    ) {
      const domainInfoQueryKey = getDomainInfoQueryKey(domain?.id!);
      const eventQueryKeys = [...queryKeys, domainInfoQueryKey];

      const eventList = [];
      const checkCdn = setupProgress.validate_cdn === 0;
      const checkSsl = setupProgress.validate_ssl === 0;
      const checkDns = setupProgress.validate_dns === 0;
      // Subscribe to a given step event if 1) it or 2) any one of its predecessors is in progress
      if (checkDns || checkSsl || checkCdn) eventList.push('ok cdn');
      if (checkDns || checkSsl) eventList.push('ok ssl');
      if (checkDns) eventList.push('ok dns');

      callbacks.subscribeToEvent({
        events: [...eventList],
        resourceIds: {
          domain: [String(domain.id)],
          hosting: [String(domain.hosting_id)],
        },
        queryKeys: eventQueryKeys,
      } as eventWatchRequestPropOptions);
    }
    // We only care about re-subscribing to event polling if the domain or sites domains info updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, hasDomains]);

  if (
    !siteInfo ||
    siteOnboardingCompleted ||
    // Only check number of sites if onboarding gave us a site
    (!!firstSite && numberOfSites !== 1) ||
    // There is a domain id from site.meta, but no pending url on the site
    (!!domainId && !siteInfo?.pending_url) ||
    // We found the domain from the pending url, but it is not internal
    (!!domain && !domainIsInternal) ||
    (sitesDomainsSuccess && !sitesDomains) ||
    !hasDomains
  )
    return <></>;

  const pendingUrl = siteInfo?.pending_url;

  if (domainLoading)
    return <Skeleton variant="rounded" width={'100%'} height={250} />;

  const siteStepStatus =
    siteStatus === 'active'
      ? 1 // site is active
      : checkCreatingStatus(siteStatus)
      ? 0 // site is installing
      : -1; // site has errored

  /*
    The functionality does not yet exist to manually update the site url as its own step
    so for now as long as everything up to cdn has passed validation, just consider it done.
    TODO: Once it is available and we change this, be sure to test thoroughly.
  */
  const urlStepStatus =
    !siteInfo?.pending_url &&
    !!setupProgress?.validate_ssl &&
    [1, 2].includes(setupProgress?.validate_ssl) &&
    !!setupProgress?.validate_cdn &&
    [1, 2].includes(setupProgress?.validate_cdn)
      ? 1
      : null;

  // TODO: will need to check this for url update failure once it eventually gets added
  const pendingURLError: string | null =
    siteInfo?.pending_url_data?.error ?? null;

  const isUnregisteredError: boolean = [
    setupProgress?.validate_dns_error,
    setupProgress?.validate_ssl_error,
    setupProgress?.validate_cdn_error,
  ].includes(DOMAIN_UNREGISTERED_ERROR);

  const isTimeoutError: boolean =
    siteInfo?.pending_url_data?.job_failed ?? false;

  const aRecordFailRetrying =
    setupProgress?.validate_dns_error === A_RECORD_FAIL_ERROR;
  // && setupProgress?.validate_dns_auto_repair === 1;

  /*
    As the CDN check progresses, it can reset or remove the DNS valiation check. Since
    the CDN just changed the IP, it's expected to be propegating still. We need to look
    for this situation and consider the CDN step as the active one, not the DNS step.
  */
  const isCdnRecheckingDns =
    [null, 0].includes(setupProgress?.validate_dns!) &&
    [1, 2].includes(setupProgress?.validate_ssl!) &&
    setupProgress?.validate_cdn === 0 &&
    [WAITING_FOR_CDN_DNS, WAITING_FOR_CDN_VALIDATION].includes(
      setupProgress?.validate_cdn_error!,
    );

  const errors: DomainSetupErrorTypes = {
    site: pendingURLError,
    dns: setupProgress?.validate_dns_error ?? null,
    ssl: setupProgress?.validate_ssl_error ?? null,
    cdn: setupProgress?.validate_cdn_error ?? null,
    isUnregistered: isUnregisteredError,
    isTimeout: isTimeoutError,
    aRecordFailRetrying,
    isAny:
      (isUnregisteredError ||
        isTimeoutError ||
        !!pendingURLError ||
        (!!setupProgress?.validate_dns_error && !aRecordFailRetrying) ||
        !!setupProgress?.validate_ssl_error ||
        !!setupProgress?.validate_cdn_error) &&
      !getIsStillInProgressError(setupProgress), // Hide 'still in progress' messages for now
  };

  type RawStepStatus = {
    key: string;
    status: number | null;
    error?: string | null;
  };
  // Order of the steps is set here
  const rawStepStatuses: RawStepStatus[] = [
    { key: stepKeys.site, status: siteStepStatus, error: errors.site },
    {
      key: stepKeys.dns,
      // If the CDN has caused DNS to re-check, display DNS as having completed
      status: isCdnRecheckingDns ? 1 : setupProgress?.validate_dns ?? null,
      error: errors.dns,
    },
    {
      key: stepKeys.ssl,
      status: setupProgress?.validate_ssl ?? null,
      error: errors.ssl,
    },
    {
      key: stepKeys.cdn,
      status: setupProgress?.validate_cdn ?? null,
      error: errors.cdn,
    },
    { key: stepKeys.url, status: urlStepStatus },
  ];

  let foundActive: string | undefined;
  let prevRawStatus: RawStepStatus | undefined;
  let isActionableError: boolean = false;
  const steps: DomainSetupStep[] = [];

  /*
    - There will be periods during which a given step has completed but the
      next step has not yet gone active. In this case, the next null step after
      the latest completed step will be considered the active step.

    - It is possible that more than one step could be active (status = 0) at a time. In this
      case, we will consider the first active one in the list to be the active step.
  */
  rawStepStatuses.forEach((rawStatus) => {
    let status: number | null = null;

    // if we haven't seen an active status yet and we 1) find one, or 2) see a null immediately after a completed/skipped
    // then set this status to 0 (active), and track it
    if (
      !foundActive &&
      (rawStatus.status === 0 ||
        (rawStatus.status === null && [1, 2].includes(prevRawStatus?.status!)))
    ) {
      status = 0;
      foundActive = rawStatus.key;
    }
    // if we have seen an active status already and we see another active/completed/skipped status, set it to null (not started)
    else if (foundActive && [0, 1, 2].includes(rawStatus?.status!)) {
      status = null;
    } else {
      status = rawStatus.status;
    }

    /*
      A job timeout error should take precedence over an unregistered error because the domain could be registered
      in the time between a timeout first occurring and a new job running; both take precedence over other errors.
    */
    const stepErrorMessage = errors.isTimeout
      ? PENDING_URL_JOB_TIMEOUT
      : errors.isUnregistered
      ? DOMAIN_UNREGISTERED_ERROR
      : rawStatus.error ?? setupProgress?.validate_error;

    if (getIsActionableError(stepErrorMessage) && !aRecordFailRetrying)
      isActionableError = true;

    const translatedStatus = translateStatus(status);

    const step: DomainSetupStep = {
      key: rawStatus.key,
      name: t(`steps.${rawStatus.key}.name`),
      status:
        translatedStatus === stepStatuses.active && isActionableError
          ? stepStatuses.errored
          : translatedStatus,
      component: (
        <StepContent
          stepType={rawStatus.key}
          stepStatus={translatedStatus}
          stepError={stepErrorMessage}
          errors={errors}
          handleRetry={setOpenChangeURLModal}
        />
      ),
    };

    steps.push(step);

    prevRawStatus = rawStatus;
  });

  // Determine the 'active' step; NOTE: the active step could have been marked as errored by now
  const activeStepIndex = steps.findIndex((step) =>
    [stepStatuses.active, stepStatuses.errored].includes(step.status),
  );
  const lastStep = steps.length - 1;
  const isOnLastStep = activeStepIndex === lastStep;
  const urlStepCompleted = !!steps.find(
    (step) =>
      step.key === stepKeys.url && step.status === stepStatuses.completed,
  );

  // The isHostingContext check will go away when stepper moves to Site context
  if (isHostingContext && pendingUrl && !urlStepCompleted) {
    return (
      <Stack spacing={3}>
        {siteStepStatus > 0 && <EditSiteSSOBanner site={siteInfo} />}
        <DomainSetupProgress
          activeStepIndex={activeStepIndex}
          domain={domain!}
          isActionableError={isActionableError}
          isOnLastStep={isOnLastStep}
          isUnregisteredError={isUnregisteredError}
          openChangeURLModal={openChangeURLModal}
          setOpenChangeURLModal={setOpenChangeURLModal}
          site={siteInfo}
          steps={steps}
        />
      </Stack>
    );
  }

  if (!isHostingContext && pendingUrl && isOnLastStep) {
    return <UpdateURLPrompt site={siteInfo} />;
  }

  if (!pendingUrl && !setupProgress) {
    // NOTE: This won't yet work past the initial site, as there is no per-site onboarding value to confirm against
    return (
      <UpdateURLComplete
        site={siteInfo}
        handleInvalidation={handleInvalidation}
      />
    );
  }
};

export default DomainSetup;

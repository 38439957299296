import { useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesInfoQueryKey,
  useSitesDomainsV2,
  useSiteUpdate,
} from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SitesDomainsV2Params,
  SitesDomainsV2200DomainsItem,
  SitesInfo200,
  SiteUpdateBody,
} from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import { useBrandInfo } from '~/hooks/useBrandInfo';

import { checkDomainDocrootEqualsSiteDocpath } from '../utils/checkDomainDocrootEqualsSiteDocpath';

export type ChangeSiteURLModalPropOptions = {
  domain?: SitesDomainsV2200DomainsItem;
  isRetry?: boolean;
  open?: boolean;
  setOpen: Function;
  siteInfo?: SitesInfo200 | HostingSitesV2200ItemsItem;
};

const ChangeSiteURLModal = ({
  domain = undefined,
  isRetry = false,
  open = false,
  setOpen,
  siteInfo = undefined,
}: PropsWithChildren<ChangeSiteURLModalPropOptions>) => {
  const { t } = useTranslation('domains');
  const [, { generateAlert }] = useAlerts();
  const { siteId: paramsSiteId } = useParams() as { siteId: string };
  const siteIdNum: number = !!siteInfo?.id
    ? Number(siteInfo?.id)
    : Number(paramsSiteId);
  const siteIdStr: string = !!siteInfo?.id
    ? String(siteInfo?.id)
    : paramsSiteId;

  const queryClient = useQueryClient();
  const { phoneNumberFormatted } = useBrandInfo();

  const domainName = domain?.domain;
  const descriptionKey = isRetry ? 'retryDescription' : 'description';
  const titleKey = isRetry ? 'retryTitle' : 'title';
  const isWordPress =
    siteInfo?.type === 'wordpress' && siteInfo?.detected_type === 'wordpress';
  const siteTypeText = isWordPress ? 'WordPress ' : ''; // Yes the trailing space is intentional

  const sitePath = siteInfo?.path ?? undefined;
  const domainDocrootEqualsSiteDocpath = checkDomainDocrootEqualsSiteDocpath(
    domain,
    siteInfo,
  );
  const newPendingSiteUrl =
    sitePath && !domainDocrootEqualsSiteDocpath
      ? `${domainName}/${sitePath}`
      : domainName;

  const [isPollingDomains, setIsPollingDomains] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const sitesInfoQueryKey = getSitesInfoQueryKey(siteIdNum);

  const queryParams: SitesDomainsV2Params = {};
  const {
    data: siteDomainsInfo,
    refetch: refetchDomains,
    isSuccess: isSuccessSiteDomains,
  } = useSitesDomainsV2(siteIdStr, queryParams, {
    query: {
      enabled: !!siteIdStr && isPollingDomains,
      // refetch Domain list on update success until domain is found in the list and is active
      refetchInterval: (query) => {
        const queryStateData = query?.state?.data;
        const domains = queryStateData?.data?.domains;
        const domainStatus = domains?.find(
          (d) => d.domain === domainName,
        )?.status;
        const keepPolling = isPollingDomains && domainStatus !== 'active';
        return keepPolling ? 5000 : false;
      },
    },
  });

  // onSuccess - useSitesDomainsV2
  useEffect(() => {
    if (isSuccessSiteDomains && isPollingDomains) {
      const domains = siteDomainsInfo?.data?.domains;
      const domainStatus = domains?.find(
        (d) => d.domain === domainName,
      )?.status;
      const siteUrlUpdated = domainStatus === 'active';

      if (siteUrlUpdated) {
        generateAlert({
          description: (
            <Trans
              i18nKey="domains:changeSiteURLModal.successMessage"
              values={{
                url: newPendingSiteUrl,
                siteTypeText,
              }}
              components={{ b: <b /> }}
            />
          ),
          severity: 'success',
          showCloseBtn: true,
        });
        setIsPollingDomains(false);
        handleClose();
        queryClient.invalidateQueries({ queryKey: sitesInfoQueryKey });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSiteDomains, siteDomainsInfo, isPollingDomains]);

  const { mutate: updateSiteUrl, isPending } = useSiteUpdate({
    mutation: {
      onError: () => {
        generateAlert({
          description: (
            <Trans
              i18nKey="domains:changeSiteURLModal.errorMessage"
              values={{ phone: phoneNumberFormatted }}
              components={{ b: <b /> }}
            />
          ),
          severity: 'error',
          showCloseBtn: true,
        });
        handleClose();
      },
      onSuccess: () => {
        setIsPollingDomains(true);
        refetchDomains();
      },
    },
  });

  const handleConfirm = () => {
    let data: SiteUpdateBody = { domain: domainName, skip_fg_checks: true };
    if (sitePath && !domainDocrootEqualsSiteDocpath) {
      data = { ...data, path: sitePath };
    }
    updateSiteUrl({
      siteId: siteIdNum,
      data,
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="change-site-url-modal-title"
      aria-describedby="change-site-url-modal-description"
      data-testid="change-site-url-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>
            <Trans
              i18nKey={`domains:changeSiteURLModal.${titleKey}`}
              values={{ siteTypeText }}
            />
          </DialogTitle>
        </Stack>
        <Divider />
        <Typography variant="body1" py={3}>
          <Trans
            i18nKey={`domains:changeSiteURLModal.${descriptionKey}`}
            values={{
              url: newPendingSiteUrl,
              siteTypeText,
            }}
            components={{ b: <b /> }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={handleClose}
            data-testid="change-site-url-cancel-btn"
          >
            {t('changeSiteURLModal.cancelBtn')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={isPending || isPollingDomains}
            data-testid="change-site-url-confirm-btn"
          >
            {t('changeSiteURLModal.confirmBtn')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeSiteURLModal;

import orderSort from 'lodash/orderBy';
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';

import useResponsive from '~/hooks/useResponsive';

import { Pagination, PaginationPropOptions } from '../Pagination';
import VirtualizedTable from '../VirtualizedTable';
import VisionUICardList from './CardList';
import VisionUITable from './Table';

interface ResponsiveTableOptions {
  type?: string;
  list: {}[];
  headCells?: {
    id: string;
    label?: string;
    sort?: boolean;
    toolTip?: string;
  }[];
  isFetching?: boolean;
  isPending?: boolean;
  isError?: boolean;
  loader?: ReactNode;
  error?: ReactNode;
  empty?: ReactNode;
  showCardsUI?: boolean;
  mobile?: {
    ui?: ReactNode;
    loader?: ReactNode;
  };
  showPagination?: boolean;
  paginationProps?: PaginationPropOptions;
  handleSort?: Function | undefined;
  sortOrder?: string;
  showAllCTA?: ReactNode;
  children?: ReactNode;
  virtualizedTableOptions?: {
    rowContent: (index: number, item: any) => JSX.Element;
    itemHeight?: number;
  };
}

export default function ResponsiveTable({
  type = 'list',
  isFetching = false,
  isPending = false,
  isError = false,
  loader = undefined,
  error = undefined,
  empty = undefined,
  headCells = [],
  showCardsUI = false,
  mobile = {
    ui: undefined,
    loader: undefined,
  },
  handleSort = undefined,
  sortOrder = 'asc',
  showPagination = true,
  paginationProps = undefined,
  showAllCTA = undefined,
  children = null,
  virtualizedTableOptions = undefined,
  ...props
}: PropsWithChildren<ResponsiveTableOptions>) {
  const { isTablet } = useResponsive();
  const showCards = (showCardsUI || isTablet()) && mobile?.ui;

  const [order, setOrder] = useState(sortOrder);
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(property);
      if (!!handleSort) handleSort(newOrder);
    };

  const list = props?.list;
  const listSorted = useMemo(() => {
    // If handling sort already from the BE, just return the list
    if (!!handleSort) return list;
    // @ts-expect-error
    return orderSort(list, [(item) => item[orderBy]?.toLowerCase()], [order]);
  }, [handleSort, list, order, orderBy]);

  // override pagination page setter and value when parent uses search

  return (
    <>
      {showCards ? (
        <VisionUICardList
          {...props}
          type={type}
          isFetching={isFetching}
          isPending={isPending}
          isError={isError}
          // @ts-expect-error
          loader={mobile?.loader}
          // @ts-expect-error
          error={error}
          // @ts-expect-error
          empty={empty}
          // @ts-ignore
          list={listSorted}
        >
          {/* @ts-expect-error */}
          {mobile?.ui}
        </VisionUICardList>
      ) : virtualizedTableOptions ? (
        <VirtualizedTable
          {...props}
          type={type}
          isFetching={isFetching}
          isPending={isPending}
          isError={isError}
          loader={loader}
          error={error}
          empty={empty}
          headCells={headCells}
          list={listSorted}
          order={order === 'asc' ? 'asc' : 'desc'}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          virtualizedTableOptions={virtualizedTableOptions}
        >
          {children}
        </VirtualizedTable>
      ) : (
        <VisionUITable
          {...props}
          type={type}
          isFetching={isFetching}
          isPending={isPending}
          isError={isError}
          // @ts-expect-error
          loader={loader}
          // @ts-expect-error
          error={error}
          // @ts-expect-error
          empty={empty}
          headCells={headCells}
          // @ts-ignore
          list={listSorted}
          order={order}
          orderBy={orderBy}
          // @ts-expect-error
          handleRequestSort={handleRequestSort}
        >
          {/* @ts-expect-error */}
          {children}
        </VisionUITable>
      )}
      {showPagination && paginationProps && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems={{ xs: 'start', sm: 'center' }}
        >
          <Pagination {...paginationProps} />
        </Stack>
      )}
    </>
  );
}

import '@animxyz/core';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import OpenInNew from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  getHostingAdvancedDnsQueryKey,
  useHostingAdvancedDns,
  useHostingAdvancedDnsUpdate,
  useHostingAdvancedServer,
  useHostingAdvancedSsoWhm,
} from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import SsoLink, {
  SsoQueryParamsTypes,
  SsoTypeTypes,
} from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import useQueryFeature from '~/hooks/useQueryFeature';

import MultipleChoiceSsoDialog from '../../../QuickLinks/MultipleChoiceSsoDialog';
import useServerEmailChoices from '../../../QuickLinks/ServerEmailChoices';
import DNSZoneDialog from './DNSZoneDialog';

interface QuickLink {
  name: string;
  type: string;
  queryParams?: { [key: string]: string };
  canShow: boolean;
}

const AdvancedHostingQuickLinks = () => {
  const { t } = useTranslation('quickLinks');
  const { id: accountId } = useAccount();
  const [serverEmailIsOpen, setServerEmailIsOpen] = useState(false);
  const [isDNSDialogError, setIsDNSDialogError] = useState(false);
  const [isDNSZoneDialogOpen, setIsDNSZoneDialogOpen] =
    useState<boolean>(false);
  const { hostingAccount, isDisabled, isPending } = useHostingInfo();

  const [hasAdvancedHosting] = useFeature('advanced_hosting');
  const [hasCpanel, , isClientReady] = useFeature('cpanel');
  const [isPlesk] = useFeature('plesk');
  const [isWindows] = useFeature('windows');
  const [hasRootWhm] = useFeature('whm');
  const [checkDNSZone] = useQueryFeature('checkdnszone');

  const isReseller = hostingAccount?.data?.type === 'reseller';
  const isRootUser = hostingAccount?.data?.username === 'root';
  const queryClient = useQueryClient();

  const generateAlert = useAlert();
  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    },
  });
  const serverStatus = serverData?.data?.status;
  // call sso whm API
  const { data: ssoWhmData, refetch: fetchSSOWHMData } =
    useHostingAdvancedSsoWhm(accountId);
  const ssl_setup =
    ssoWhmData && ssoWhmData?.data! && ssoWhmData?.data!.ssl_setup;
  const isAttentionRequired = ssl_setup?.required === 1;
  const hostName = ssl_setup && ssl_setup.server_hostname;
  const serverIP = ssl_setup && ssl_setup.server_ip;
  const doaminName = ssl_setup && ssl_setup.domain;
  const dnsZoneWithUS = ssl_setup && ssl_setup.dns_zone_with_us;

  // CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const getQuickLinks = (): QuickLink[] => {
    const listButtonLinks: QuickLink[] = [
      {
        name: 'whm',
        type: 'vps',
        canShow: hasRootWhm,
        queryParams: {
          app: 'whm',
        },
      },
      {
        name: 'cpanel',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
      },
      {
        name: 'filemanager',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
        queryParams: {
          app: 'filemanager',
        },
      },
      {
        name: 'resellerwhm',
        type: 'reseller',
        canShow: isReseller,
        queryParams: {
          app: 'resellerwhm',
        },
      },
      {
        name: 'resellercpanel',
        type: 'reseller',
        canShow: hasCpanel && isReseller,
        queryParams: {
          app: 'resellercpanel',
        },
      },
      {
        name: 'adminpanel',
        type: 'plesk',
        canShow: isPlesk,
      },
    ];

    const listOfQuickLinks = listButtonLinks.filter((item) => item.canShow);
    return listOfQuickLinks;
  };

  const choices = useServerEmailChoices();

  const handleServerEmailClose = () => {
    setServerEmailIsOpen(false);
  };

  const handleServerEmailClick = () => {
    setServerEmailIsOpen(true);
  };
  const handleDNSZoneDialogClose = () => {
    setIsDNSZoneDialogOpen(false);
    setIsDNSDialogError(false);
  };

  const queryKey = getHostingAdvancedDnsQueryKey(accountId, {
    hostname: hostName!,
    domain: serverIP!,
  });

  const { refetch: fetchDNSExists, isLoading: isFetchingDNSExistsData } =
    useHostingAdvancedDns(
      accountId,
      {
        hostname: hostName!,
        domain: doaminName!,
        dns_with_us: dnsZoneWithUS!,
      },
      { query: { enabled: false } },
    );

  const { mutate: updateDNSZone, isPending: isUpdatingDNSZoneInprogress } =
    useHostingAdvancedDnsUpdate({
      mutation: {
        onSuccess: () => {
          handlePostConnectDNS();
        },
        onError: (error, data, context) => {
          queryClient.setQueryData(queryKey, context);
          generateAlert({
            severity: 'error',
            description: t('updateDNS.errorMessage'),
            showCloseBtn: true,
            persist: false,
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey });
        },
      },
    });

  const handlePostConnectDNS = async () => {
    handleDNSZoneDialogClose();
    generateAlert({
      severity: 'success',
      description: t('updateDNS.dnsExistsMessage'),
      showCloseBtn: true,
      persist: false,
    });
    await fetchSSOWHMData();
  };
  const handleDNSZoneDialogPrimaryAction = async (isDNSZoneWithUS: boolean) => {
    const { data: dnsExistsData, isError: isDNSExistsError } =
      await fetchDNSExists();
    if (isDNSExistsError) {
      setIsDNSZoneDialogOpen(false);
      generateAlert({
        severity: 'error',
        description: t('fetchDNSExists.errorMessage'),
        showCloseBtn: true,
        persist: false,
      });
      return;
    }
    const dnsData =
      dnsExistsData && dnsExistsData.data && dnsExistsData.data.dns;
    const isDNSExists =
      dnsData && dnsData.length > 0 && dnsData[0].content === serverIP;
    if (isDNSZoneWithUS) {
      if (isDNSExists) {
        handlePostConnectDNS();
      } else {
        //Update DNS path
        updateDNSZone({
          hostingId: accountId,
          data: {
            domain: doaminName!,
            hostname: hostName!,
            server_ip: serverIP!,
          },
        });
      }
    } else {
      // Install SSL path as the domain is not with us
      if (isDNSExists) {
        handlePostConnectDNS();
      } else {
        setIsDNSDialogError(true);
      }
    }
  };
  const handleConnectDNS = () => {
    setIsDNSZoneDialogOpen(true);
  };
  const quickLinks = getQuickLinks() as {
    name: string;
    type: SsoTypeTypes;
    canShow: boolean;
    queryParams: SsoQueryParamsTypes;
  }[];

  if (isClientReady && isWindows) {
    return null;
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          titleTypographyProps={{ variant: 'h3' }}
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{t('quickLinks.cardTitle')}</Typography>
              {isAttentionRequired && checkDNSZone && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <WarningIcon color="warning" />
                  <Typography variant="body2">
                    <Link onClick={handleConnectDNS}>{t('sslRequired')}</Link>
                  </Typography>
                  <Tooltip
                    arrow
                    placement="top-start"
                    title={t('sslRequiredTooltip')}
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <InfoIcon color="action" />
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          }
          subheader={t('advHostingQuicklinksDesc')}
          subheaderTypographyProps={{ variant: 'body2' }}
        />
        <CardContent>
          {(!isClientReady || isPending) && t('advHostingLoadingTxt')}
          {/* quick links success (show them) */}
          {isClientReady && quickLinks.length > 0 && (
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              alignItems={'stretch'}
            >
              {Array.isArray(quickLinks) &&
                quickLinks.map(({ name, type, queryParams }) => (
                  <SsoLink
                    key={`sso-link-${name}`}
                    fluid
                    type={type}
                    advHostingQuickLink={true}
                    {...(queryParams && { queryParams })}
                  >
                    {t(`quickLinks.links.${name}`)}
                  </SsoLink>
                ))}
              {hasCpanel &&
                !isReseller &&
                hasAdvancedHosting &&
                !isRootUser && (
                  <>
                    <MultipleChoiceSsoDialog
                      isOpen={serverEmailIsOpen}
                      onClose={handleServerEmailClose}
                      title={t('serverEmail.title')}
                      description={t('serverEmail.description')}
                      choices={choices}
                    />
                    <Button
                      variant="outlined"
                      onClick={handleServerEmailClick}
                      data-testid="email-sso-button"
                      disabled={
                        serverStatus !== 'active' ||
                        isDisabled ||
                        hasPurchasedRecently
                      }
                      endIcon={<OpenInNew />}
                    >
                      {t('serverEmail.buttonLabel')}
                    </Button>
                  </>
                )}
            </Stack>
          )}
          {isClientReady && quickLinks?.length <= 0 && (
            <Typography variant="h3" align="center">
              {t('quickLinks.noQuickLinks')}
            </Typography>
          )}
        </CardContent>
      </Card>
      <DNSZoneDialog
        open={isDNSZoneDialogOpen}
        onClose={handleDNSZoneDialogClose}
        onPrimaryAction={handleDNSZoneDialogPrimaryAction}
        SSLData={ssl_setup!}
        showError={isDNSDialogError}
        isPending={isUpdatingDNSZoneInprogress || isFetchingDNSExistsData}
      />
    </>
  );
};

export default AdvancedHostingQuickLinks;

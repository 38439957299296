import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSiteUpdate } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
  SiteUpdateBody,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useResponsive from '~/hooks/useResponsive';

import updateCompleted from '../assets/update-completed.svg';
import { domainSetupCardSx } from '../utils/stepData';

type FinalizeDomainPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  isPending?: boolean;
  handleInvalidation: Function;
};

/*
  A component that takes a Site object as defined by HUAPI and
  displays domain info as well as a button to perform an action
*/
const UpdateURLComplete = ({
  site,
  isPending = false,
  handleInvalidation,
}: FinalizeDomainPropOptions) => {
  /*
    The site pending url has been successfully promoted to site url. We will
    show a button that unsets the onboarding flag (addon.meta.setup_flow), which
    will prevent this component from displaying anymore.

    - This will likely be a useHostingOnboardingDataUpdate HUAPI hook call.
    - We want to display the site url here, since the former pending url has now been promoted.

    This component will need to be displayed in two locations:
    1. Replacing the stepper on the Hosting Overview - NOT within the stepper
    2. On Site Overview, between the tab list and tab content
  */
  const { t } = useTranslation('domains', {
    keyPrefix: 'setupProgress.updateURLComplete',
  });

  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();
  const generateAlert = useAlert();

  const { mutate: updateSite } = useSiteUpdate({
    mutation: {
      onSettled: (data, error, variables, context) => {
        if (error) {
          generateAlert({
            description: t('errorMessage'),
            severity: 'error',
            showCloseBtn: true,
          });
        }

        handleInvalidation();
      },
    },
  });

  const handleUpdateOnboarding = () => {
    updateSite({
      siteId: site?.id!,
      data: {
        onboarding: 1,
      } as SiteUpdateBody,
    });
  };

  const siteUrl = site?.url;
  if (!siteUrl || site.pending_url) return <></>;

  return (
    <Card
      data-testid="update-url-complete"
      sx={{
        ...domainSetupCardSx,
        backgroundColor: 'primary.light',
      }}
    >
      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction={isTablet ? 'column' : 'row'}
          spacing={5}
          alignItems={'center'}
        >
          <CardMedia
            image={updateCompleted}
            component="img"
            alt={t('imageAltText') as string}
            sx={{ width: 244, height: 168 }}
          />
          <Stack
            spacing={3}
            alignItems={'center'}
            textAlign={'center'}
            maxWidth={450}
          >
            {isPending ? (
              <Skeleton height={25} width={isTablet ? 200 : 400} />
            ) : (
              <Typography variant={'h2'} sx={{ wordBreak: 'break-word' }}>
                <Trans t={t} i18nKey={'header'} values={{ domain: siteUrl }} />
              </Typography>
            )}
            <Typography variant={'body1'} fontWeight={400}>
              {t('description')}
            </Typography>
            <Button
              data-testid={'update-url-complete-button'}
              variant={'contained'}
              sx={{ minWidth: '120px' }}
              disabled={isPending}
              onClick={handleUpdateOnboarding}
            >
              {t('button')}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UpdateURLComplete;

import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { createContext, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useSitesInfo } from '@newfold/huapi-js';
import { SitesInfo200 } from '@newfold/huapi-js/src/index.schemas';

import useHostingInfo from '~/hooks/useHostingInfo';
import usePromotionInfo from '~/hooks/usePromotionInfo';
import useSitePath from '~/hooks/useSitePath';
import { getSiteDisabled } from '~/utils/getSiteDisabled';

type SiteContextTypes = UseQueryResult<
  AxiosResponse<SitesInfo200, any>,
  AxiosError<unknown, any>
> & {
  data?: SitesInfo200;
  accountId?: string | null | undefined;
  isDisabled?: boolean;
  isHostingDisabled?: boolean;
  isSiteOrHostingDisabled?: boolean;
  promotionInProgress: boolean;
};

export const SiteContext = createContext({} as SiteContextTypes);
export const useSiteContext = () => useContext(SiteContext);

export default function SiteDetail() {
  const { siteId } = useParams();

  const { isValid } = useSitePath(siteId);

  const { data: siteInfo, ...site } = useSitesInfo(Number(siteId), {
    query: {
      enabled: isValid,
      select: (data) => ({
        ...data?.data,
      }),
    },
  });

  const { inProgress } = usePromotionInfo(siteInfo);

  const isDisabled = getSiteDisabled(siteInfo?.status) || site?.isPending;

  const accountBackRef = siteInfo?.account_back_ref;
  const siteAccountId =
    !!accountBackRef && accountBackRef.startsWith('WN')
      ? siteInfo?.account_back_ref
      : siteInfo?.account_id;

  const { isDisabled: hostingDisabled, isPending: hostingLoading } =
    useHostingInfo({
      hostingId: siteAccountId,
      options: {
        enabled: !!siteAccountId,
      },
    });

  type SiteContextTypes = {
    data: SitesInfo200;
    accountId: string | null | undefined;
    isDisabled: boolean;
    isHostingDisabled: boolean;
    isSiteOrHostingDisabled: boolean;
    promotionInProgress: boolean;
  };
  const siteData = {
    data: { ...siteInfo },
    ...site,
    accountId: siteAccountId,
    isDisabled,
    isHostingDisabled: hostingDisabled || hostingLoading,
    isSiteOrHostingDisabled: isDisabled || hostingDisabled || hostingLoading,
    promotionInProgress: inProgress,
  } as SiteContextTypes;

  return (
    // @ts-expect-error
    <SiteContext.Provider value={siteData}>
      <Outlet />
    </SiteContext.Provider>
  );
}

import { PropsWithChildren } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { isTestMode } from '~/components/DevMode';

import CustomRouteListener from './CustomRouteListener';

interface ReactRouterDomPropOptions {
  basename?: string;
}

const ReactRouterDom = ({
  children = undefined,
  basename = undefined,
}: PropsWithChildren<ReactRouterDomPropOptions>) => (
  <Router basename={basename}>
    {isTestMode ? (
      children
    ) : (
      <CustomRouteListener>{children}</CustomRouteListener>
    )}
  </Router>
);

export default ReactRouterDom;

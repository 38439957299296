// Remove custom style var and sx prop
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';

type PromoteInProgressModalPropOptions = {
  open: boolean;
};

const PromoteInProgressModal = ({
  open,
}: PromoteInProgressModalPropOptions) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'staging.promoteSite.promoteModal',
  });

  return (
    <Dialog
      open={open}
      keepMounted
      aria-describedby="promote-site-in-progress-modal-description"
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        {t('title')}
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" noWrap>
          {t('promotionInProgress')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButtonWrapper
          data-testid="goLiveBtn"
          variant="contained"
          loading={true}
        >
          {t('goLiveBtn')}
        </LoadingButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};
export default PromoteInProgressModal;

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';

import {
  AccountHostingList200AccountsItem,
  HostingSitesV2200ItemsItem,
} from '@newfold/huapi-js/src/index.schemas';

import { getHostingDisabled } from '~/utils/getHostingDisabled';
import { getSiteDisabled } from '~/utils/getSiteDisabled';
import {
  getCanShowDocrootMismatchAlert,
  getIsPendingAction,
} from '~/utils/siteMeta';

import LogInButton from './LogInButton';
import Menu from './Menu';
import PrimaryAction from './SiteCardPrimaryAction';
import SiteThumbnail from './SiteThumbnail';
import SiteTitle from './SiteTitle';

interface SiteTypes extends HostingSitesV2200ItemsItem {
  hosting: AccountHostingList200AccountsItem;
}

type SitesCardsPropOptions = {
  list?: SiteTypes[];
};

const SitesCards = ({ list = undefined }: SitesCardsPropOptions) => {
  const theme = useTheme();
  return (
    <Stack display="block">
      <Grid container spacing={2}>
        {list?.map((site) => {
          const { id, status, docroot, type, detected_type, hosting } = site;
          const isAwaitingAction = getIsPendingAction(site);
          const siteDisabled = getSiteDisabled(status);
          const isDisabled = getHostingDisabled(site?.hosting?.status!);

          const isDocrootMismatched = getCanShowDocrootMismatchAlert(status);

          return (
            <Grid
              key={`SitesCard__${id}`}
              item
              xs={12}
              md={6}
              lg={4}
              minWidth={{ md: 376 }}
            >
              <Card
                data-testid={`site-${id}`}
                variant={
                  isAwaitingAction || isDisabled ? 'outlined' : undefined
                }
                sx={{
                  backgroundColor:
                    isAwaitingAction || isDisabled
                      ? theme.palette.grey[100]
                      : undefined,
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <SiteThumbnail disabled={isDisabled} id={id!} />
                    <Grid container direction="row">
                      <Grid item xs={10}>
                        {/* title */}
                        <SiteTitle disabled={isDisabled} {...site} />
                      </Grid>
                      <Grid item xs={2}>
                        {/* extra actions menu */}
                        <Menu
                          disabled={isDisabled || siteDisabled}
                          {...site}
                          id={id}
                          type={type}
                          status={status}
                          detected_type={detected_type}
                          docroot={docroot}
                        />
                      </Grid>
                    </Grid>
                    <Stack
                      direction={'row'}
                      spacing={2}
                      justifyContent={'space-between'}
                      sx={{ maxHeight: 40 }}
                    >
                      <PrimaryAction
                        // @ts-expect-error
                        sx={{ flex: 1 }}
                        status={status!}
                        type={type!}
                        id={id!}
                        migration_id={site?.migration_id!}
                        disabled={isDisabled}
                      />
                      <LogInButton
                        // @ts-expect-error
                        sx={{ flex: 1 }}
                        id={id!}
                        type={type!}
                        status={status!}
                        detected_type={detected_type!}
                        viewActivated="grid"
                        hostingId={hosting?.hosting_id}
                        disabled={
                          isDisabled ||
                          siteDisabled ||
                          !docroot ||
                          isDocrootMismatched
                        }
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default SitesCards;

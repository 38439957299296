import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingAccount } from '@newfold/huapi-js';

import CTB from '~/components/CTB';
import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';
import useFeature from '~/hooks/useFeature';

import ChipPro from './ChipPro';

export default function PlanName({ hideUpgrade = false }) {
  const { t } = useTranslation('hosting');
  // @ts-ignore
  const { data: mfeData } = useMFEContext();
  const { id: hostingId } = useAccount();
  const [canUpgrade] = useFeature('hosting_upgrade');
  const { data: hostingAccountData, isPending } = useHostingAccount(hostingId, {
    query: {
      enabled: !!hostingId,
      select: ({ data }) => data,
    },
  });

  const cloudPlanTier = hostingAccountData?.plan_key?.replace(
    'atomic_bundle_',
    '',
  );

  const planName =
    hostingAccountData?.billing?.product_name ||
    // fallback
    `Cloud ${cloudPlanTier}` ||
    // second fallback, name passed from host application
    mfeData?.hostingData?.productName;

  return (
    <Stack direction="row" spacing={1} alignItems={'center'}>
      <Typography variant="h3">
        {isPending ? (
          <Skeleton variant="text" height={30} width={100} />
        ) : (
          planName
        )}
      </Typography>
      <ChipPro />
      {!hideUpgrade && canUpgrade && (
        <CTB
          product="HOSTING_UPGRADE"
          hasPurchasedRecentlyTypographyProps={{
            variant: 'body1',
            fontWeight: 600,
          }}
        >
          {t('upgradeHosting')}
        </CTB>
      )}
    </Stack>
  );
}

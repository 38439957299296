import { useTranslation } from 'react-i18next';

import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { OverridableStringUnion } from '@mui/types';

type StatusChipProps = {
  type?: 'online' | 'ssl' | 'promotion';
  status?: string;
};

type StatusAttributes = {
  label: string;
  color: OverridableStringUnion<'success' | 'error' | 'warning'>;
  tooltip?: string;
};

export default function StatusChip({
  type = 'online',
  status = '',
}: StatusChipProps) {
  const { t } = useTranslation('cloud', { keyPrefix: 'statusChip' });

  // site status
  const statuses = {
    pendingOnline: [
      'stop_decom',
      'pending_stop_decom',
      'failed_decom',
      'destroy',
      'pending_destroy',
      'failed_destroy',
      'delete',
      'pending_delete',
      'failed_delete',
      'restore',
      'pending_restore',
      'reprovision',
      'pending_reprovision',
      'reset',
      'pending_reset',
      'failed_reset',
      'failed_disable',
    ],
    pendingOffline: ['create', 'pending_create'],
    inactive: ['failed_create', 'disabled', 'decom', 'tos', 'aup'],
  };

  const onlineStatuses: {
    active: StatusAttributes;
    inactive: StatusAttributes;
    pendingOnline: StatusAttributes;
    pendingOffline: StatusAttributes;
  } = {
    active: {
      label: t('labels.online'),
      color: 'success',
    },
    inactive: {
      label: t('labels.offline'),
      color: 'error',
    },
    pendingOnline: {
      label: t('labels.online'),
      color: 'warning',
    },
    pendingOffline: {
      label: t('labels.offline'),
      color: 'warning',
    },
  };

  const getSiteStatus: Function = () => {
    // site is online
    let siteStatus = '';
    if (status === 'active') siteStatus = 'active';
    // site is offline and is being created
    if (statuses.pendingOffline.includes(status)) siteStatus = 'pendingOffline';
    // site as online and is being updated
    if (statuses.pendingOnline.includes(status)) siteStatus = 'pendingOnline';
    // site is offline
    if (statuses.inactive.includes(status)) siteStatus = 'inactive';

    return onlineStatuses[siteStatus as keyof typeof onlineStatuses];
  };

  // ssl status
  const sslStatuses: {
    active: StatusAttributes;
    inactive: StatusAttributes;
    in_progress: StatusAttributes;
  } = {
    active: {
      label: t('labels.secure'),
      color: 'success',
    },
    inactive: {
      label: t('labels.secure'),
      color: 'error',
    },
    in_progress: {
      label: t('labels.secure'),
      color: 'warning',
    },
  };

  const getSslStatus: Function = () => {
    return sslStatuses[status as keyof typeof sslStatuses];
  };

  if (type === 'ssl') {
    const sslStatus = getSslStatus();
    if (!sslStatus) return null;
    return (
      <Chip
        data-testid={'ssl-status-chip'}
        label={sslStatus.label}
        variant="outlined"
        sx={{
          paddingLeft: '10px',
          fontWeight: 'bold',
        }}
        icon={
          <CircleRoundedIcon
            sx={{ fontSize: 12, fontWeight: 'medium' }}
            color={sslStatus.color}
          />
        }
      />
    );
  }

  // staging site promotion status
  const promotionStatuses: {
    failed: StatusAttributes;
    in_progress: StatusAttributes;
  } = {
    in_progress: {
      label: t('labels.promotionInProgress'),
      color: 'warning',
      tooltip: t('tooltips.promotionInProgress'),
    },
    failed: {
      label: t('labels.promotionFailed'),
      color: 'error',
      tooltip: t('tooltips.promotionFailed'),
    },
  };

  const getPromotionStatus: Function = () => {
    return promotionStatuses[status as keyof typeof promotionStatuses];
  };

  if (type === 'promotion') {
    const promotionStatus = getPromotionStatus();
    if (!promotionStatus) return null;
    return (
      <Tooltip title={promotionStatus.tooltip} arrow placement={'top-start'}>
        <Chip
          data-testid={'promotion-status-chip'}
          label={promotionStatus.label}
          variant="outlined"
          sx={{
            paddingLeft: '10px',
            fontWeight: 'bold',
          }}
          icon={
            <CircleRoundedIcon
              sx={{ fontSize: 12, fontWeight: 'medium' }}
              color={promotionStatus.color}
            />
          }
        />
      </Tooltip>
    );
  }

  const siteStatus = getSiteStatus();
  if (!siteStatus) return null;
  return (
    <Chip
      data-testid={'site-status-chip'}
      label={siteStatus.label}
      variant="outlined"
      sx={{
        paddingLeft: '10px',
        fontWeight: 'bold',
      }}
      icon={
        <CircleRoundedIcon
          sx={{ fontSize: 12, fontWeight: 'medium' }}
          color={siteStatus.color}
        />
      }
    />
  );
}

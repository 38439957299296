import { XyzTransition } from '@animxyz/react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAccountHostingList } from '@newfold/huapi-js';
import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

import { useMFEContext } from '~/components/MFEProvider';
import ResponsiveTable from '~/components/VisionUI/ResponsiveTable';

import Empty from './components/Empty';
import Error from './components/Error';
import HostingCards from './components/HostingCards';
import CardsLoading from './components/HostingCards/Loading';
import HostingSearch from './components/HostingSearch';
import HostingTableBody from './components/HostingTableBody';
import TableLoading from './components/HostingTableBody/Loading';

export default function HostingList() {
  const [searchQuery, setSearchQuery] = useState('');

  const {
    // @ts-expect-error
    data: { addHostingAccountUrl },
  } = useMFEContext();
  const { data, isPending, isError } = useAccountHostingList({
    query: {
      select: (data) => data?.data,
    },
  });

  const hostingList = data?.accounts || [];

  const filterHostingList = (
    query: string,
    list: AccountHostingList200AccountsItem[],
  ) => {
    if (!query) {
      return list;
    } else {
      return list.filter((item) =>
        Object.keys(item).some((key) =>
          // @ts-expect-error
          item[key]?.toString().toLowerCase().includes(query.toLowerCase()),
        ),
      );
    }
  };

  const hostingListFiltered = filterHostingList(searchQuery, hostingList);

  const headCells = [
    {
      id: 'hosting_id',
      label: 'Hosting Plan',
      sort: true,
    },
    {
      id: 'status',
      label: 'Status',
      sort: true,
    },
    {
      id: 'account_id',
      label: 'Account',
      sort: true,
    },
    {
      id: 'feature_set',
      label: 'Renewal Date',
      sort: true,
    },
    {
      id: 'manage',
    },
  ];

  return (
    <XyzTransition
      xyz="fade left"
      appear
      duration="auto"
      mode="out-in"
      data-testid="hosting-list"
    >
      <Container data-testid="hosting-list-container">
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography variant="h1">Hosting Packages</Typography>
            <Button variant="outlined" href={addHostingAccountUrl}>
              Buy More Hosting Packages
            </Button>
          </Stack>
          <Card>
            <CardHeader
              title={`All My Hosting Packages ${
                isPending ? '' : `(${hostingListFiltered?.length})`
              }`}
            />
            <CardContent>
              <Stack spacing={3}>
                {hostingList?.length > 3 && (
                  <HostingSearch setSearchQuery={setSearchQuery} />
                )}
                <ResponsiveTable
                  type="hosting accounts"
                  headCells={headCells}
                  list={hostingListFiltered}
                  isPending={isPending}
                  isError={isError}
                  loader={<TableLoading />}
                  error={<Error />}
                  empty={<Empty />}
                  mobile={{
                    loader: <CardsLoading />,
                    ui: <HostingCards />,
                  }}
                >
                  <HostingTableBody />
                </ResponsiveTable>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </XyzTransition>
  );
}

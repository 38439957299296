import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';

import { getHostingSitesV2QueryKey, useSite } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import { useMFEContext } from '~/components/MFEProvider';

type DBWarningModalPropOptions = {
  siteId: number;
  name: string;
};

const DeleteBtn = ({ siteId, name, ...props }: DBWarningModalPropOptions) => {
  const { t } = useTranslation('settings');
  const generateAlert = useAlert();
  //@ts-expect-error
  const { hostingId } = useMFEContext();
  const theme = useTheme();

  const queryClient = useQueryClient();
  const queryKey = getHostingSitesV2QueryKey(hostingId);

  const { mutate: deleteSite, isPending: isPendingSiteDeletion } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'success',
          description: t('deleteSite.alertMessage.success', {
            siteName: name,
          }),
          showCloseBtn: true,
        });
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('deleteSite.alertMessage.error', {
            siteName: name,
          }),
          showCloseBtn: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    },
  });

  const handleSubmit = () => {
    deleteSite({ siteId, data: {} });
  };

  return (
    <>
      <MenuItem
        data-testid="delete-site-btn"
        color="error"
        onClick={handleSubmit}
        sx={{ color: theme.palette.error.main }}
        {...props}
      >
        {isPendingSiteDeletion
          ? t('manageSiteCard.deleteSiteBtnActive')
          : t('manageSiteCard.deleteSiteBtn')}
      </MenuItem>
    </>
  );
};

export default DeleteBtn;

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useStagingSitePromote } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';

import { useBrandInfo } from './useBrandInfo';

const usePromoteSiteValidateOnly = () => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'staging.promoteSite',
  });

  const { phoneNumberFormatted } = useBrandInfo();

  const generateAlert = useAlert();

  const [openPromoteSiteModal, setOpenPromoteSiteModal] = useState(false);

  const sitePromoteResp = useStagingSitePromote({
    mutation: {
      onSuccess: () => {
        setOpenPromoteSiteModal(true);
      },
      onError: (error) => {
        if (error?.response?.data?.error === 'resourceUsageExceeded') {
          setOpenPromoteSiteModal(true);
        } else {
          return generateAlert({
            severity: 'error',
            description: (
              <Trans
                i18nKey={t('alertMessage.error')}
                values={{ phoneNumber: phoneNumberFormatted }}
              />
            ),
            showCloseBtn: true,
            persist: true,
          });
        }
      },
    },
  });

  const handleSitePromote = (siteId: number) => {
    sitePromoteResp.mutate({
      siteId,
      data: { validate_only: true },
    });
  };

  return {
    openPromoteSiteModal,
    setOpenPromoteSiteModal,
    handleSitePromote,
    sitePromoteResp,
  };
};

export default usePromoteSiteValidateOnly;

export const STATUSES = {
  CREATING: ['create', 'pending_create'],
  FAILED: ['failed_create'],
  RESETTING: ['pending_reset', 'reset'],
  FAILED_RESET: ['failed_reset'],
  DELETING: ['pending_delete', 'delete'],
  FAILED_DELETE: ['failed_delete'],
  SUSPENDED: ['decom', 'disabled', 'tos', 'aup'],
};

export const checkDeletingStatus = (status: string | undefined) =>
  STATUSES.DELETING.includes(status!);

export const checkFailedDelete = (status: string | undefined) =>
  STATUSES.FAILED_DELETE.includes(status!);

export const checkResettingStatus = (status: string | undefined) =>
  STATUSES.RESETTING.includes(status!);

export const checkFailedReset = (status: string | undefined) =>
  STATUSES.FAILED_RESET.includes(status!);

export const checkFailedStatus = (status: string | undefined) =>
  STATUSES.FAILED.includes(status!);

export const checkCreatingStatus = (status: string | undefined) =>
  STATUSES.CREATING.includes(status!);

export const checkSuspendedStatus = (status: string | undefined) =>
  STATUSES.SUSPENDED.includes(status!);

export const getSiteStatus = (status: string | undefined) => {
  if (STATUSES.CREATING.includes(status!)) {
    return 'creating';
  }
  if (STATUSES.FAILED.includes(status!)) {
    return 'failed';
  }
};

export const getCanShowLogin = ({ status, type, detected_type }: any) =>
  type === 'wordpress' &&
  type === detected_type &&
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status) &&
  !checkResettingStatus(status);

export const getCanShowDocrootMismatchAlert = (status?: string) => {
  return status === 'docroot_mismatch';
};

export const getCanShowDocrootMissingAlert = ({ docroot }: any) =>
  docroot === null;

export const getCanShowFixSiteModal = ({ status, type, detected_type }: any) =>
  type === 'wordpress' &&
  type !== detected_type &&
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status);

export const getCanShowManageInTitle = ({ status }: any) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status);

export const getCanShowManageInMenu = ({ status }: any) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status);

export const getCanShowViewSite = ({ status }: any) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status) &&
  !checkSuspendedStatus(status);

export const getCanShowRenameSite = ({ status }: any) =>
  !checkCreatingStatus(status) && !checkFailedStatus(status);

export const getIsPendingAction = (site: any) =>
  checkFailedStatus(site?.status) ||
  checkCreatingStatus(site?.status) ||
  checkFailedReset(site?.status) ||
  checkResettingStatus(site?.status) ||
  checkDeletingStatus(site?.status) ||
  checkFailedDelete(site?.status);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { useWebzaiSso, useWeeblySso } from '@newfold/huapi-js';
import { HostingAddons200RowsItem } from '@newfold/huapi-js/src/index.schemas';

import useAlertStore from '~/components/Alerts/alertsStore';
import useHostingInfo from '~/hooks/useHostingInfo';

type BuilderSSOPropOptions = {
  type: string;
  list: HostingAddons200RowsItem[];
  id: string;
};

const BuilderSSO = ({ type, list, id }: BuilderSSOPropOptions) => {
  const { t } = useTranslation('hosting', { keyPrefix: 'builder' });
  const [, { generateAlert }] = useAlertStore();

  const { isDisabled } = useHostingInfo();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [ssoLoading, setSSOLoading] = useState<string | null>(null);
  const [builderId, setBuilderId] = useState(id);
  const openMenu = Boolean(anchorEl);

  const { refetch: refetchWeeblySSO } = useWeeblySso(builderId, {
    query: {
      enabled: false,
    },
  });

  const { refetch: refetchWebzaiSSO } = useWebzaiSso(builderId, {
    query: {
      enabled: false,
    },
  });

  const handleSSOClick = async (selectedId: string) => {
    setSSOLoading(`${type}${selectedId}`);
    try {
      await setBuilderId(selectedId);
      if (type === 'weebly') {
        const { data, isError } = await refetchWeeblySSO();
        const sso = data?.data?.sso;
        if (isError) {
          return generateAlert({
            severity: 'error',
            description: t('ssoLink.alertError'),
            showCloseBtn: true,
          });
        }
        if (sso) return window.open(sso, '_blank');
      }
      if (type === 'webzai') {
        const { data, isError } = await refetchWebzaiSSO();
        const sso = data?.data?.sso;
        if (isError) {
          return generateAlert({
            severity: 'error',
            description: t('ssoLink.alertError'),
            showCloseBtn: true,
          });
        }
        if (sso) return window.open(sso, '_blank');
      }
      generateAlert({
        severity: 'error',
        description: t('ssoLink.notFound'),
        showCloseBtn: true,
      });
    } finally {
      setSSOLoading(null);
      handleClose();
    }
  };

  const handleAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = list[0]?.id;
    list.length > 1 ? handleAnchor(event) : handleSSOClick(id!);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  type BuilderItemTypes = {
    primary?: string;
    secondary: string;
    onClick: () => void;
    addonId: string;
  };

  const BuilderItem = ({
    primary = undefined,
    secondary,
    onClick,
    addonId,
  }: BuilderItemTypes) => (
    <MenuItem
      onClick={onClick}
      sx={{ minWidth: '200px' }}
      disabled={ssoLoading !== addonId && !!ssoLoading}
      data-testid={`${type}-sso-menu-item-${addonId}`}
    >
      <Stack direction={'row'} spacing={1}>
        <ListItemText primary={primary} secondary={secondary || addonId} />
        {ssoLoading === `${type}${addonId}` && <CircularProgress size={20} />}
      </Stack>
    </MenuItem>
  );

  return (
    <>
      <LoadingButton
        disabled={!!ssoLoading || isDisabled}
        loading={`${type}${list[0]?.id}` === ssoLoading && list?.length < 2}
        onClick={(event) => handleDropdown(event)}
        variant="contained"
        endIcon={
          list?.length > 1 ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )
        }
        data-testid={`${type}-sso-button`}
      >
        {type === 'weebly' ? t('weeblyLoginBtn') : t('builderLoginBtn')}
      </LoadingButton>
      {list?.length > 1 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          data-testid={`${type}-sso-menu`}
        >
          <List dense>
            {list?.map((addon) => (
              <BuilderItem
                key={`${addon?.type}-${addon?.id}`}
                primary={String(addon?.meta?.domain)}
                secondary={String(addon?.id)}
                addonId={String(addon?.id)}
                onClick={() => handleSSOClick(addon?.id!)}
              />
            ))}
          </List>
        </Menu>
      )}
    </>
  );
};

export default BuilderSSO;

import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { sitesListV3, useSitesListV3 } from '@newfold/huapi-js';
import {
  SitesListV3Params,
  SitesListV3200,
} from '@newfold/huapi-js/src/index.schemas';

import { removeDeletedSites } from '~/utils/remove-deleted-sites';

type TData = AxiosResponse<SitesListV3200, any>;
type TError = AxiosError<unknown, any>;
type QueryOptionsTypes =
  | {
      query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof sitesListV3>>, TError, TData>
      >;
      axios?: AxiosRequestConfig<any> | undefined;
    }
  | undefined;

const useFilteredSitesV3 = (
  params?: SitesListV3Params,
  options?: QueryOptionsTypes,
) => {
  const { data, isLoading, isError, isSuccess, ...rest } = useSitesListV3(
    params,
    options,
  );

  if (isLoading || isError) {
    return { ...rest, data, isLoading, isError, isSuccess };
  }

  const sites = data?.data?.items;
  const filteredSites = removeDeletedSites(sites);

  return {
    ...rest,
    isLoading,
    isError,
    isSuccess,
    data: {
      ...data,
      data: { ...data?.data, items: filteredSites },
    },
  };
};

export default useFilteredSitesV3;

import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoveUpIcon from '@mui/icons-material/MoveUp';

import { DomainInfo200SetupProgress } from '@newfold/huapi-js/src/index.schemas';

import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';

import { stepColors, stepErrors, stepKeys, stepStatuses } from './stepData';

export function translateStatus(status?: number | null): string {
  if (status === null || status === undefined) return stepStatuses.notstarted;
  else if ([1, 2].includes(status)) return stepStatuses.completed;
  else if (!status) return stepStatuses.active;
  else if (status < 0) return stepStatuses.errored;
  return stepStatuses.notstarted;
}

export function getIsActionableError(error?: string | null): boolean {
  return error
    ? [
        ...stepErrors.unrecoverable,
        ...stepErrors.retry,
        ...stepErrors.unregistered,
      ].includes(error)
    : false;
}

export function getIsStillInProgressError(
  setupProgress?: DomainInfo200SetupProgress,
): boolean {
  return setupProgress
    ? stepErrors.inprogress.includes(setupProgress.validate_dns_error!) ||
        stepErrors.inprogress.includes(setupProgress.validate_ssl_error!) ||
        stepErrors.inprogress.includes(setupProgress.validate_cdn_error!)
    : false;
}

export function getStepIconColor(stepStatus: string): string {
  switch (stepStatus) {
    case stepStatuses.active:
      return stepColors.active;
    case stepStatuses.completed:
      return stepColors.completed;
    case stepStatuses.errored:
      return stepColors.errored;
    default:
      return stepColors.notStarted;
  }
}

// Return the icon for a step
export function getStepIcon(stepType: string, iconColor: string): JSX.Element {
  const iconFontSize = 'large';

  switch (stepType) {
    case stepKeys.cdn:
      return (
        <CloudDoneOutlinedIcon
          data-testid={'cloud-icon'}
          fontSize={iconFontSize}
          sx={{ color: iconColor }}
        />
      );
    case stepKeys.dns:
      return (
        <LanguageIcon
          data-testid={'language-icon'}
          fontSize={iconFontSize}
          sx={{ color: iconColor }}
        />
      );
    case stepKeys.site:
      // The WP Icon (SvgIcon) doesn't seem to accept sx, so using this workaround
      const wpIconColorParts = iconColor.split('.main');
      return (
        <WordPressIcon
          data-testid={'wp-icon'}
          fontSize={iconFontSize}
          color={wpIconColorParts[0]}
        />
      );
    case stepKeys.ssl:
      return (
        <LockOutlinedIcon
          data-testid={'lock-icon'}
          fontSize={iconFontSize}
          sx={{ color: iconColor }}
        />
      );
    case stepKeys.url:
      return (
        <MoveUpIcon
          data-testid={'moveup-icon'}
          fontSize={iconFontSize}
          sx={{ color: iconColor }}
        />
      );
    default:
      return <></>;
  }
}

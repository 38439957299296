import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SitesLoading = () => {
  const { t } = useTranslation('sites');

  return (
    <Paper variant="outlined" sx={{ p: 4, textAlign: 'center' }}>
      <Stack spacing={2} alignItems={'center'}>
        <Typography variant="h3">{t('cloud.loadingSites')}</Typography>
      </Stack>
    </Paper>
  );
};

export default SitesLoading;

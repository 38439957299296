import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PieChart, PieChartProps } from '@mui/x-charts/PieChart';

import PieChartCenterLabel from './PieChartCenterLabel';
import { chartData, getChartData, getValues } from './utils';

interface CustomPieChartProps extends Omit<PieChartProps, 'series'> {
  data: chartData[];
  cardTitle: string; //  Title for the card
  isLoading?: boolean;
  cardHeaderTooltip?: string; //  Title tooltip for the card
  type?: string; // Optional type of card to handle conversions("storage" for disk usage)
}

const CustomPieChart: React.FC<CustomPieChartProps> = ({
  data,
  width = 260, // Default width
  height = 180, // Default height
  cardTitle,
  type = 'default',
  cardHeaderTooltip = '',
  isLoading = false,
  ...props
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'customPieChart',
  });
  const theme = useTheme();

  const {
    totalValue,
    usedValue,
    customLegendUsedValue,
    customLegendTotalValue,
  } = getValues(data, type);

  // Calculate the percentage of used space
  const OUT_OF_RANGE_COLORS = [theme.palette.error.main];
  const WARN_COLORS = [theme.palette.warning.main, theme.palette.grey[300]];
  const DEFAULT_COLORS = [theme.palette.info.dark, theme.palette.grey[300]];

  let usedPercentage = 0;
  if (totalValue !== 0) {
    const percentage = Math.round((usedValue / totalValue) * 100);
    usedPercentage = percentage > 100 ? 100 : percentage;
  }
  // colors for 100% and 90%
  let colors = { colors: DEFAULT_COLORS };
  if (usedPercentage === 100) {
    colors = { colors: OUT_OF_RANGE_COLORS };
  } else if (usedPercentage >= 90) {
    colors = { colors: WARN_COLORS };
  }
  // Data for the pie chart
  const chartData = getChartData(data, type);

  const showTooltip = customLegendTotalValue !== 'Unlimited';

  if (isLoading || chartData.length === 0) {
    return (
      <Card data-testid="PieChart-card-loader">
        <CardHeader
          title={
            <Typography variant="h3" data-testid="piechart-card-title">
              {cardTitle}
            </Typography>
          }
        ></CardHeader>
        <CardContent>
          {isLoading && (
            <Skeleton width={width} height={height} animation="wave" />
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card data-testid="PieChart-card">
      <CardHeader
        title={
          <Stack direction={'row'} spacing={2} alignItems="center">
            <Typography variant="h3" data-testid="PieChart-card-title">
              {cardTitle}
            </Typography>
            {cardHeaderTooltip && (
              <Tooltip title={cardHeaderTooltip} arrow placement="top">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      ></CardHeader>
      <CardContent>
        {/* Pie Chart */}
        <Stack direction={{ md: 'row', sm: 'column' }} alignItems="center">
          <Box display="flex">
            {chartData && (
              <PieChart
                series={[
                  {
                    data: chartData,
                    innerRadius: 70,
                    outerRadius: 80,
                  },
                ]}
                tooltip={{ trigger: showTooltip ? 'item' : 'none' }}
                width={width}
                height={height}
                {...colors}
                {...props}
              >
                <PieChartCenterLabel
                  outOfRange={usedPercentage === 100}
                  secondaryLabel={t('used')}
                  label={`${usedPercentage}%`}
                ></PieChartCenterLabel>
              </PieChart>
            )}
          </Box>
          {/* Custom legend */}
          <Box
            display="flex"
            color={usedPercentage === 100 ? 'error.main' : 'text'}
          >
            <Typography variant="body2">
              {t('usedOfTxt', {
                usedValue: customLegendUsedValue,
                totalValue: customLegendTotalValue,
              })}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomPieChart;

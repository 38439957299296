import {
  ModalIconBluehost,
  ModalIconDefault,
  ModalIconHostGator,
  ModalIconNetSol,
  ModalIconRegister,
  ModalIconWeb,
} from '~/scenes/Hosting/scenes/HostingDetail/components/FirstSiteInstalling/assets';

const brands = [
  {
    brand: 'bluehost',
    icon: ModalIconBluehost,
    fileName: '../images/modal-icon-bluehost.svg',
  },
  {
    brand: 'hostgator',
    icon: ModalIconHostGator,
    fileName: '../images/modal-icon-hostgator.svg',
  },
  {
    brand: 'netsol',
    icon: ModalIconNetSol,
    fileName: '../images/modal-icon-netsol.svg',
  },
  {
    brand: 'register',
    icon: ModalIconRegister,
    fileName: '../images/modal-icon-register.svg',
  },
  {
    brand: 'web',
    icon: ModalIconWeb,
    fileName: '../images/modal-icon-web.svg',
  },
];

const defaultBrand = {
  brand: 'defaultBrand',
  icon: ModalIconDefault,
  fileName: '../images/modal-icon-default.svg',
};

const getBrandLogo = (brand: string) => {
  if (brand) {
    const brandLowerCase = brand.toLowerCase();
    const brandFound = brands.find((e) => e.brand === brandLowerCase);
    return brandFound || defaultBrand;
  }

  return defaultBrand;
};

export default getBrandLogo;

export const isValidPassword = (value: string) => {
  const PASSWORD_REGEX =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return PASSWORD_REGEX.test(value);
};

export const isValidUsername = (val: string) => {
  const USER_NAME_REGEX = /^[a-zA-Z0-9]+$/;
  return USER_NAME_REGEX.test(val);
};

export const isValidCase = (value: string) => {
  const UPPERCASE_REGEX = /[A-Z]/;
  return {
    isUpperCase: UPPERCASE_REGEX.test(value),
  };
};

export const isValidCharacters = (value: string) => {
  const SPECIAL_CHARACTERS_REGEX = /[!@#$%^&*()]/;
  return {
    isSplCharacters: SPECIAL_CHARACTERS_REGEX.test(value),
  };
};

export const isNumeric = (value: string) => {
  const NUMERIC_REGEX = /[0-9]/;
  return {
    isNumber: NUMERIC_REGEX.test(value),
  };
};

export const isValidLength = (value: string) => {
  const MIN_LENGTH_REGEX = /^.{8,}$/;
  return {
    isMinLength: MIN_LENGTH_REGEX.test(value),
  };
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';

import { useHostingAccount } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import LoadingScene from '~/components/MFEProvider/lib/ReactSuspense/LoadingScene';
import useFeature from '~/hooks/useFeature';
import MultipleChoiceSsoDialog from '~/scenes/Hosting/scenes/HostingDetail/components/QuickLinks/MultipleChoiceSsoDialog';
import useServerEmailChoices from '~/scenes/Hosting/scenes/HostingDetail/components/QuickLinks/ServerEmailChoices';

const ServerEmail = () => {
  const { t } = useTranslation('quickLinks');
  // @ts-ignore
  const { hostingId } = useMFEContext();

  const navigate = useNavigate();

  const [hasAdvancedHosting, , isClientReady] = useFeature('advanced_hosting');
  const [hasCpanel] = useFeature('cpanel');

  const { data, isLoading } = useHostingAccount(hostingId);

  const isReseller = data?.data?.type === 'reseller';

  const choices = useServerEmailChoices();

  // validation the user can see "Server Email" content before showing
  if (
    !isLoading &&
    isClientReady &&
    (!hasCpanel || hasAdvancedHosting || isReseller)
  ) {
    // otherwise, send the user to the hosting page
    navigate('/');
    return null;
  }

  return (
    <Container>
      {!isClientReady || isLoading || !choices ? (
        // LOADING
        <LoadingScene />
      ) : (
        // EMAIL SELECTION DIALOG
        <MultipleChoiceSsoDialog
          isOpen
          onClose={() => navigate('/')}
          title={t('serverEmail.title')}
          description={t('serverEmail.description')}
          choices={choices}
        />
      )}
    </Container>
  );
};

export default ServerEmail;

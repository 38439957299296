import { Fragment, PropsWithChildren, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';

import useResponsive from '~/hooks/useResponsive';

import Drawer from './Drawer';

type TabsProps = { pages: Array<{ title: string; path: string }> };

const Tabs = ({ pages }: PropsWithChildren<TabsProps>) => {
  const { isMobile, isDesktop } = useResponsive();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const toggleDrawer =
    (open: boolean, callback?: (to?: string) => void) =>
    (
      e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
      setIsDrawerOpen(open);

      if (callback && typeof callback === 'function') {
        callback();
      }
    };

  const currentTab = pages.find(({ path: to }) => to === pathname);

  return (
    <>
      {isMobile() && currentTab && (
        <>
          <MUITabs
            variant="fullWidth"
            value={pathname}
            onClick={toggleDrawer(true)}
          >
            <Tab
              label={currentTab.title}
              icon={<ArrowDropDownIcon />}
              iconPosition="end"
              value={pathname}
              sx={{
                textTransform: 'none',
                justifyContent: 'start',
                paddingLeft: 0,
                minHeight: 40,
              }}
            />
          </MUITabs>

          {/* drawer */}
          <Drawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
            <List sx={{ p: 0 }}>
              {pages.map(({ title, path: to }) => (
                <Fragment key={`mobile-list-${to}`}>
                  <ListItem key={title} disablePadding>
                    <ListItemButton
                      onClick={toggleDrawer(false, () =>
                        navigate(`${to}${search}`),
                      )}
                      // TODO: style should be overridden at theme level
                      selected={to === pathname}
                    >
                      <ListItemText primary={title} />
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" />
                </Fragment>
              ))}
            </List>
          </Drawer>
        </>
      )}
      {isDesktop() && (
        <MUITabs variant="scrollable" scrollButtons="auto" value={pathname}>
          {pages.map(({ title, path: to }) => (
            <Tab
              component={RouterLink}
              key={`tab-${to}`}
              label={title}
              value={to}
              to={`${to}${search}`}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </MUITabs>
      )}
    </>
  );
};

export default Tabs;

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  useHostingAccount,
  useHostingEcommerceInfo,
  useSitesInfo,
} from '@newfold/huapi-js';

import CTB from '~/components/CTB';
import HighlightedBox from '~/components/HighlightedBox';
import SsoLink from '~/components/SsoLink';
import useFeature from '~/hooks/useFeature';
import useHostingPlanDetails from '~/hooks/useHostingPlanDetails';

type HostingInfoPropOptions = {
  accountId?: string;
  sso?: boolean;
};

export const HostingInfo = ({
  accountId = undefined,
  sso = false,
}: HostingInfoPropOptions) => {
  const { t } = useTranslation('hosting');
  const { siteId } = useParams();

  const { data: hostingAccount, isLoading: isLoadingHostingAccount } =
    useHostingAccount(String(accountId), {
      query: {
        enabled: !!accountId,
      },
    });

  const [canUpgrade, ,] = useFeature('hosting_upgrade');
  const [isReseller] = useFeature('reseller');
  const [isPlesk] = useFeature('plesk');

  const { productName, isBlueHost, isBluehostOnlineStorePlan } =
    useHostingPlanDetails(String(accountId));

  const advancedHosting = !!hostingAccount?.data?.advanced;

  const { data: siteInfo, isLoading: isLoadingSiteInfo } = useSitesInfo(
    Number(siteId),
  );

  const isGenericOrBuilderSite = ['generic', 'builder'].includes(
    siteInfo?.data?.type?.toLowerCase()!,
  );

  const {
    data: ecommerceData,
    isLoading: isLoadingEcommerce,
    isError: isErrorEcommerce,
  } = useHostingEcommerceInfo(String(accountId), {
    query: {
      enabled: !!accountId || !advancedHosting || !isPlesk,
    },
  });

  const noEcommerceAddon = ecommerceData?.data === null;

  const isWordPress = siteInfo?.data?.type === 'wordpress';
  const detectedSiteGeneric = siteInfo?.data?.detected_type === 'generic';
  const mismatchSite = detectedSiteGeneric && isWordPress;

  if (isLoadingEcommerce) return <Skeleton variant="text" width={160} />;

  if (isLoadingSiteInfo) return <Skeleton variant="text" width={160} />;

  if (isErrorEcommerce) return productName;

  if (!productName) return null;

  const canShowPurchaseCTB =
    !isPlesk &&
    !isReseller &&
    noEcommerceAddon &&
    !advancedHosting &&
    !isGenericOrBuilderSite &&
    !mismatchSite &&
    !isLoadingHostingAccount;

  const canShowUpdgradeCTB =
    !noEcommerceAddon &&
    !advancedHosting &&
    !isGenericOrBuilderSite &&
    !mismatchSite &&
    !isLoadingHostingAccount;

  return (
    <ListItem disableGutters dense data-testid="hosting-info">
      <ListItemText>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            {isLoadingHostingAccount ? (
              <Skeleton variant="text" width="50%" />
            ) : (
              <Typography variant="body2">{productName}</Typography>
            )}
            {canUpgrade && !isBluehostOnlineStorePlan && (
              <CTB product="HOSTING_UPGRADE">
                {/* @ts-expect-error */}
                <Typography variant="body" noWrap>
                  {t('upgradeHosting')}
                </Typography>
              </CTB>
            )}
          </Stack>
          {/* hide  online store plan for bluehost */}
          {!isBlueHost && (
            <>
              {canShowPurchaseCTB && (
                <HighlightedBox>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ShoppingCart color="secondary" fontSize="small" />
                    <Typography variant="body2">
                      {t(`createOnlineStore.title`)}
                    </Typography>
                    <CTB product="BH_ECOMM_ADDON_FAMILY">
                      {/* @ts-expect-error */}
                      <Typography variant="body2" noWrap>
                        {t(`createOnlineStore.action`)}
                      </Typography>
                    </CTB>
                  </Stack>
                </HighlightedBox>
              )}

              {canShowUpdgradeCTB && (
                <HighlightedBox>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ShoppingCart color="secondary" fontSize="small" />
                    <Typography variant="body2" noWrap>
                      <b>{t(`ecommerce.storePlan`)}</b>
                    </Typography>
                    <Typography variant="body2">
                      {t(`ecommerce.nameByLevel.${ecommerceData?.data?.level}`)}
                    </Typography>
                    {ecommerceData?.data?.level === 'basic' && !sso ? (
                      <CTB
                        product="BH_ECOMM_ADDON_PLUS"
                        data={{ prodInstId: ecommerceData?.data?.back_ref }}
                      >
                        {/* @ts-expect-error */}
                        <Typography variant="body2" noWrap>
                          {t('ecommerce.upgradeStorePlans')}
                        </Typography>
                      </CTB>
                    ) : (
                      sso && (
                        <SsoLink
                          type={'site'}
                          queryParams={{ app: 'store' }}
                          variant="textLink"
                        >
                          {t('ecommerce.viewDetails')}
                        </SsoLink>
                      )
                    )}
                  </Stack>
                </HighlightedBox>
              )}
            </>
          )}
        </Stack>
      </ListItemText>
    </ListItem>
  );
};

export default HostingInfo;

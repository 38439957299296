import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

const MenuContext = createContext({ handleClose: () => {} });

const MoreActionsMenu = ({
  buttonRef = undefined,
  children = undefined,
}: PropsWithChildren<{ buttonRef?: React.Ref<HTMLButtonElement> | null }>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <MenuContext.Provider value={{ handleClose }}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        ref={buttonRef}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </MenuContext.Provider>
  );
};

export default MoreActionsMenu;

export const useMoreActionsMenu = () => useContext(MenuContext);

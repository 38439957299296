import React, { ReactNode } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';

import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import VirtualizedTableBody from './components/TableBody';
import VirtualizedTableHeader from './components/TableHeader';

type virtualizedTablePropOptions = {
  type?: string;
  list?: {}[];
  isFetching?: boolean;
  isPending?: boolean;
  loader?: ReactNode;
  loaderCount?: Number[];
  isError?: boolean;
  error?: ReactNode;
  errorCopy?: string;
  emptyListCopy?: string;
  empty?: ReactNode;
  headCells: {
    id: string;
    label?: string;
    sort?: boolean;
    toolTip?: string;
  }[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  handleRequestSort?: Function;
  children?: ReactNode;
  virtualizedTableOptions?: {
    rowContent: (index: number, item: any) => JSX.Element;
    itemHeight?: number;
  };
};

const VirtualizedTable = ({
  type = 'list',
  list = [],
  isFetching = false,
  isPending = false,
  loader = undefined,
  loaderCount = [1],
  isError = false,
  error = undefined,
  errorCopy = undefined,
  emptyListCopy = undefined,
  empty = undefined,
  headCells,
  order = 'asc',
  orderBy = '',
  handleRequestSort = () => {},
  virtualizedTableOptions = undefined,
}: virtualizedTablePropOptions) => {
  const isEmpty = list?.length === 0;

  const tableHeight = (() => {
    const EMPTY_HEIGHT = 270;
    const ERROR_HEIGHT = 170;
    const MIN_ROWS = 11; // no scroll including header.
    const itemCount = list?.length + 1; // include header

    if (isEmpty) return EMPTY_HEIGHT;
    if (isError) return ERROR_HEIGHT;

    return Math.min(
      virtualizedTableOptions?.itemHeight! * itemCount,
      virtualizedTableOptions?.itemHeight! * MIN_ROWS,
    );
  })();

  const VirtuosoTableComponents: TableComponents = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => (
      <>
        {isFetching && <LinearProgress />}
        <Table
          aria-labelledby="tableTitle"
          data-testid={`${type}-list`}
          {...props}
        />
      </>
    ),
    TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead {...props} ref={ref} />
    )),
    TableRow,
    TableBody: React.forwardRef((props, ref) => (
      <VirtualizedTableBody
        props={props}
        ref={ref}
        headCells={headCells}
        type={type}
        isPending={isPending}
        loader={loader}
        loaderCount={loaderCount}
        error={error}
        errorCopy={errorCopy}
        emptyListCopy={emptyListCopy}
        empty={empty}
        isEmpty={isEmpty}
        isError={isError}
      />
    )),
  };

  return (
    <TableVirtuoso
      data={list}
      components={VirtuosoTableComponents}
      fixedHeaderContent={() => (
        <VirtualizedTableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />
      )}
      itemContent={virtualizedTableOptions!.rowContent}
      overscan={50}
      defaultItemHeight={virtualizedTableOptions!.itemHeight}
      fixedItemHeight={virtualizedTableOptions!.itemHeight}
      style={{ height: tableHeight }}
    />
  );
};

export default VirtualizedTable;

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useMFEContext } from '../../MFEProvider';

export default function TenantEmpty() {
  const { t } = useTranslation('tenant');

  const {
    // @ts-expect-error
    data: { addHostingAccountUrl },
  } = useMFEContext();

  return (
    <Card>
      <CardHeader
        title={t('empty.title')}
        action={
          <Button variant="contained" href={addHostingAccountUrl}>
            {t('empty.cta')}
          </Button>
        }
      />
      <CardContent>{t('empty.description')}</CardContent>
    </Card>
  );
}

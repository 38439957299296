import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';

import FixSiteModal from '~/components/FixSiteModal';
import SsoLink from '~/components/SsoLink';
import useResponsive from '~/hooks/useResponsive';
import {
  checkCreatingStatus,
  checkDeletingStatus,
  checkFailedDelete,
  checkFailedReset,
  checkFailedStatus,
  checkResettingStatus,
  getCanShowFixSiteModal,
  getCanShowLogin,
} from '~/utils/siteMeta';

// @ts-expect-error
import { ReactComponent as WPIcon } from './WordPressIcon.svg';

type LogInButtonPropOptions = {
  id: string | number;
  type: string;
  status: string;
  detected_type: string;
  viewActivated: string;
  disabled: boolean;
};

const LogInButton = ({
  id,
  type,
  status,
  detected_type,
  viewActivated,
  disabled,
  ...props
}: LogInButtonPropOptions) => {
  const { t } = useTranslation('sites');
  const { isMobile } = useResponsive();

  const [openFixSiteModal, setOpenFixSiteModal] = useState(false);

  // hide if
  if (
    checkFailedStatus(status) ||
    checkFailedReset(status) ||
    checkResettingStatus(status) ||
    checkDeletingStatus(status) ||
    checkFailedDelete(status)
  )
    return null;

  // hide if status is not 'active'
  if (checkCreatingStatus(status)) return null;

  if (getCanShowFixSiteModal({ status, type, detected_type })) {
    return (
      <>
        <Button
          data-testid={`sso-button-${id}`}
          variant="outlined"
          color="primary"
          disabled={disabled}
          onClick={() => setOpenFixSiteModal(true)}
        >
          {viewActivated === 'grid' && !isMobile() && (
            <SvgIcon sx={{ mr: 1 }} fontSize="small">
              <WPIcon />
            </SvgIcon>
          )}
          {isMobile() ? t('loginBtnMobile') : t('loginBtn')}
          {viewActivated === 'grid' && <KeyboardArrowRightIcon />}
        </Button>
        <FixSiteModal
          siteId={id}
          open={openFixSiteModal}
          setOpen={setOpenFixSiteModal}
        />
      </>
    );
  }
  // show for wp that do not have the above statuses
  if (getCanShowLogin({ status, type, detected_type }))
    return (
      <SsoLink
        {...props}
        disabled={disabled}
        variant="outlined"
        type="site"
        id={id}
        data-testid={`sso-login-button-${id}`}
      >
        {viewActivated === 'grid' && !isMobile() && (
          <SvgIcon sx={{ mr: 1 }} fontSize="small">
            <WPIcon />
          </SvgIcon>
        )}
        <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
          {isMobile() ? t('loginBtnMobile') : t('loginBtn')}
        </Box>
        {viewActivated === 'grid' && <KeyboardArrowRightIcon />}
      </SsoLink>
    );

  return null;
};

export default LogInButton;

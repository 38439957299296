import { ForwardedRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

type VirtualizedTableBodyOptions = {
  props: {};
  ref: ForwardedRef<HTMLTableSectionElement>;
  type?: string;
  isPending: boolean;
  loader: ReactNode;
  loaderCount?: Number[];
  isError?: boolean;
  error?: ReactNode;
  errorCopy?: string;
  emptyListCopy?: string;
  empty?: ReactNode;
  headCells: {
    id: string;
    label?: string;
    sort?: boolean;
    toolTip?: string;
  }[];
  isEmpty: boolean;
};

export default function VirtualizedTableBody({
  props,
  ref,
  isPending,
  loader,
  loaderCount = [1],
  error = undefined,
  errorCopy = undefined,
  emptyListCopy = undefined,
  empty = undefined,
  isError = false,
  type = 'list',
  headCells,
  isEmpty,
}: VirtualizedTableBodyOptions) {
  const { t } = useTranslation('lists');

  if (isPending && loader) return loader;
  if (isPending) {
    return (
      <TableBody>
        {loaderCount.map((key) => (
          <TableRow
            key={`loader-row-${key}`}
            data-testid={`table-loader-${key}`}
          >
            <TableCell>
              <Skeleton variant="text" width={120} />
              <Skeleton variant="text" width={200} />
            </TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Skeleton variant="text" height={24} width={80} />
                <Skeleton variant="rounded" width={100} height={36} />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center" justifyContent="end">
                <Skeleton variant="rounded" width={100} height={36} />
                <Button id="basic-button" disabled>
                  <MoreVertRounded />
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (isError) {
    return (
      <TableBody data-testid={`${type}-table-error`}>
        <TableRow>
          <TableCell colSpan={headCells.length}>
            <Typography>
              {error || errorCopy || t('listError', { type })}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (isEmpty) {
    return (
      <TableBody data-testid={`${type}-table-empty`}>
        <TableRow>
          <TableCell colSpan={headCells.length}>
            <Typography>
              {empty || emptyListCopy || t('listNotAvailable', { type })}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody
      component="div"
      data-testid={`${type}-table`}
      {...props}
      ref={ref}
    />
  );
}

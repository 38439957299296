import { bool, func, node, oneOfType, shape } from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import DefaultErrorScene from './ErrorScene';

const propTypes = {
  children: oneOfType([node, func]),
  ErrorScene: oneOfType([node, func]),
  hideOnError: bool,
  onError: func,
  history: shape({}),
};

const ErrorBoundary = ({
  ErrorScene = DefaultErrorScene,
  onError = null,
  children = null,
  hideOnError = false,
}) => {
  const errorhandler = (error, errorInfo) => {
    if (onError) {
      return onError(error, errorInfo);
    }
  };

  const ErrorDisplay = (error) => {
    if (hideOnError) return null;
    return <ErrorScene {...error} />;
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorDisplay} onError={errorhandler}>
      {children}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = propTypes;
export default ErrorBoundary;

import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Circle from '@mui/icons-material/Circle';
import PlayArrowOutlined from '@mui/icons-material/PlayArrowOutlined';
import PowerSettingsNewOutlined from '@mui/icons-material/PowerSettingsNewOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingAdvancedServerQueryKey,
  useHostingAdvancedServer,
  useHostingAdvancedServerStatus,
} from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';

import ButtonAlt from '../utils/ButtonAlt';
import AdvancedHostingPowerOffServerModal from './PowerOffServerModal';
import AdvancedHostingRebootModal from './RebootModal';
import AdvancedHostingServerStatusModal from './ServerStatusModal';

const AdvancedHostingServerStatus = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });

  const generateAlert = useAlert();
  const { id: hostingId } = useAccount();

  const [hasAdvancedHosting] = useFeature('advanced_hosting');
  const [isPlesk] = useFeature('plesk');
  const [isWindows] = useFeature('windows');

  const [inputs, setInputsState] = useState({
    openRebootModal: false,
    openPowerOffServerModal: false,
    showRebootSuccessfullTxt: false,
    showPowerOffSuccessfullTxt: false,
    showServerOnSuccessfullTxt: false,
    openServerStatusSuccessModal: false,
    showLoaderForRebootServer: false,
    showLoaderForStartServer: false,
    showLoaderForPowerOffServer: false,
  });

  const setAlertMessage = useCallback(
    (variant: string, descKey: string) => {
      generateAlert({
        severity: variant,
        description: descKey,
        showCloseBtn: true,
      });
    },
    [generateAlert],
  );

  const handleInputs = (target: { name: any; value: any }) => {
    // destructuring the object
    let { name, value } = target;
    setInputsState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const systemConsoleData: any = {
    name: 'systemconsole',
    type: 'vps',
    canShow: hasAdvancedHosting && !isPlesk && !isWindows,
    queryParams: {
      app: 'systemconsole',
    },
  };

  const refetchInterval = 5000;

  //Get Hosting Capabilities
  const [showServerControls, , clientReady] = useFeature('server_controls');

  //CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const intermediateStates = [
    'start',
    'stop',
    'pending_stop',
    'reboot_soft',
    'pending_reboot_soft',
    'pending_resize',
    'resize',
    'pending_start',
  ];
  const finalStates = useMemo(() => ['active', 'stopped'], []);

  //Get Server Status
  const {
    data,
    isPending: isPendingServerStatus,
    isSuccess: isSuccessServerStatus,
    isError: isErrorServerStatus,
    refetch: refetchServerStatus,
  } = useHostingAdvancedServer(hostingId, {
    query: {
      enabled: !!hostingId && clientReady && showServerControls,
      refetchInterval: (query) => {
        const status = query?.state?.data?.data?.status!;
        return !!hostingId &&
          clientReady &&
          showServerControls &&
          intermediateStates.includes(status)
          ? refetchInterval
          : false;
      },
    },
  });

  // onSuccess - useHostingAdvancedServer
  useEffect(() => {
    if (isSuccessServerStatus) {
      if (
        !isPendingServerStatus &&
        showServerControls &&
        finalStates.includes(data?.data?.status!) &&
        (inputs.showLoaderForStartServer ||
          inputs.showLoaderForRebootServer ||
          inputs.showLoaderForPowerOffServer)
      ) {
        handleInputs({ name: 'showLoaderForRebootServer', value: false });
        handleInputs({ name: 'showLoaderForStartServer', value: false });
        handleInputs({ name: 'showLoaderForPowerOffServer', value: false });
        handleInputs({ name: 'openServerStatusSuccessModal', value: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccessServerStatus]);

  // onError - useHostingAdvancedServer
  useEffect(() => {
    if (isErrorServerStatus) {
      setAlertMessage('error', t('advHostingServerInfo.errorMsg'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorServerStatus]);

  //Put Server Status.
  const queryClient = useQueryClient();
  const advancedServerQueryKey = getHostingAdvancedServerQueryKey(hostingId);
  const { mutate: serverStatusToggle } = useHostingAdvancedServerStatus({
    mutation: {
      onMutate: (newStatus: any) => {
        // @ts-ignore
        const oldStatus = queryClient.getQueryData(advancedServerQueryKey)?.data
          .status;
        queryClient.setQueryData(advancedServerQueryKey, newStatus);
        return { oldStatus, newStatus };
      },
      onSuccess: async ({ data }: any) => {
        //TODO -  Alert Message to be provided by the product.
        queryClient.invalidateQueries({ queryKey: advancedServerQueryKey });
      },
    },
  });

  useEffect(() => {
    if (showServerControls && hasPurchasedRecently === false) {
      refetchServerStatus();
    }
  }, [showServerControls, hasPurchasedRecently, refetchServerStatus]);

  const serverStatus = data?.data.status!;
  const disableServerStatusButtons = [
    'failed_start',
    'decom',
    'pending_start',
    'start',
    'pending_stop',
    'stop',
    'pending_reboot_soft',
    'reboot_soft',
    'deleted',
    'failed_stop',
  ].includes(serverStatus);
  const disableStartServerStatusButton = [
    'failed_start',
    'decom',
    'active',
    'pending_start',
    'start',
    'pending_stop',
    'stop',
    'pending_reboot_soft',
    'reboot_soft',
    'deleted',
    'failed_stop',
  ].includes(serverStatus);
  const disableStopServerStatusButton = [
    'failed_start',
    'decom',
    'stopped',
    'pending_start',
    'start',
    'pending_stop',
    'stop',
    'pending_reboot_soft',
    'reboot_soft',
    'deleted',
    'failed_stop',
  ].includes(serverStatus);

  //Server Status Label and Color
  const getServerStatusandColor = () => {
    switch (serverStatus) {
      default:
      case 'pending_resize':
      case 'resize':
        return {
          status: 'loading',
        };
      case 'start':
      case 'pending_start':
        return {
          color: 'success.main',
          status: 'starting',
        };
      case 'active':
        return {
          color: 'success.main',
          status: 'Online',
        };
      case 'failed_start':
      case 'decom':
      case 'deleted':
      case 'stopped':
        return {
          color: 'error.main',
          status: 'Offline',
        };
      case 'reboot_soft':
      case 'pending_reboot_soft':
        return {
          color: 'error.main',
          status: 'Rebooting',
        };
      case 'stop':
      case 'pending_stop':
        return {
          color: 'error.main',
          status: 'Powering off',
        };
    }
  };
  const handleServerStatus = async () => {
    const request = serverStatus === 'active' ? 'stop' : 'start';
    serverStatusToggle({
      hostingId,
      data: {
        // @ts-ignore
        status: request,
        action: request,
      },
    });

    if (request === 'start') {
      handleInputs({ name: 'showLoaderForStartServer', value: true });
      handleInputs({ name: 'showServerOnSuccessfullTxt', value: true });
    } else {
      handleInputs({ name: 'openPowerOffServerModal', value: false });
      handleInputs({ name: 'showLoaderForPowerOffServer', value: true });
    }
  };

  //RebootButton
  const handleRebootServer = async () => {
    const request = serverStatus === 'active' ? 'reboot' : 'start';
    serverStatusToggle({
      hostingId,
      data: {
        // @ts-ignore
        status: 'reboot_soft',
        action: request,
      },
    });

    handleInputs({ name: 'openRebootModal', value: false });
    handleInputs({ name: 'showLoaderForRebootServer', value: true });
  };

  const showRebootServerModal = () => {
    handleInputs({ name: 'openRebootModal', value: true });
    handleInputs({ name: 'showRebootSuccessfullTxt', value: true });
  };

  const showPowerOffServerModal = () => {
    handleInputs({ name: 'openPowerOffServerModal', value: true });
    handleInputs({ name: 'showPowerOffSuccessfullTxt', value: true });
  };

  const handleClose = () => {
    handleInputs({ name: 'showPowerOffSuccessfullTxt', value: false });
    handleInputs({ name: 'showRebootSuccessfullTxt', value: false });
    handleInputs({ name: 'openRebootModal', value: false });
    handleInputs({ name: 'openPowerOffServerModal', value: false });
  };

  const handleSuccessfulModalClose = () => {
    handleInputs({ name: 'showRebootSuccessfullTxt', value: false });
    handleInputs({ name: 'showPowerOffSuccessfullTxt', value: false });
    handleInputs({ name: 'showServerOnSuccessfullTxt', value: false });
    handleInputs({ name: 'openServerStatusSuccessModal', value: false });
  };

  const { color, status } = getServerStatusandColor();
  // server status color
  const statusColor = isErrorServerStatus ? 'error.main' : color;

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Circle sx={{ fontSize: 10, color: statusColor }} />
            <span>
              {t('advHostingServerInfo.serverTxt')}
              <Typography variant="h2" color={statusColor} component={'span'}>
                &nbsp;
                {status}
              </Typography>
            </span>
          </Stack>
        </Typography>

        <Stack
          gap={3}
          direction={'row'}
          flexWrap="wrap"
          justifyItems={'stretch'}
        >
          <ButtonAlt
            aria-label="StartServer"
            disabled={
              disableStartServerStatusButton ||
              hasPurchasedRecently ||
              isErrorServerStatus
            }
            onClick={handleServerStatus}
          >
            <Stack alignItems="center" spacing={1}>
              {inputs.showLoaderForStartServer ? (
                <CircularProgress size={24} />
              ) : (
                <PlayArrowOutlined sx={{ fontSize: 24 }} />
              )}
              <Typography variant="body2">
                {t('advHostingServerInfo.startServer')}
              </Typography>
            </Stack>
          </ButtonAlt>

          <ButtonAlt
            aria-label="RebootServer"
            onClick={showRebootServerModal}
            disabled={
              disableServerStatusButtons ||
              hasPurchasedRecently ||
              isErrorServerStatus
            }
          >
            <Stack alignItems="center" spacing={1}>
              {inputs.showLoaderForRebootServer ? (
                <CircularProgress size={24} />
              ) : (
                <RefreshIcon color="action" sx={{ fontSize: 24 }} />
              )}
              <Typography variant="body2">
                {t('advHostingServerInfo.rebootServer')}
              </Typography>
            </Stack>
          </ButtonAlt>

          <ButtonAlt
            aria-label="StopServer"
            onClick={showPowerOffServerModal}
            disabled={
              disableStopServerStatusButton ||
              hasPurchasedRecently ||
              isErrorServerStatus
            }
          >
            <Stack alignItems="center" spacing={1}>
              {inputs.showLoaderForPowerOffServer ? (
                <CircularProgress size={24} />
              ) : (
                <PowerSettingsNewOutlined
                  color="action"
                  sx={{ fontSize: 24 }}
                />
              )}

              <Typography variant="body2">
                {t('advHostingServerInfo.powerOffServer')}
              </Typography>
            </Stack>
          </ButtonAlt>

          {systemConsoleData?.canShow && (
            <SsoLink
              type={systemConsoleData.type}
              queryParams={systemConsoleData.queryParams}
              variant="manageConsoleIconButton"
            ></SsoLink>
          )}
        </Stack>
      </Stack>

      <AdvancedHostingRebootModal
        openRebootModal={inputs.openRebootModal}
        handleClose={handleClose}
        handleRebootServer={handleRebootServer}
      />
      <AdvancedHostingPowerOffServerModal
        openPowerOffServerModal={inputs.openPowerOffServerModal}
        handleServerStatus={handleServerStatus}
        handleClose={handleClose}
      />
      <AdvancedHostingServerStatusModal
        openServerStatusSuccessModal={inputs.openServerStatusSuccessModal}
        handleSuccessfullModalClose={handleSuccessfulModalClose}
        showRebootSuccessfullTxt={inputs.showRebootSuccessfullTxt}
        showPowerOffSuccessfullTxt={inputs.showPowerOffSuccessfullTxt}
        showServerOnSuccessfullTxt={inputs.showServerOnSuccessfullTxt}
      />
    </>
  );
};

export default AdvancedHostingServerStatus;

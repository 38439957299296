import jwt_decode, { JwtPayload } from 'jwt-decode';

/*
Utility functions for extracting specific user information from a JWT
Example JWT:
{
  "sub": "urn:jarvis:bluehost:account:11111111",
  "aud": "QA",
  "act": {
    "sub": "urn:jarvis:bluehost:user:111111111",
    "role": "admin"
  },
  "nbf": 1674683807,
  "scope": "user-fe",
  "iss": "jarvis-jwt",
  "exp": 1674899807,
  "iat": 1674683807
}
*/

// position of the brand should always be the third item in the sub when split by ':'
export const getBrandFromSub = (sub: string | undefined) => {
  if (!sub || typeof sub !== 'string') return undefined;
  return sub.split(':')[2];
};

export const extractJWTBrand = (accessToken: string) => {
  const { sub }: JwtPayload = jwt_decode(accessToken);
  return getBrandFromSub(sub);
};

export const extractJWTAud = (accessToken: string) => {
  const { aud }: JwtPayload = jwt_decode(accessToken);
  return aud;
};

const VALID_PROD_ENVIRONMENTS = ['prod', 'production'];

// determine if prod by token
export const getIsUserEnvProd = (accessToken: string) => {
  const jwtAud = extractJWTAud(accessToken);
  if (!jwtAud || typeof jwtAud !== 'string') return false;
  const jwtAudLower = jwtAud.toLowerCase();

  return VALID_PROD_ENVIRONMENTS.includes(jwtAudLower);
};

// determine if prod by env
export const getIsUserEnvProdByEnv = (userEnvironment: string) => {
  if (!userEnvironment) return false;
  return VALID_PROD_ENVIRONMENTS.includes(userEnvironment);
};

import { useHostingAccount } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import { useBrandInfo } from '~/hooks/useBrandInfo';

export const ONLINE_STORE_PLAN_CODES = [
  'BH_ECOMM_STORE',
  'BH_ECOMM_STORE_MKTPL',
];

const useHostingPlanDetails = (accountId: string) => {
  const { brandFromJWT } = useBrandInfo();
  const isBlueHost = brandFromJWT.toLowerCase() === 'bluehost';

  const {
    // @ts-expect-error
    data: { hostingId, hostingData },
  } = useMFEContext();

  const { data: hostingAccount, isLoading } = useHostingAccount(accountId, {
    query: {
      enabled: !!accountId,
    },
  });

  const productName =
    hostingAccount?.data?.billing?.product_name || hostingData?.productName;
  const productCode = hostingAccount?.data?.billing?.prod_code ?? '';
  const isOnlineStorePlan = ONLINE_STORE_PLAN_CODES.includes(productCode);
  const isBluehostOnlineStorePlan = isBlueHost && isOnlineStorePlan;

  return {
    isBlueHost,
    productName,
    productCode,
    isOnlineStorePlan,
    isBluehostOnlineStorePlan,
    isLoading,
    hostingId,
  };
};

export default useHostingPlanDetails;

import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// @ts-ignore
import unionVector from '~/scenes/Sites/assets/placeholders/union-vector.svg';

type SitesEmptyPropOptions = {
  showAll?: boolean;
  noSites?: boolean;
  emptyListCopy?: string;
  handleAddSite: VoidFunction;
};

export default function SitesEmpty({
  showAll = false,
  noSites = false,
  emptyListCopy = undefined,
  handleAddSite,
}: SitesEmptyPropOptions) {
  const { t } = useTranslation('lists');

  if (showAll)
    return (
      <Stack alignItems="center" spacing={2}>
        <CardMedia
          component="img"
          image={unionVector}
          alt="Union Vector"
          sx={{ width: 82, height: 70 }}
        />
        <Typography>{t('', { type: 'websites' })}</Typography>
        {noSites && (
          <LoadingButton variant="contained" onClick={handleAddSite}>
            {t('addFirstWebsite')}
          </LoadingButton>
        )}
      </Stack>
    );

  return (
    <Card>
      <CardContent>{emptyListCopy}</CardContent>
    </Card>
  );
}

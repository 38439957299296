import { func, node, oneOfType, shape, string } from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import DevMode, {
  isDevMode as isDevOnlyMode,
  isTestMode,
} from '~/components/DevMode';
import MFEBootstrapTool from '~/components/MFEBootstrapTool';
import useLocalStorage from '~/hooks/useLocalStorage';
import { extractJWTBrand } from '~/utils/extractJWTInfo';
import { getHostingRenewalStatus } from '~/utils/hosting-renewal-status';

import AnalyticsProvider from './Analytics';
import ErrorBoundary from './ErrorBoundary';
import HttpClient from './HttpClient';
import i18n from './I18N';
import MUI from './MUI';
import ReactRouterDom from './ReactRouterDom';
import ReactSuspense from './ReactSuspense';

const MFEContext = createContext({});
export const useMFEContext = () => useContext(MFEContext);

const propTypes = {
  children: oneOfType([node, func]).isRequired,
  basename: string, // TODO: this may be required
  errorBoundaryConfig: shape({}),
  reactQueryConfig: shape({}),
  muiConfig: shape({}),
  data: shape({}),
  callbacks: shape({}),
};

export const MFEProvider = ({
  children,
  basename = '',
  errorBoundaryConfig = undefined,
  reactQueryConfig = undefined,
  muiConfig = undefined,
  hasAnalytics = true,
  data = {},
  callbacks = {},
}) => {
  const { t } = useTranslation('common');
  // Leave this useState in MFEProvider
  const [accessToken, setAccessToken] = useState(data?.accessToken);

  /**
   * brand as encoded in jwt token
   */
  let brandFromJWT = extractJWTBrand(accessToken);
  if (brandFromJWT === 'webhostbox') {
    brandFromJWT = data?.brand ?? brandFromJWT;
  }

  /**
   * formatted brand name, ex: "Bluehost" or "Network Solutions"
   */
  const brand = t(`brand.${brandFromJWT}`, {
    defaultValue: '', // if no brand value is found in translation then we return an empty string
  });

  // in order to avoid prop-drilling as soon as we receive these props, they get put into the context
  // and they all will be accessible from any component within the MFE application
  const providerData = useMemo(
    () => ({
      accessToken, // local state
      brand,
      brandFromJWT,
      data,
      callbacks,
      hostingId: data?.hostingId,
      hostingStatus: {
        renewalStatus: getHostingRenewalStatus(data?.hostingData?.expiryDate),
        isActive: data?.hostingData?.status?.toLowerCase() === 'active',
        isProvisioning:
          data?.hostingData?.status?.toLowerCase() === 'provisioning',
      },
    }),
    [accessToken, brand, brandFromJWT, callbacks, data],
  );

  const isDevMode = isDevOnlyMode && !isTestMode;
  const localStorageKey = `HOST_APP_PROPS`;
  const [lsHostAppData, setLSHostAppData] = useLocalStorage(
    localStorageKey, // local storage key
    providerData, // local storage value
    isDevMode, // enables/disables local storage setter
  );

  // Only use local storage values if in dev mode
  // Production always uses the passed in providerData
  const mfeProviderData = useMemo(
    () =>
      isDevMode
        ? {
            ...lsHostAppData,
            callbacks: {
              ...providerData?.callbacks,
              refreshAccessToken: async () => lsHostAppData?.accessToken,
            },
          }
        : providerData,
    [isDevMode, lsHostAppData, providerData],
  );

  return (
    <I18nextProvider i18n={i18n}>
      <DevMode>
        {/* This tool allows you to change the bootstrap props values without needing to restart the server */}
        <MFEBootstrapTool
          formData={lsHostAppData}
          setFormData={setLSHostAppData}
          defaultFormData={providerData}
        />
      </DevMode>
      <MFEContext.Provider
        value={mfeProviderData}
        style={{ backgroundColor: 'inherit' }}
      >
        <MUI theme={muiConfig?.theme} selector={muiConfig?.selector}>
          <ErrorBoundary
            onError={errorBoundaryConfig?.onError}
            ErrorScene={errorBoundaryConfig?.ErrorScene}
            hideOnError={errorBoundaryConfig?.hideOnError}
          >
            <HttpClient
              disabledReactQueryDevTools={
                reactQueryConfig?.disabledReactQueryDevTools
              }
              reactQueryDevToolsConfig={
                reactQueryConfig?.reactQueryDevToolsConfig
              }
              apiBaseUrl={mfeProviderData?.data?.apiBaseUrl}
              accessToken={mfeProviderData?.accessToken}
              refreshAccessToken={
                mfeProviderData?.callbacks?.refreshAccessToken
              }
              setAccessToken={setAccessToken} // sets state
              appEnv={data?.appEnv}
            >
              <ReactSuspense>
                <ReactRouterDom basename={basename}>
                  <AnalyticsProvider enabled={hasAnalytics}>
                    {children}
                  </AnalyticsProvider>
                </ReactRouterDom>
              </ReactSuspense>
            </HttpClient>
          </ErrorBoundary>
        </MUI>
      </MFEContext.Provider>
    </I18nextProvider>
  );
};

MFEProvider.propTypes = propTypes;
export default MFEProvider;

import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

type CopyPropOptions = {
  copyValue?: string;
  disable?: boolean;
  icon?: ReactElement;
};
const Copy = ({
  children = undefined,
  copyValue = undefined,
  disable = false,
  icon = undefined,
}: PropsWithChildren<CopyPropOptions>) => {
  const { t } = useTranslation('site', { keyPrefix: 'Copy' });
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const findCopy = copyValue || { children };
    try {
      navigator.clipboard.writeText(findCopy as string);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="baseline"
      spacing={{ sm: 1 }}
    >
      {children}
      <Tooltip open={copied} title={t('copiedMsg')} placement="top-end">
        <Button
          sx={{ minWidth: icon ? 0 : '40px' }}
          disabled={disable}
          onClick={handleCopy}
          data-testid="copy-btn"
        >
          <>{icon ? icon : t('copyBtn')}</>
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default Copy;

import Info from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';

type VirtualizedTableHeaderOptions = {
  headCells: {
    id: string;
    label?: string;
    sort?: boolean;
    toolTip?: string;
  }[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  handleRequestSort?: Function;
};

export default function VirtualizedTableHeader({
  headCells,
  order = 'asc',
  orderBy = '',
  handleRequestSort = () => {},
}: VirtualizedTableHeaderOptions) {
  return (
    <TableRow>
      {headCells?.map((headCell: any) => (
        <TableCell
          variant="head"
          width={headCell.width}
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          {headCell.sort && headCell.label ? (
            <TableSortLabel
              active={headCell.id === orderBy}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={handleRequestSort(headCell.id)}
              data-testid={`${headCell.id}-sort-btn`}
            >
              <Stack direction="row" spacing={4} alignItems="center">
                {headCell.label}
                {headCell?.toolTip && (
                  <Tooltip
                    title={headCell.toolTip}
                    arrow
                    placement="top-start"
                    sx={{ padding: '1px' }}
                  >
                    <Info color={'info'} fontSize="small" />
                  </Tooltip>
                )}
              </Stack>
            </TableSortLabel>
          ) : (
            <Stack direction="row" spacing={4} alignItems="center">
              {headCell.label}
              {headCell?.toolTip && (
                <Tooltip
                  title={headCell.toolTip}
                  arrow
                  placement="top-start"
                  sx={{ padding: '1px' }}
                >
                  <Info color={'info'} fontSize="small" />
                </Tooltip>
              )}
            </Stack>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

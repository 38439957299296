import { useHostingOnboardingData } from '@newfold/huapi-js';

import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';

import useAccount from './useAccount';

// NOTE: Hooks will be converted to typescript in the very near future
const useOnboarding = (enabled = true, fetchSite = true) => {
  const { id: hostingId } = useAccount();

  const { data: onboardingData, isPending } = useHostingOnboardingData(
    hostingId,
    {
      query: {
        enabled: !!hostingId && enabled,
        gcTime: 5000,
        staleTime: 5000,
      },
    },
  );

  const onboarding = onboardingData?.data;
  const hasOnboarding = typeof onboarding?.completed === 'boolean';
  const { data: hostingSites } = useFilteredHostingSites(
    hostingId,
    {},
    {
      query: {
        enabled: fetchSite && hasOnboarding && !!hostingId,
      },
    },
  );
  const sites = hostingSites?.data?.items;
  const numberOfSites = hostingSites?.data?.items?.length;

  const firstSite = sites?.find(
    ({ type, docroot }) =>
      /*
        This will likely remain a reliable check for a while longer, but
        worth noting that this is making a pretty big assumption
      */
      type?.toLowerCase() === 'wordpress' && docroot === 'public_html',
  );

  return {
    onboarding,
    firstSiteId: firstSite?.id,
    firstSite,
    numberOfSites,
    isPending,
    hasOnboarding,
  };
};

export default useOnboarding;

import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomRouteListener = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.Hosting.router = {
      navigate,
    };
    window.Hosting.routes = {
      hosting: '/',
      sites: '/sites',
      email: '/email',
    };
  }, [navigate]);

  return <>{children}</>;
};

export default CustomRouteListener;

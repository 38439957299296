import { useTranslation } from 'react-i18next';

import CloudOutlined from '@mui/icons-material/CloudOutlined';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';

import { useHostingAccount, useHostingSitesV2 } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import { useNewfoldBrandTheme } from '~/components/MFEProvider/lib/MUI/themeDefault';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useResponsive from '~/hooks/useResponsive';

import AllocationLinearProgress from './components/AllocationLinearProgress';
import AllocationText from './components/AllocationText';
import { PhpWorkersTooltip } from './components/PhpWorkersTooltip';
import PlanName from './components/PlanName';
import Sites from './scenes/Sites';

export default function AtomicHostingOverview() {
  const { t } = useTranslation('cloud', { keyPrefix: 'hosting' });
  // const navigate = useNavigate();

  const { isMobile: getIsMobile } = useResponsive();
  const isMobile = getIsMobile();

  const themeDark = useNewfoldBrandTheme('dark');

  // @ts-ignore
  const { hostingId, brand, data: mfeData } = useMFEContext();
  const { domainName, path } = useBrandInfo();
  const expiryDate = mfeData?.hostingData?.expiryDate;
  const hasAutoRenew = mfeData?.hostingData?.hasAutoRenew;

  const {
    data: hostingAccountData,
    // isPending: isPendingHostingInfo
  } = useHostingAccount(hostingId, {
    query: {
      enabled: !!hostingId,
      select: ({ data }) => data,
    },
  });
  const storage = hostingAccountData?.resources?.storage;
  const workers = hostingAccountData?.resources?.workers;

  const {
    data: hostingSitesData,
    // isPending
  } = useHostingSitesV2(
    hostingId,
    {},
    {
      query: {
        enabled: !!hostingId,
        select: ({ data }) => data,
      },
    },
  );

  // sx
  const graphCellStyles = {
    minWidth: { xs: 0, md: 125, lg: 140 },
    width: '100%',
  };

  const kbLink = `https://${domainName}/help/results?c=${path}`;

  return (
    <>
      {/* Overview */}
      <ThemeProvider theme={themeDark}>
        <Card elevation={0}>
          <CardContent sx={{ p: 3 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              justifyContent={'space-between'}
              alignContent={'stretch'}
              alignItems={{ md: 'center' }}
            >
              <Stack spacing={1}>
                <Typography variant="body2">
                  {/* TODO */}
                  <b>
                    {brand} {t('overview.cloud')}
                  </b>
                </Typography>
                {/* hiding upgrade until CTB is implemented */}
                <PlanName />
                <Typography variant="body2">
                  {/* TODO */}
                  <i>
                    {hasAutoRenew
                      ? t('overview.renews')
                      : t('overview.expires')}{' '}
                    {expiryDate}
                  </i>
                </Typography>
              </Stack>

              <Paper
                variant="outlined"
                elevation={0}
                sx={{
                  // flex: { sm: 1, md: undefined },
                  // backgroundColor: 'transparent',
                  // height: '100%',
                  // padding: '1rem 3rem',
                  textAlign: { xs: 'left', md: 'center' },
                }}
              >
                {/* I put a Box here while we wait for the resources page to be built, 
                  the Box can be removed in place of the ActionArea */}
                <Box sx={{ px: { xs: 1, md: 4 }, py: { xs: 1, md: 2 } }}>
                  {/* <CardActionArea
                  onClick={() => navigate('./resources')}
                  sx={{ px: { xs: 1, md: 4 }, py: { xs: 1, md: 2 } }}
                > */}
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 3 }}
                    alignContent={'center'}
                    alignItems={'center'}
                    sx={{ p: 1 }}
                  >
                    <Stack spacing={1} flex={3} sx={graphCellStyles}>
                      <Typography variant="body2">
                        {t('overview.sitesUsed')}
                      </Typography>
                      <AllocationLinearProgress
                        used={hostingSitesData?.used_sites}
                        total={hostingSitesData?.max_sites}
                      />
                      <AllocationText
                        variant="body1"
                        fontWeight={700}
                        used={hostingSitesData?.used_sites}
                        total={hostingSitesData?.max_sites}
                      />
                    </Stack>

                    <Divider
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem
                    />

                    {/* WORKERS */}
                    <Stack spacing={1} flex={3} sx={graphCellStyles}>
                      <Typography variant="body2">
                        <Stack
                          component="span"
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <span>{t('overview.phpWorkers')}</span>
                          <PhpWorkersTooltip color="info" />
                        </Stack>
                      </Typography>
                      <AllocationLinearProgress
                        used={workers?.used}
                        total={workers?.total}
                      />
                      <AllocationText
                        variant="body1"
                        fontWeight={700}
                        used={workers?.used}
                        total={workers?.total}
                      />
                    </Stack>

                    <Divider
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem
                    />

                    {/* STORAGE */}
                    <Stack spacing={1} flex={3} sx={graphCellStyles}>
                      <Typography variant="body2">
                        {t('overview.storageSpace')}
                      </Typography>
                      <AllocationLinearProgress
                        used={storage?.used}
                        total={storage?.total}
                      />
                      <AllocationText
                        variant="body1"
                        fontWeight={700}
                        used={storage?.used}
                        total={storage?.total}
                        unit={storage?.unit}
                      />
                    </Stack>

                    {/*
                    {isMobile && (
                      <Divider orientation={'horizontal'} flexItem />
                    )}

                    <Typography variant="body1">
                    <Stack
                      alignItems={'center'}
                      spacing={1}
                      direction={'row'}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {isMobile && <span>Manage Resources</span>}{' '}
                      <ArrowForwardIos fontSize="inherit" />
                    </Stack>
                  </Typography> */}
                  </Stack>
                  {/* </CardActionArea> */}
                </Box>
              </Paper>
            </Stack>
          </CardContent>
        </Card>
      </ThemeProvider>

      {/* Knowledgebase */}
      <Card
        elevation={0}
        variant="outlined"
        sx={{ backgroundColor: '#f2f5f7' }}
      >
        <CardActionArea href={kbLink} target="_blank" rel="noopener noreferrer">
          <CardContent>
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
              {/* Icon */}
              <CloudOutlined color="primary" fontSize={'large'} />

              <Stack
                spacing={1}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={{ xs: 'flex-start', md: 'center' }}
              >
                {/* Main Copy */}
                <span>
                  <Typography variant="h3">
                    {t('overview.kbArticle.title')}
                  </Typography>
                  <Typography variant="body2">
                    {t('overview.kbArticle.desc')}
                  </Typography>
                </span>

                {/* KB Link CTA */}
                <Typography variant="body2" justifySelf={'flex-end'}>
                  <Link href={kbLink} target="_blank" rel="noopener noreferrer">
                    <Stack
                      component="span"
                      alignItems={'center'}
                      direction={'row'}
                      spacing={1}
                    >
                      <span>{t('overview.kbArticle.link')}</span> <OpenInNew />
                    </Stack>
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>

      {/* Sites */}
      <Sites />
    </>
  );
}

import {
  AccountEventListQueryResult,
  useAccountEventList,
} from '@newfold/huapi-js';

import { accountEventsQueryKey } from '~/components/MFEProvider/lib/HttpClient/customQueryConfig';
import { queryClient } from '~/components/MFEProvider/lib/HttpClient/queryClient';

import useOnboarding from './useOnboarding';

const useAccountEvents = () => {
  const { isPending, firstSite, numberOfSites } = useOnboarding();
  const onboardingCompleted = firstSite?.onboarding === 1;

  const queryKey = accountEventsQueryKey;
  const eventQueryData: AccountEventListQueryResult | undefined =
    queryClient.getQueryData(queryKey);
  const eventDateLast = eventQueryData?.data?.utc;

  const eventsQueryResult = useAccountEventList(
    {
      date_last: eventDateLast,
    },
    {
      query: {
        queryKey,
        enabled: !isPending && !!firstSite && numberOfSites === 1,
        refetchInterval: !!onboardingCompleted ? 0 : 30000,
      },
    },
  );

  return eventsQueryResult;
};

export default useAccountEvents;

// Remove SX and Style Prop
import { SnackbarProvider } from 'notistack';

import Portal from '@mui/material/Portal';

import alertTemplate from '~/components/Alerts/AlertTemplate';
import TenantProvider from '~/components/TenantProvider';

import QueryFeatureAlertBar from './components/Alerts/QueryFeatureAlertBar';
import Main from './components/Main/index.tsx';
import MainRoutes from './MainRoutes';

const App = () => {
  return (
    <TenantProvider>
      <Portal>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          Components={{ alertTemplate }}
        >
          <QueryFeatureAlertBar />
        </SnackbarProvider>
      </Portal>
      <Main>
        <MainRoutes />
      </Main>
    </TenantProvider>
  );
};

export default App;

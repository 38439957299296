import { useTranslation } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { HostingAdvancedSsoWhm200SslSetup } from '@newfold/huapi-js/src/index.schemas';

import ResponsiveDialog, {
  StyledButton,
  StyledLoadingButton,
} from '~/components/ResponsiveDialog';

interface DNSZoneDialogProps {
  open: boolean;
  onClose: () => void;
  onPrimaryAction: (isDNSZoneWithUS: boolean) => void;
  SSLData: HostingAdvancedSsoWhm200SslSetup;
  isPending?: boolean;
  showError?: boolean;
}

interface RenderDNSZoneNotWithUsProps {
  serverHostname: string;
  serverIP: string;
}
interface RenderDNSZoneContentProps {
  dnsZoneWithUs: boolean;
  serverHostname: string;
  serverIP: string;
}

const RenderDNSZoneNotWithUs = ({
  serverHostname,
  serverIP,
}: RenderDNSZoneNotWithUsProps) => {
  const { t } = useTranslation('quickLinks', { keyPrefix: 'dnsZoneDialog' });
  return (
    <Stack spacing={2}>
      <Typography>
        {t('contentNotWithUs', {
          hostName: serverHostname,
          serverIP: serverIP,
        })}
      </Typography>
      <Typography>{t('contentNotWithUsDesc')}</Typography>
      <List>
        <ListItem>
          <Typography>
            <strong>{t('host')}:</strong> {serverHostname}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>{t('type')}:</strong> A
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>{t('value')}:</strong> {serverIP}
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
};
const RenderDNSZoneContent = ({
  dnsZoneWithUs,
  serverHostname,
  serverIP,
}: RenderDNSZoneContentProps) => {
  const { t } = useTranslation('quickLinks', { keyPrefix: 'dnsZoneDialog' });
  return dnsZoneWithUs ? (
    <Stack spacing={2}>
      <Typography>
        {t('content', {
          hostName: serverHostname,
          serverIP: serverIP,
        })}
      </Typography>
      <Typography>
        {t('contentDesc', {
          hostName: serverHostname,
        })}
      </Typography>
    </Stack>
  ) : (
    <RenderDNSZoneNotWithUs
      serverHostname={serverHostname}
      serverIP={serverIP}
    />
  );
};
const DNSZoneDialog = ({
  open,
  onClose,
  onPrimaryAction,
  SSLData,
  isPending = false,
  showError = false,
}: DNSZoneDialogProps) => {
  const { t } = useTranslation('quickLinks', { keyPrefix: 'dnsZoneDialog' });
  if (!SSLData) return null;
  const { dns_zone_with_us, server_hostname, server_ip } = SSLData;
  const isDNSZoneWithUS = dns_zone_with_us === 1;
  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dns-zone-dialog"
    >
      <DialogTitle data-testid="dns-zone-dialog-title">
        {t('title')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <RenderDNSZoneContent
          dnsZoneWithUs={isDNSZoneWithUS}
          serverHostname={server_hostname!}
          serverIP={server_ip!}
        />
        {showError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {t('error')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} color="secondary" sx={{ mr: 2 }}>
          {t('secondaryAction')}
        </StyledButton>
        <StyledLoadingButton
          onClick={() => onPrimaryAction(isDNSZoneWithUS)}
          color="primary"
          variant="contained"
          disabled={isPending || showError}
          loading={isPending}
        >
          {isDNSZoneWithUS ? t('updateDNS') : t('installSSL')}
        </StyledLoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  );
};

export default DNSZoneDialog;

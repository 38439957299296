/*
  SsoLink

    This is a component that can be used to generate a clickable button that
    opens a new browser tab to log the user in to another service, such as
    SiteLock or cPanel, associated with the customer's account.

    Each type has a function to fetch that particular type of sso link and the
    specific function actually called depends on the type parameter.  To implement
    a new type, define a new function to go with it.

  Properties:

    type: Should be one of "site", "hosting", "whm", or "sitelock" depending on which
      kind of sso link is needed.  New types may be defined as needed.
    queryParams: should be like: { app: 'someAppReference', } plus specific arguments 
      if needed to be.
      This is specific to the type of sso link being generated.
      For current types, we are making calls to huapi to get the sso link and the
      queryParams are passed to the huapi call.
    fluid, variant: These parameters set CSS properties for the button that is displayed.
    onAction: Optional.  A function to call when an SSO link iss successfully retrieved and opened.
    props: may contain custom data passed to the SsoLink component.  For example,
      the addonid is passed this way for SiteLock. Note that these will appear as properties
      on the HTML button.

  State:

    isPending: Boolean to control whether or not the button should display a loading spinner.

*/
import { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import OpenInNew from '@mui/icons-material/OpenInNew';
import Terminal from '@mui/icons-material/Terminal';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  useAddonsSitelockSsoV2,
  useAddonsSpamexpertsSso,
  useHostingAdvancedServer,
  useHostingAdvancedSsoWhm,
  useHostingAdvancedSystemConsole,
  useHostingResellerSsoWhm,
  useSitesSso,
  useSso,
} from '@newfold/huapi-js';
import {
  AddonsSitelockSsoV2Params,
  SitesSsoBounceName,
  SitesSsoParams,
  SsoApp,
  SsoParams,
} from '@newfold/huapi-js/src/index.schemas';

import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useHostingInfo from '~/hooks/useHostingInfo';
import ButtonAlt from '~/scenes/Hosting/scenes/HostingDetail/components/AdvancedHosting/components/utils/ButtonAlt';
import { useSiteContext } from '~/scenes/Site';
import { ButtonVariantPropTypes } from '~/types/mui';

import useAlerts from '../Alerts/alertsStore';

const SITE = 'site';
const HOSTING = 'hosting';
const SPAMEXPERTS = 'spamexperts';
const VPS = 'vps';
const SITELOCK = 'sitelock';
const PLESK = 'plesk';
const WINDOWS = 'windows';
const RESELLER = 'reseller';

export type SsoTypeTypes =
  | 'site'
  | 'hosting'
  | 'spamexperts'
  | 'vps'
  | 'sitelock'
  | 'plesk'
  | 'windows'
  | 'reseller';

export type SsoQueryParamsTypes = (SitesSsoParams &
  AddonsSitelockSsoV2Params) & {
  dir?: SsoParams['dir'];
  domain?: SsoParams['domain'];
  db_name?: SsoParams['db_name'];
  app?:
    | SsoApp
    | SitesSsoBounceName
    | 'whm'
    | 'resellerwhm'
    | 'systemconsole'
    | 'systemConsoleSso';
};

export type SsoVariantTypes =
  | ButtonVariantPropTypes
  | 'manageConsoleIconButton'
  | 'menuItem'
  | 'textLink';

export type SsoLinkPropOptions = {
  type?: SsoTypeTypes;
  queryParams?: SsoQueryParamsTypes;
  ssoQueryParams?: string;
  fluid?: boolean;
  variant?: SsoVariantTypes;
  userId?: number;
  addonId?: number | string;
  disabled?: boolean;
  /** @onAction calls after successfully retrieving the sso url */
  onAction?: VoidFunction;
  id?: string | number;
  siteType?: string;
  hostingId?: string | number;
  advHostingQuickLink?: boolean;
};

const SsoLink = ({
  children = undefined,
  type = SITE,
  queryParams = {},
  ssoQueryParams = '',
  fluid = false,
  variant = 'outlined',
  userId = undefined,
  addonId = undefined,
  onAction = undefined,
  id = undefined,
  disabled = undefined,
  siteType = 'wordpress',
  hostingId = undefined,
  advHostingQuickLink = false,
  ...props
}: PropsWithChildren<SsoLinkPropOptions>) => {
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();
  const { siteId: paramsSiteId } = useParams();
  const siteId = id || paramsSiteId;

  const { isDisabled: hostingDisabled } = useHostingInfo({
    hostingId,
    options: {
      enabled: !!hostingId && disabled === undefined,
    },
  });

  const { isDisabled: siteDisabled } = useSiteContext();

  const isDisabled = useMemo(() => {
    if (disabled !== undefined) return disabled;
    return hostingDisabled || siteDisabled;
  }, [disabled, hostingDisabled, siteDisabled]);

  const [isPending, setIsPending] = useState(false);

  const { brandFromJWT: brand } = useBrandInfo();
  const { id: accountId } = useAccount();

  const hookOptions = {
    query: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  };

  //get server status
  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      ...hookOptions.query,
      enabled: type === VPS,
    },
  });
  const serverStatus = serverData?.data?.status;

  //ctb upgrade in progress check.
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  //site sso
  const siteSso = useSitesSso(
    Number(siteId),
    {
      ...(queryParams && ({ bounce_name: queryParams?.app } as SitesSsoParams)),
      ...(userId && { user_id: String(userId) }),
      ...(addonId && { addon_id: String(addonId) }),
    },
    hookOptions,
  );

  //hosting sso
  const hostingSso = useSso(accountId, queryParams as SsoParams, hookOptions);

  //spam experts sso
  const spamExpertsSso = useAddonsSpamexpertsSso(String(addonId), hookOptions);

  //whm sso
  const whmSso = useHostingAdvancedSsoWhm(accountId, {
    query: {
      ...hookOptions.query,
      enabled: type === VPS && queryParams?.app === 'whm',
    },
  });

  //reseller whm  sso
  const resellerWhmSso = useHostingResellerSsoWhm(accountId, hookOptions);

  //reseller cpannel sso
  const resellerCPanel = useSso(accountId, {}, hookOptions);

  //system console sso
  const systemConsoleSso = useHostingAdvancedSystemConsole(accountId, {
    query: {
      ...hookOptions.query,
      enabled: type === VPS && queryParams?.app === 'systemConsoleSso',
    },
  });

  // GET: sitelock sso
  const sitelockSso = useAddonsSitelockSsoV2(
    Number(addonId),
    queryParams,
    hookOptions,
  );

  const getSsoByType = (type: SsoTypeTypes) => {
    switch (type) {
      case VPS:
        //TODO: this will be updated later to have one sso call for whm and systemconsole.
        return queryParams?.app === 'whm' ? whmSso : systemConsoleSso;
      case SITE:
        return siteSso;
      case HOSTING:
      case PLESK:
      case WINDOWS:
        return hostingSso;
      case SPAMEXPERTS:
        return spamExpertsSso;
      case SITELOCK:
        return sitelockSso;
      case RESELLER:
        return queryParams?.app === 'resellerwhm'
          ? resellerWhmSso
          : resellerCPanel;
      default:
        return hostingSso;
    }
  };

  const sso = getSsoByType(type);

  const { refetch } = sso;

  // Actually open the window
  const redirectToSsoUrl = async () => {
    const siteSsoLoadingPage =
      brand === 'bluehost'
        ? `${process.env.PUBLIC_URL}/loading/${siteType}-${brand}.html`
        : `${process.env.PUBLIC_URL}/loading/${siteType}.html`;
    const cPanelSsoLoadingPage = `${process.env.PUBLIC_URL}/sso.html`;
    var ssoWindow = window.open(
      type === SITE ? siteSsoLoadingPage : cPanelSsoLoadingPage,
      '_blank',
    );
    setIsPending(true);

    const { data, error } = await refetch();

    if (error) {
      // close the window
      ssoWindow?.close();
      setIsPending(false);
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    // @ts-expect-error
    const url = data?.data?.url || data?.data?.sso;

    if (url && !!ssoWindow) {
      // set url for window
      ssoWindow.location = url + ssoQueryParams;

      setIsPending(false);
      if (onAction) {
        onAction();
      }
      return;
    }

    ssoWindow?.close();

    // TODO: need some copy from UX
    generateAlert({
      severity: 'error',
      description: t('ssoLink.notFound'),
      showCloseBtn: true,
    });
    setIsPending(false);
  };

  //Disable quicklinks vps/dedi based on server status or ctb upgrade status.
  const quickLinksDisabled = () => {
    //systemconsole.
    if (queryParams?.app === 'systemconsole') {
      const serverStates = ['active'];
      if (!serverStates.includes(serverStatus!) || hasPurchasedRecently) {
        return true;
      }
    }
    //whm.
    if (queryParams?.app === 'whm') {
      const serverStates = ['active'];
      if (!serverStates.includes(serverStatus!) || hasPurchasedRecently) {
        return true;
      }
    }

    return false;
  };

  if (variant === 'manageConsoleIconButton')
    return (
      <ButtonAlt
        aria-label="MangeConsole"
        onClick={() => redirectToSsoUrl()}
        disabled={isDisabled || quickLinksDisabled()}
      >
        <Stack alignItems="center" spacing={1}>
          <Terminal color="action" sx={{ fontSize: 24 }} />
          <Typography variant="body2">{t('manageConsole')}</Typography>
        </Stack>
      </ButtonAlt>
    );

  if (advHostingQuickLink)
    return (
      <LoadingButton
        endIcon={<OpenInNew />}
        loading={isPending}
        variant={variant as ButtonVariantPropTypes}
        color="primary"
        onClick={() => redirectToSsoUrl()}
        disabled={isDisabled || quickLinksDisabled()}
      >
        {children}
      </LoadingButton>
    );

  if (variant === 'menuItem')
    return (
      <MenuItem
        onClick={() => redirectToSsoUrl()}
        disabled={isDisabled}
        {...props}
      >
        {isPending ? <CircularProgress size={20} /> : children}
      </MenuItem>
    );

  if (variant === 'textLink')
    return (
      // @ts-expect-error
      <Link
        target="_blank"
        rel="noopener noreferrer"
        data-testid={`sso-link-${siteId}`}
        component="text"
        onClick={() => redirectToSsoUrl()}
        aria-disabled={isDisabled}
        {...props}
      >
        {children}
      </Link>
    );

  return (
    <LoadingButton
      data-testid={`sso-button-${siteId}`}
      loading={isPending}
      variant={variant}
      color="primary"
      sx={{ width: fluid ? '100%' : undefined }}
      onClick={() => redirectToSsoUrl()}
      disabled={isDisabled || quickLinksDisabled()}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default SsoLink;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * PageChangeTracker component tracks page views using FullStory.
 *
 * This component uses the `useLocation` hook from `react-router-dom` to get the current location
 * and the `useEffect` hook to trigger a side effect whenever the location changes.
 *
 * When the location changes, it checks if the FullStory (`FS`) object and its `event` method are available
 * on the `window` object. If they are, it logs a 'Page View' event with the current pathname.
 *
 * FullStory is a digital experience analytics platform that helps you understand and improve
 * your users' experience by recording and analyzing user interactions on your website.
 *
 * @returns {null} This component does not render any UI.
 */
function PageChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.FS && window.FS.event) {
      window.FS.event('Page View', { page: location.pathname });
    }
  }, [location]);

  return null;
}

export default PageChangeTracker;

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';

import {
  getHostingAccountQueryKey,
  getHostingSitesV2QueryKey,
  getSitesInfoQueryKey,
  getSitesListV2QueryKey,
  SitesInfoQueryResult,
} from '@newfold/huapi-js';
import { HostingSitesV2Params } from '@newfold/huapi-js/src/index.schemas';

import { getIsDevMode, isDevMode, isTestMode } from '~/components/DevMode';
import { decodeToken, useJwtAutoRefresh } from '~/hooks/useJwtAutoRefresh';
import { EnvTypes } from '~/types/environment';

import { useMFEContext } from '../MFEProvider';
import {
  dehydrateOptions,
  invalidateMultipleQueries,
  persister,
  queryClient,
} from './queryClient';

type HttpClientPropOptions = {
  children?: ReactNode;
  disableReactQueryDevTools?: boolean;
  reactQueryDevToolsConfig?: any;
  apiBaseUrl?: string;
  accessToken: string;
  refreshAccessToken: () => Promise<string>;
  setAccessToken: (arg: string | void) => void;
  appEnv?: EnvTypes;
};

const HttpClient = ({
  children = undefined,
  disableReactQueryDevTools = false,
  reactQueryDevToolsConfig = undefined,
  apiBaseUrl = undefined,
  accessToken,
  refreshAccessToken,
  setAccessToken,
  appEnv = process.env.REACT_APP_ENV,
}: HttpClientPropOptions) => {
  const [ready, setReady] = useState(false);

  // @ts-expect-error
  const { hostingId } = useMFEContext();

  // AMM     - hosting_id = account_back_ref (i.e. WN.HP.123456)
  // HAL GUI - hosting_id = account_id       (i.e. 12345678)
  //
  // If bootstrapping the MFE through HAL GUI,
  // account_id is passed as the hosting_id rather than the account_back_ref.
  // Hosting calls within a site context use the account_back_ref as the hosting_id.
  // Since the hosting_id is different between the bootstrapped and site context hosting_id,
  // different caches will be stored for hosting calls (i.e. /hosting/12345678/sites vs /hosting/WN.HP.123456/sites).
  // Therefore, turn off staleTime
  const hasAccountBackRef = !!hostingId && String(hostingId)?.startsWith('WN');
  if (!hasAccountBackRef && !isTestMode) {
    const defaultQueryOptions = queryClient.getDefaultOptions();

    defaultQueryOptions.queries = {
      ...defaultQueryOptions.queries,
      staleTime: 0,
    };
    queryClient.setDefaultOptions(defaultQueryOptions);
  }

  const getApiBaseUrl = (
    env: EnvTypes | undefined,
    serverUrl: string | undefined,
  ) => {
    if (serverUrl) return serverUrl;
    switch (env) {
      case 'development':
        return 'https://hosting-beta.uapi.newfold.com';
      case 'beta':
        return 'https://hosting-beta.uapi.newfold.com';
      case 'staging':
        return 'https://hosting-alpha.uapi.newfold.com';
      default:
        return 'https://hosting.uapi.newfold.com';
    }
  };

  useEffect(() => {
    // Set initial header with token received from the instantiating app
    axios.defaults.headers = {
      // @ts-expect-error
      Authorization: `Bearer ${accessToken}`,
    };
    // NOTE: added to ensure CTB can fetch the latest token from localStorage
    localStorage.setItem('token', accessToken);
    // We only want this to run once, afterwards the hook handles updating the header
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isTokenExpired, refreshToken } = useJwtAutoRefresh(
    setAccessToken, // state setter
    refreshAccessToken, // refresh callback from AMM
  );

  // @ts-expect-error
  const { appTokenUserId, originatorUserId } = decodeToken(accessToken);
  // TODO: in the future we want to bust the cache of our local storage if the app updates to a new version
  // We could use for example REACT_APP_BUILD_HASH or some value that is unique per build, but we don't have this value today
  // buster = REACT_APP_BUILD_HASH, // TODO
  // Bust local storage cache if user id changes
  const buster = !!originatorUserId
    ? `${String(appTokenUserId)}:${originatorUserId}`
    : `${String(appTokenUserId)}`;

  // Automatically invalidates the associated query after mutate based on response query config url
  // Also invalidate sites, hosting sites, site info, and hosting info
  const handleQueryKeysToInvalidate = (url: string | undefined) => {
    const currentAPIQueryKey = [url];
    const siteListQueryKey = getSitesListV2QueryKey();
    const queryKeys: (
      | (string | undefined)[]
      | readonly [string]
      | readonly [string, ...HostingSitesV2Params[]]
    )[] = [currentAPIQueryKey, siteListQueryKey];

    const siteIdRe = /sites\/(\d+)/;
    const hostingIdRe = /hosting\/(\d+)/;

    // Extract the IDs
    const matchSiteId = url?.match(siteIdRe);
    const matchHostingId = url?.match(hostingIdRe);

    const siteId = !!matchSiteId ? matchSiteId[1] : null;
    const hostingId = !!matchHostingId ? matchHostingId[1] : null;

    // Invalidate site info and hosting calls related to site
    if (!!siteId) {
      const siteInfoQK = getSitesInfoQueryKey(Number(siteId));
      const siteInfo: SitesInfoQueryResult | undefined =
        queryClient.getQueryData(siteInfoQK);
      const accountBackRef = siteInfo?.data?.account_back_ref;
      const siteAccountId =
        !!accountBackRef && accountBackRef.startsWith('WN')
          ? siteInfo?.data?.account_back_ref
          : siteInfo?.data?.account_id;

      if (siteInfoQK[0] !== url) queryKeys.push(siteInfoQK);
      queryKeys.push(getHostingAccountQueryKey(String(siteAccountId)));
      // TODO: allow hosting id to be a string in huapi schema
      // @ts-expect-error
      queryKeys.push(getHostingSitesV2QueryKey(siteAccountId));
    }

    if (!!hostingId) {
      queryKeys.push(getHostingAccountQueryKey(hostingId));
      // @ts-expect-error
      queryKeys.push(getHostingSitesV2QueryKey(hostingId));
    }

    if (getIsDevMode(['development', 'beta', 'staging'])) {
      console.log('INVALIDATING QUERIES: ', queryKeys);
    }
    invalidateMultipleQueries(queryClient, queryKeys);
  };

  useEffect(() => {
    if (isTestMode) {
      setReady(true);
      return;
    }

    axios.defaults.baseURL = getApiBaseUrl(appEnv, apiBaseUrl);

    // This wasn't applying before our axios calls were being made. Using axios defaults for now.
    // const instance = axios.create({
    //   baseURL: getApiBaseUrl(appEnv),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   }
    // });

    // intercept bad tokens before they make huapi calls
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        // validate jwt has not expired before request is sent
        if (isTokenExpired(accessToken) === true) {
          // token is expired, so call the hook's refresh function manually
          const newToken = await refreshToken();

          axios.defaults.headers = {
            // @ts-expect-error
            Authorization: `Bearer ${newToken}`,
          };

          // Update the token for the *in-flight* request in
          // addition to the token for the default header
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newToken}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response?.config?.method === 'delete') {
          if (getIsDevMode(['development', 'beta', 'staging'])) {
            console.log('Interceptor Response: ', response);
          }
          queryClient.invalidateQueries();
          return response;
        }
        if (response?.config?.method !== 'get') {
          if (getIsDevMode(['development', 'beta', 'staging'])) {
            console.log('Interceptor Response: ', response);
          }
          const url = response?.config?.url;
          handleQueryKeysToInvalidate(url);
          return response;
        }
        return response;
      },
      async function (error) {
        const config = error?.config;
        if (error.code === 'ERR_CANCELED') {
          // When tabbing back and forth between the different Site tabs
          // we were seeing Cancel errors in the console which showed up in FullStory
          return Promise.resolve({ status: 499 });
        }
        if (error?.response?.status === 401 && !config._retry) {
          config._retry = true;
          const newToken = await refreshToken();

          // Update the token for the *in-flight* request in
          // addition to the token for the default header
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newToken}`,
          };
          return axios(config);
        }
        return Promise.reject(error);
      },
    );

    setReady(true);

    // Clean up interceptors at least with each new accessToken
    // to prevent spawning multiple concurrent interceptors
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      // Simply ejecting the interceptors doesn't clean up the lists, so
      // we should clear them afterward to prevent infinite growth
      // TODO: Figure out how to clean up interceptors that avoids ts errors
      // @ts-expect-error
      axios.interceptors.request.handlers = [];
      // @ts-expect-error
      axios.interceptors.response.handlers = [];
    };
  }, [accessToken, apiBaseUrl, appEnv, isTokenExpired, refreshToken]);

  if (isDevMode && !isTestMode) {
    console.log(
      'REACT_QUERY_OFFLINE_CACHE',
      window.JSON.parse(
        window.localStorage.getItem('REACT_QUERY_OFFLINE_CACHE')!,
      )?.clientState?.queries,
    );
  }

  if (isTestMode) {
    return (
      <QueryClientProvider client={queryClient}>
        {/* Delay children until axios is properly configured */}
        {ready && children}
        {!disableReactQueryDevTools && (
          <ReactQueryDevtools {...reactQueryDevToolsConfig} />
        )}
      </QueryClientProvider>
    );
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        buster,
        dehydrateOptions,
      }}
    >
      {/* Delay children until axios is properly configured */}
      {ready && children}
      {!disableReactQueryDevTools && (
        <ReactQueryDevtools {...reactQueryDevToolsConfig} />
      )}
    </PersistQueryClientProvider>
  );
};

export default HttpClient;

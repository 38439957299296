import { Trans, useTranslation } from 'react-i18next';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useResponsive from '~/hooks/useResponsive';

import { domainSetupCardSx } from '../utils/stepData';
import StatusChip from './StatusChip';

export type TempDomainInfoBoxPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  isPending?: boolean;
};

const TempDomainInfoBox = ({
  site,
  isPending = false,
}: TempDomainInfoBoxPropOptions) => {
  const { t } = useTranslation('domains', { keyPrefix: 'setupProgress' });
  const { isMobile: getIsMobile, isTablet: getIsTablet } = useResponsive();
  const isMobile = getIsMobile();
  const isTablet = getIsTablet();

  const currentUrl = site?.url!;
  const currentUrlIsProvisional = site?.url_is_provisional;
  const newDomainUrl = site?.pending_url;
  const domainsListHref = `/sites/${site?.id}/domains`;

  return (
    <Card
      data-testid="temp-domain-info-box"
      sx={{
        ...domainSetupCardSx,
        boxShadow: 'none',
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={1} justifyContent={'flex-start'}>
            <LightbulbOutlinedIcon />
            <Typography variant="body2">
              <Trans
                t={t}
                i18nKey="tempDomainInfoBox.noteBold"
                components={{ b: <b></b> }}
              />
              {currentUrlIsProvisional && (
                <> {t('tempDomainInfoBox.descriptionTemp')}</>
              )}{' '}
              {t('tempDomainInfoBox.descriptionProcess')}
            </Typography>
          </Stack>
          <Stack
            direction={isTablet ? 'column' : 'row'}
            spacing={isTablet ? 2 : 3}
            justifyContent={'flex-start'}
          >
            <Stack spacing={1}>
              <Typography variant={'body2'} fontWeight={600}>
                {t(
                  currentUrlIsProvisional
                    ? 'tempDomainInfoBox.tempDomainLabel'
                    : 'tempDomainInfoBox.currentDomainLabel',
                )}
              </Typography>
              <Typography
                variant={'body2'}
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {isPending ? (
                  <Skeleton width={100} />
                ) : (
                  <Link href={currentUrl} underline="always" target="_blank">
                    {currentUrl}
                    <OpenInNew
                      className="icon"
                      fontSize="inherit"
                      sx={{ ml: 0.5 }}
                    />
                  </Link>
                )}
              </Typography>
            </Stack>
            <Divider
              orientation={isTablet ? 'horizontal' : 'vertical'}
              variant="middle"
              flexItem
            />
            <Stack spacing={1}>
              <Typography variant={'body2'} fontWeight={600}>
                {t('tempDomainInfoBox.newDomainLabel')}
              </Typography>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                spacing={1}
                alignItems={isMobile ? 'normal' : 'center'}
              >
                <Typography
                  variant={'body2'}
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {isPending ? <Skeleton width={100} /> : newDomainUrl}
                </Typography>
                <StatusChip
                  label={'connecting'}
                  textColor={'warning.dark'}
                  backgroundColor={'warning.light'}
                  sx={{ maxWidth: '50%' }} // Prevents the chip from going fullWidth in column layout
                />
              </Stack>
              <Typography variant={'body2'}>
                <Link href={domainsListHref} underline="always" color="primary">
                  {t('tempDomainInfoBox.manageDomainsText')}
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TempDomainInfoBox;

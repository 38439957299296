import { OverridableStringUnion } from '@mui/types';

export const JARVIS_DOMAIN_PRODUCT_PREFIX = 'WN.D.';

export const stepEvents = [
  'ok dns',
  'ok ssl',
  'ok cdn',
  'pending_url added',
  'pending_url dns',
  'pending_url ssl',
  'pending_url cdn',
];
// TODO: Actual event strings are formatted as follows. Look into reworking these - nothing else uses them yet AFAIK
// 'pending_url %DOMAIN_NAME% added'
// 'pending_url %DOMAIN_NAME% dns'
// 'pending_url %DOMAIN_NAME% ssl'
// 'pending_url %DOMAIN_NAME% cdn'

export const stepKeys = {
  cdn: 'cdn',
  dns: 'dns',
  site: 'site',
  ssl: 'ssl',
  url: 'url',
};

export const stepETAsInMinutes = {
  cdn: 10,
  dns: 5,
  site: 1,
  ssl: 60,
  url: 1,
};

export const stepStatuses = {
  active: 'active',
  completed: 'completed',
  errored: 'errored',
  notstarted: 'notstarted',
};

export const stepColors = {
  active: 'primary.main',
  completed: 'success.main',
  errored: 'error.main',
  notStarted: 'grey.500',
};

export const domainSetupCardSx = {
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'grey.300',
  overflow: 'visible',
};

// This should exist somewhere more centralized
export type stepIconColor = OverridableStringUnion<
  | 'action'
  | 'disabled'
  | 'error'
  | 'info'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
>;

export const PENDING_URL_JOB_TIMEOUT = 'job_timeout';
export const DOMAIN_UNREGISTERED_ERROR = 'not_registered';
export const A_RECORD_FAIL_ERROR = 'dns_a_record_fail';
export const WAITING_FOR_CDN_DNS = 'waiting_for_cdn_dns';
export const WAITING_FOR_CDN_VALIDATION = 'waiting_for_cdn_validation';
export const stepErrors = {
  // These show up when the job is re-attempting to validate
  inprogress: [
    // DNS A record
    'A record doesnt match. Waiting for TTL',
    'dns_a_record_mismatch',
    'dns_a_record_flappy',
    // DNS Nameservers
    'NS record doesnt match',
    'dns_ns_record_mismatch',
    'DNS repair performed on domain.',
    'not_our_ns',
    A_RECORD_FAIL_ERROR, // Showing this as in progress for now
    // SSL
    'dns_status_error',
    'ssl_acme_check_fail',
    'ssl_acme_check_start',
    'ssl_waiting_resolve',
    'invalid cert',
    'invalid_cert',
    // CDN
    'waiting_for_dns',
    WAITING_FOR_CDN_DNS,
    WAITING_FOR_CDN_VALIDATION,
    'unknown', // This seems like an error state that needs to be addressed in HAL, so for now we'll stay in progres
  ],
  // This is when a user should be allowed to re-queue the validation job
  retry: [PENDING_URL_JOB_TIMEOUT],
  /*
    This is when external intervention is required to fix an issue

    NOTE: all of the errors in this list may or may not actually be
    unrecoverable, but we will default them here until we learn otherwise
  */
  unrecoverable: ['missing dns', 'missing_dns'],
  // We should give the option to purchase the domain (for a later release)
  unregistered: [DOMAIN_UNREGISTERED_ERROR],
};

export type DomainSetupStep = {
  key: string;
  name: string;
  status: string;
  component: JSX.Element;
};

export type StepAttributeTypes = {
  action?: boolean;
  actionType?: string;
  canShowErrorSection: boolean;
  error?: boolean;
  errorColor?: stepIconColor;
  errorType?: string;
  status: string;
  statusChip: JSX.Element;
};

export type DomainSetupErrorTypes = {
  site: string | null;
  dns: string | null;
  ssl: string | null;
  cdn: string | null;
  isUnregistered: boolean;
  isTimeout: boolean;
  aRecordFailRetrying: boolean;
  isAny: boolean;
};

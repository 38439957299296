import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Chip from '@mui/material/Chip';
import { OverridableStringUnion } from '@mui/types';

type StatusChipProps = {
  type?: string | 'online' | 'ssl';
  status?: string;
};

type StatusAttributes = {
  label: string;
  color: OverridableStringUnion<'success' | 'error' | 'warning'>;
};

export default function StatusChip({
  type = 'online',
  status = '',
}: StatusChipProps) {
  // site status
  const statuses = {
    pendingOnline: [
      'stop_decom',
      'pending_stop_decom',
      'failed_decom',
      'destroy',
      'pending_destroy',
      'failed_destroy',
      'delete',
      'pending_delete',
      'failed_delete',
      'restore',
      'pending_restore',
      'reprovision',
      'pending_reprovision',
      'reset',
      'pending_reset',
      'failed_reset',
      'failed_disable',
    ],
    pendingOffline: ['create', 'pending_create'],
    inactive: ['failed_create', 'disabled', 'decom', 'tos', 'aup'],
  };

  const onlineStatuses: {
    active: StatusAttributes;
    inactive: StatusAttributes;
    pendingOnline: StatusAttributes;
    pendingOffline: StatusAttributes;
  } = {
    active: {
      label: 'Online',
      color: 'success',
    },
    inactive: {
      label: 'Offline',
      color: 'error',
    },
    pendingOnline: {
      label: 'Online',
      color: 'warning',
    },
    pendingOffline: {
      label: 'Offline',
      color: 'warning',
    },
  };

  const getSiteStatus: Function = () => {
    // site is online
    let siteStatus = '';
    if (status === 'active') siteStatus = 'active';
    // site is offline and is being created
    if (statuses.pendingOffline.includes(status)) siteStatus = 'pendingOffline';
    // site as online and is being updated
    if (statuses.pendingOnline.includes(status)) siteStatus = 'pendingOnline';
    // site is offline
    if (statuses.inactive.includes(status)) siteStatus = 'inactive';

    return onlineStatuses[siteStatus as keyof typeof onlineStatuses];
  };

  // ssl status
  const sslStatuses: {
    active: StatusAttributes;
    inactive: StatusAttributes;
    in_progress: StatusAttributes;
  } = {
    active: {
      label: 'Secure',
      color: 'success',
    },
    inactive: {
      label: 'Secure',
      color: 'error',
    },
    in_progress: {
      label: 'Secure',
      color: 'warning',
    },
  };

  const getSslStatus: Function = () => {
    return sslStatuses[status as keyof typeof sslStatuses];
  };

  if (type === 'ssl') {
    if (!getSslStatus()) return null;
    return (
      <Chip
        label={getSslStatus().label}
        variant="outlined"
        sx={{
          paddingLeft: '10px',
          fontWeight: 'bold',
        }}
        icon={
          <CircleRoundedIcon
            sx={{ fontSize: 12, fontWeight: 'medium' }}
            color={getSslStatus().color}
          />
        }
      />
    );
  }

  if (!getSiteStatus()) return null;
  return (
    <Chip
      label={getSiteStatus().label}
      variant="outlined"
      sx={{
        paddingLeft: '10px',
        fontWeight: 'bold',
      }}
      icon={
        <CircleRoundedIcon
          sx={{ fontSize: 12, fontWeight: 'medium' }}
          color={getSiteStatus().color}
        />
      }
    />
  );
}

export const setAlertMessage = (
  descKey: string,
  generateAlert: (alert?: any) => void,
  options?: any,
) => {
  generateAlert({
    description: descKey,
    showCloseBtn: true,
    ...options,
  });
};

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const PromoteModalLoading = () => (
  <>
    <DialogTitle>
      <Skeleton height={28} width={'100%'} />
    </DialogTitle>
    <DialogContent>
      <Divider />
      <Stack direction="row" spacing={2} py={2}>
        <Skeleton height={24} width={24} />
        <Stack spacing={0.5} width={'100%'}>
          <Skeleton height={24} width={'100%'} />
        </Stack>
      </Stack>
      <Skeleton height={70} width={'100%'} />
    </DialogContent>
    <DialogActions>
      <Skeleton height={40} width={80} />
      <Skeleton height={40} width={80} />
    </DialogActions>
  </>
);
export default PromoteModalLoading;

import { Trans, useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useBrandInfo } from '~/hooks/useBrandInfo';

export default function TimeoutError() {
  const { t } = useTranslation('tenant');
  const { phoneNumberFormatted, phoneNumberHref } = useBrandInfo();

  return (
    <>
      <LinearProgress />
      <Card>
        <CardHeader title={t('error.timeout.title')} />
        <CardContent>
          <Stack spacing={2}>
            <Typography>{t('error.timeout.description')}</Typography>
            <Typography>
              <Trans
                i18nKey="tenant:error.timeout.supportTxt"
                values={{ phoneNumber: phoneNumberFormatted }}
                components={{ Link: <Link href={phoneNumberHref}></Link> }}
              />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

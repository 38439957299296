import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useHostingInfo from '~/hooks/useHostingInfo';

const SitesEmpty = () => {
  const { t } = useTranslation('sites');
  const addSiteBtnTranslation = t('cloud.addSiteBtn', {
    wp: `${t('platform.wordpress')} `,
  });

  const { isDisabled } = useHostingInfo();

  return (
    <Paper
      variant="outlined"
      sx={{ borderStyle: 'dashed', p: 4, textAlign: 'center' }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <Typography variant="h3">{t('cloud.emptySites.header')}</Typography>
        <Typography variant="body2">
          {t('cloud.emptySites.description')}
        </Typography>
        <Button variant="outlined" href="./sites/add" disabled={isDisabled}>
          {addSiteBtnTranslation}
        </Button>
      </Stack>
    </Paper>
  );
};

export default SitesEmpty;

const styleOverrides = (
  { typography: { fontFamily, fontWeight, h1, h2, h3, h4, h5, h6 } },
  scopedClass,
) => ({
  [scopedClass]: {
    fontFamily,
    fontWeight,
  },
  [`${scopedClass} h1`]: {
    fontSize: h1.fontSize,
    fontWeight: h1.fontWeight,
    lineHeight: h1.lineHeight,
    letterSpacing: h1.letterSpacing,
    margin: 0,
  },
  [`${scopedClass} h2`]: {
    fontSize: h2.fontSize,
    lineHeight: h2.lineHeight,
    fontWeight: h2.fontWeight,
    letterSpacing: h2.letterSpacing,
    margin: 0,
  },
  [`${scopedClass} h3`]: {
    fontSize: h3.fontSize,
    lineHeight: h3.lineHeight,
    fontWeight: h3.fontWeight,
    margin: 0,
  },
  [`${scopedClass} h4`]: {
    fontSize: h4.fontSize,
    fontWeight: h4.fontWeight,
    margin: 0,
  },
  [`${scopedClass} h5`]: {
    fontSize: h5.fontSize,
    margin: 0,
  },
  [`${scopedClass} h6`]: {
    fontSize: h6.fontSize,
    fontWeight: h6.fontWeight,
    margin: 0,
  },
  [`${scopedClass} p`]: {
    margin: 0,
  },
});

export default styleOverrides;

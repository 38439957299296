import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CTB from '~/components/CTB';
import useFeature from '~/hooks/useFeature';
import { ResourceLimitType } from '~/scenes/Hosting/components/AddSiteButton/Validation';

const ResourcesWarningModal = ({
  resourceLimitMap = {},
  open = false,
  setOpen,
}: PropsWithChildren<{
  resourceLimitMap?: { [key: string]: ResourceLimitType };
  open?: boolean;
  setOpen: Function;
}>) => {
  const { t } = useTranslation('sites');
  const [canUpgrade] = useFeature('hosting_upgrade');

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="add-resource-warning-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>{t(`cloud.resourceLimitModal.title`)}</DialogTitle>
        </Stack>
        <Typography variant="body1" pb={2} component="span">
          <Trans i18nKey="list_map">
            {t(`cloud.resourceLimitModal.description.part1`)}
            {/* @ts-ignore */}
            <ul i18nIsDynamicList>
              {Object.keys(resourceLimitMap)
                .filter((resource) => resourceLimitMap[resource].limitReached)
                .map((resource) => (
                  <li>
                    <Typography color="error">
                      {t(`cloud.resourceLimitModal.resources.${resource}`)}
                    </Typography>
                  </li>
                ))}
            </ul>

            <Trans
              i18nKey="sites:cloud.resourceLimitModal.description.part2"
              components={
                canUpgrade
                  ? {
                      Link: <CTB product="HOSTING_UPGRADE" />,
                    }
                  : { Link: <u></u> }
              }
            ></Trans>
          </Trans>
        </Typography>

        <Stack spacing={1} direction="row" justifyContent="flex-end" mt={3}>
          <Button onClick={handleClose} variant="text">
            {t('close')}
          </Button>

          {/* show only for php workers or storage */}
          {(resourceLimitMap.phpWorker.limitReached ||
            resourceLimitMap.storage.limitReached) && (
            <Button href="./resources" variant="contained">
              {t('cloud.resourceLimitModal.manageResourcesBtn')}
            </Button>
          )}

          {/* show only for db */}
          {resourceLimitMap.db.limitReached && (
            <Button href="./databases" variant="contained">
              {t('manageDatabases')}
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ResourcesWarningModal;

import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Info from '@mui/icons-material/Info';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';

const propTypes = {
  type: string,
  list: array,
  isFetching: bool,
  isPending: bool,
  loader: node,
  loaderCount: arrayOf(number),
  isError: bool,
  error: node,
  errorCopy: string,
  emptyListCopy: string,
  empty: node,
  headCells: arrayOf(
    shape({
      id: string,
      label: string,
    }),
  ).isRequired,
  order: oneOf(['asc', 'desc', false]),
  orderBy: string,
  handleRequestSort: func,
  children: node,
};

const VisionUITable = ({
  type = 'list',
  list = [],
  isFetching = false,
  isPending = false,
  loader = undefined,
  loaderCount = [1],
  isError = false,
  error = undefined,
  errorCopy = undefined,
  emptyListCopy = undefined,
  empty = undefined,
  headCells,
  order = 'asc',
  orderBy = '',
  handleRequestSort = () => {},
  children = undefined,
}) => {
  const { t } = useTranslation('lists');

  const isEmpty = list?.length === 0;

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { list });
    }
    return child;
  });

  const getTableBody = () => {
    if (isPending && loader) return loader;
    if (isPending) {
      return (
        <TableBody>
          {loaderCount.map((key) => (
            <TableRow
              key={`loader-row-${key}`}
              data-testid={`table-loader-${key}`}
            >
              <TableCell>
                <Skeleton variant="text" width={120} />
                <Skeleton variant="text" width={200} />
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Skeleton variant="text" height={24} width={80} />
                  <Skeleton variant="rounded" width={100} height={36} />
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" justifyContent="end">
                  <Skeleton variant="rounded" width={100} height={36} />
                  <Button id="basic-button" disabled>
                    <MoreVertRounded />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }

    if (isError) {
      return (
        <TableBody data-testid={`${type}-table-error`}>
          <TableRow>
            <TableCell colSpan={6}>
              {error || errorCopy || t('listError', { type })}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (isEmpty) {
      return (
        <TableBody data-testid={`${type}-table-empty`}>
          <TableRow>
            <TableCell colSpan={6}>
              {empty || emptyListCopy || t('listNotAvailable', { type })}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody data-testid={`${type}-table`}>{childrenWithProps}</TableBody>
    );
  };

  return (
    <TableContainer>
      {isFetching && <LinearProgress />}
      <Table aria-labelledby="tableTitle" data-testid={`${type}-list`}>
        <TableHead>
          <TableRow>
            {headCells?.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.sort && headCell.label ? (
                  <TableSortLabel
                    active={headCell.id === orderBy}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={handleRequestSort(headCell.id)}
                    data-testid={`${headCell.id}-sort-btn`}
                  >
                    <Stack direction="row" spacing={4} alignItems="center">
                      {headCell.label}
                      {headCell?.toolTip && (
                        <Tooltip
                          title={headCell.toolTip}
                          arrow
                          placement="top-start"
                          sx={{ padding: '1px' }}
                        >
                          <Info color={'info'} fontSize="small" />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableSortLabel>
                ) : (
                  <Stack direction="row" spacing={4} alignItems="center">
                    {headCell.label}
                    {headCell?.toolTip && (
                      <Tooltip
                        title={headCell.toolTip}
                        arrow
                        placement="top-start"
                        sx={{ padding: '1px' }}
                      >
                        <Info color={'info'} fontSize="small" />
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {getTableBody()}
      </Table>
    </TableContainer>
  );
};

VisionUITable.propTypes = propTypes;

export default VisionUITable;

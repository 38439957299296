import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { createContext, PropsWithChildren, useContext } from 'react';

import Container from '@mui/material/Container';

import { useAccountHostingList } from '@newfold/huapi-js';
import {
  AccountEventList200,
  AccountHostingList200,
} from '@newfold/huapi-js/src/index.schemas';

import useAccountEvents, {
  eventWatchRequestPropOptions,
} from '~/hooks/useAccountEvents';

import Main from '../Main';
import { useMFEContext } from '../MFEProvider';
import TenantError from './components/TenantError';

export type eventWatchCallback = (
  eventArgs: eventWatchRequestPropOptions,
) => void;

type TenantContextType =
  | (UseQueryResult<
      AxiosResponse<AccountHostingList200, any>,
      AxiosError<unknown, any>
    > & {
      callbacks: {
        subscribeToEvent: eventWatchCallback;
      };
      eventsData: AccountEventList200 | undefined;
      isValidated: boolean;
      isProvisioning: boolean;
      isEmpty: boolean;
      isUnexpectedError: boolean;
      isTimeoutError: boolean;
    })
  | undefined;

const TenantContext = createContext<TenantContextType>(undefined);

export function useTenant() {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export default function TenantProvider({
  children = undefined,
}: PropsWithChildren<{}>) {
  const {
    // @ts-expect-error
    hostingStatus: { isProvisioning },
  } = useMFEContext();

  const { callbacks, data } = useAccountEvents();
  const eventsData = data?.data;

  const maxFailureAttempts = 20;

  const tenant = useAccountHostingList({
    query: {
      /*
      if AMM provides a "provisioning" hosting status and we get 403 responses keep
        retrying until we get a 200 response. This is due to a delay between either
        1) the addon.back_ref updating with the proper prodInstId, or
        2) the tenant_back_ref updating with the proper accountId
        Makes 20 attempts incrementing over time, after 20 attempts it will show an error message
      if AMM provides an "active" hosting status and we get 403
        stop retrying and show an error message
      any type of other error status (400, 512, etc...) will stop retrying and show an error message
      */
      staleTime: 0, // TODO: remove once huapi provides a list of multi-tenant hosting accounts
      retryDelay: (attemptIndex, error) => {
        if (error?.response?.status === 504)
          return Math.min(1000 * 2 ** (attemptIndex + 2), 60000);
        return Math.min(1000 * 2 ** attemptIndex, 30000);
      },
      retry: (failureCount, error) => {
        const errorCode = error?.response?.status || 0;
        // Timeout error
        if (errorCode === 504) return failureCount < maxFailureAttempts;
        // Not Found error
        if (errorCode === 404) return failureCount < maxFailureAttempts;
        // Forbidden error & Host App is still provisioning hosting
        if (errorCode === 403 && isProvisioning)
          return failureCount < maxFailureAttempts;
        return false;
      },
    },
  });

  const isValidated = Boolean(
    tenant.data?.data?.accounts && tenant.data?.data?.accounts?.length > 0,
  );

  const isEmpty = Boolean(
    tenant.data?.data?.accounts && tenant.data?.data?.accounts?.length < 1,
  );

  const isTimeoutError = tenant?.failureReason?.response?.status === 504;

  const isUnexpectedError =
    !tenant?.isLoading &&
    (tenant.isError ||
      !tenant.data ||
      tenant?.failureCount > maxFailureAttempts);

  const isProvisioningExtended =
    isProvisioning &&
    (tenant?.failureReason?.response?.status === 403 ||
      tenant?.failureReason?.response?.status === 404) &&
    tenant?.failureCount <= maxFailureAttempts;

  return (
    <TenantContext.Provider
      value={{
        ...tenant,
        callbacks,
        eventsData,
        isEmpty,
        isValidated,
        isProvisioning: isProvisioningExtended,
        isUnexpectedError,
        isTimeoutError,
      }}
    >
      {isUnexpectedError || isTimeoutError ? (
        <Main>
          <Container>
            <TenantError />
          </Container>
        </Main>
      ) : (
        children
      )}
    </TenantContext.Provider>
  );
}

import { useSitesInfo } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import { queryClient } from '~/components/MFEProvider/lib/HttpClient/queryClient';
import {
  EventEntryQueryData,
  generateEventEntryQueryKey,
  ResourceIdTypes,
} from '~/hooks/useAccountEvents';
import {
  checkIsParentSite,
  checkIsStagingSite,
} from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/checkIsStagingOrParentSite';
import {
  EVENT_PROMOTE_DONE,
  PROMOTION_IN_PROGRESS,
} from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/stagingConstants';

/*
  Given a site, determines whether a promotion is currently in progress for
    either 1) the current site in context, or 2) the relevant parent site.

  Currently, only Atomic hosting supports the concepts of staging and promotion.
*/
const usePromotionInfo = (
  site?: SitesInfo200 | SiteInfoResponseModelV2 | HostingSitesV2200ItemsItem,
) => {
  const isStagingSite = checkIsStagingSite(site);
  const isParentSite = checkIsParentSite(site);
  const isAtomic = site?.subtype === 'atomic';
  let inProgress: boolean = false;
  let queryKey: any[] | undefined = undefined;

  const { data: siteInfo, isSuccess: isSuccessSiteInfo } = useSitesInfo(
    Number(site?.staging?.parent_site_id),
    {
      query: {
        // If we're in context of a staging site, then we need info about its parent site
        enabled: !!site?.id && isAtomic && isStagingSite,
      },
    },
  );

  if (isAtomic && (isStagingSite || isParentSite)) {
    const hostingIds = [];
    const siteIds = [];

    if (isStagingSite && isSuccessSiteInfo) {
      // If we're in context of a staging site, check the parent site's promotion status
      hostingIds.push(String(siteInfo?.data?.account_id)); // HAL Atomic child account id - NOT account back ref
      siteIds.push(
        String(siteInfo?.data?.id), // this staging site's parent site's id
        String(site?.id), // this staging site's id
      );
    } else if (isParentSite) {
      // otherwise check own promotion status
      hostingIds.push(String(site?.account_id)); // HAL Atomic child account id - NOT account back ref
      siteIds.push(
        String(site?.id), // this parent site's id
        String(site?.staging?.promoting_from), // the parent's staging site's id
      );
    }

    queryKey = generateEventEntryQueryKey(EVENT_PROMOTE_DONE, {
      hosting: [...hostingIds],
      site: [...siteIds],
    } as ResourceIdTypes);

    // Check to see if this site has a pending promotion event invalidation entry
    const eventQueryData: EventEntryQueryData | undefined =
      queryClient.getQueryData(queryKey);

    const pendingInvalidationExists =
      !!queryKey &&
      queryKey.length > 2 && // the default queryKey has at least two elements (base key and event)
      !!eventQueryData?.queries &&
      eventQueryData?.queries?.length > 0;

    if (
      // If we have a pending invalidation entry for this site or its parent
      pendingInvalidationExists ||
      // if this site itself is promoting
      (isParentSite &&
        site?.staging?.promotion_status === PROMOTION_IN_PROGRESS) ||
      // if this site's parent is promoting
      (isStagingSite &&
        !!siteInfo &&
        siteInfo?.data?.staging?.promotion_status === PROMOTION_IN_PROGRESS)
    )
      inProgress = true;
  }

  type PromotionInfoResponseTypes = {
    inProgress: boolean;
    queryKey?: any[];
  };

  return { inProgress, queryKey } as PromotionInfoResponseTypes;
};

export default usePromotionInfo;

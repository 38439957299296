import '@animxyz/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SsoLink, {
  SsoQueryParamsTypes,
  SsoTypeTypes,
} from '~/components/SsoLink';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';

import MultipleChoiceSsoDialog from './MultipleChoiceSsoDialog';
import useServerEmailChoices from './ServerEmailChoices';

const QuickLinks = () => {
  const { t } = useTranslation('quickLinks');
  const [serverEmailIsOpen, setServerEmailIsOpen] = useState(false);

  const { hostingAccount, isDisabled, isPending } = useHostingInfo();

  const [hasAdvancedHosting] = useFeature('advanced_hosting');
  const [hasCpanel, , isClientReady] = useFeature('cpanel');
  const [isPlesk] = useFeature('plesk');
  const [isWindows] = useFeature('windows');
  const [hasRootWhm] = useFeature('whm');

  const isReseller = hostingAccount?.data?.type === 'reseller';

  const getQuickLinks = () => {
    // TODO Huapi and frontend will be updated for VPS capabilities (whm,server_controls) to use advanced_hosting capability.
    // VPS should only get a link to WHM if whm/advanced_hosting is true
    const listButtonLinks = [
      {
        name: 'whm',
        type: 'vps',
        canShow: hasRootWhm,
        queryParams: {
          app: 'whm',
        },
      },
      {
        name: 'systemconsole',
        type: 'vps',
        canShow: hasAdvancedHosting && !isPlesk && !isWindows,
        queryParams: {
          app: 'systemconsole',
        },
      },
      {
        name: 'cpanel',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
      },
      {
        name: 'filemanager',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
        queryParams: {
          app: 'filemanager',
        },
      },
      {
        name: 'resellerwhm',
        type: 'reseller',
        canShow: isReseller,
        queryParams: {
          app: 'resellerwhm',
        },
      },
      {
        name: 'resellercpanel',
        type: 'reseller',
        canShow: hasCpanel && isReseller,
        queryParams: {
          app: 'resellercpanel',
        },
      },
      {
        name: 'adminpanel',
        type: 'plesk',
        canShow: isPlesk,
      },
    ];

    const listOfQuickLinks = listButtonLinks.filter((item) => item.canShow);
    return listOfQuickLinks;
  };

  const choices = useServerEmailChoices();

  const handleServerEmailClose = () => {
    setServerEmailIsOpen(false);
  };

  const handleServerEmailClick = () => {
    setServerEmailIsOpen(true);
  };

  const quickLinks = getQuickLinks() as {
    name: string;
    type: SsoTypeTypes;
    canShow: boolean;
    queryParams: SsoQueryParamsTypes;
  }[];

  return (
    <Card data-testid="quick-links">
      <CardHeader title={t('quickLinks.cardTitle')} />
      <CardContent>
        {(!isClientReady || isPending) && 'Loading quick links...'}
        {/* quick links success (show them) */}
        {isClientReady && quickLinks.length > 0 && (
          <Stack spacing={2}>
            {Array.isArray(quickLinks) &&
              quickLinks.map(({ name, type, queryParams }) => (
                <SsoLink
                  key={`sso-link-${name}`}
                  fluid
                  type={type}
                  {...(queryParams && { queryParams })}
                >
                  {t(`quickLinks.links.${name}`)}
                </SsoLink>
              ))}
            {hasCpanel && !isReseller && (
              <>
                <MultipleChoiceSsoDialog
                  isOpen={serverEmailIsOpen}
                  onClose={handleServerEmailClose}
                  title={t('serverEmail.title')}
                  description={t('serverEmail.description')}
                  choices={choices}
                />
                <Button
                  variant="outlined"
                  onClick={handleServerEmailClick}
                  data-testid="email-sso-button"
                  disabled={isDisabled}
                >
                  {t('serverEmail.buttonLabel')}
                </Button>
              </>
            )}
          </Stack>
        )}

        {isClientReady && quickLinks?.length <= 0 && (
          <Typography align="center" mt={8}>
            {t('quickLinks.noQuickLinks')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default QuickLinks;

import {
  HostingSitesV2200ItemsItem,
  SitesDomainsV2200DomainsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

/*
  Given a domain and a site, determines whether the docroot where the
  domain is installed matches the location where the site is installed
*/
export const checkDomainDocrootEqualsSiteDocpath = (
  domain?: SitesDomainsV2200DomainsItem,
  site?: SitesInfo200 | HostingSitesV2200ItemsItem,
) =>
  domain?.docroot ===
  (site?.path ? `${site?.docroot}/${site?.path}` : site?.docroot);
